import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { forwardRef, useState } from 'react';
import TextField, { TextFieldProps } from './material/TextField';

const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>(
	(props, ref) => {
		const [isVisible, setIsVisible] = useState(false);

		const toggleVisibility = () => setIsVisible((prev) => !prev);

		return (
			<TextField
				{...props}
				type={isVisible ? 'text' : 'password'}
				InputProps={{
					...props.InputProps,
					endAdornment: (
						<Tooltip title={isVisible ? 'Скрыть' : 'Показать'}>
							<IconButton onClick={toggleVisibility} edge="end">
								{isVisible ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</Tooltip>
					),
				}}
			/>
		);
	}
);

export default PasswordField;
