import React, { ComponentProps } from 'react';
import { IMaskMixin } from 'react-imask';
import TextField, { TextFieldProps } from './material/TextField';

const InternalMaskTextField = IMaskMixin((props) => (
	<TextField {...(props as any)} />
));

type MaskProps = ComponentProps<typeof InternalMaskTextField>;

export const MaskedField = (props: TextFieldProps & MaskProps) => {
	return <InternalMaskTextField {...props} />;
};
