import { Add, Close } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { ReactNode, useEffect, useRef } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../store';
import { setAdditionalFilter } from '../../store/filtersSlice';
import ButtonDropdown, { ButtonDropdownProps } from '../ButtonDropdown';
import DropdownMenuSection from '../ButtonDropdown/DropdownMenuSection';
import CurriculumFilters, {
	CurriculumFiltersProps,
} from '../CurriculumFilters';
import TextField, { TextFieldProps } from '../material/TextField';
import RouterLink from '../RouterLink';

interface FilterObject {
	title: string;
	value: any;
	onChange: (value: any) => void;
	options: Array<{ label: string; value: any }>;
	hasAllOption?: boolean;
}

interface Props {
	newButtonText: string;
	rightSideAdornment?: ReactNode;
	allowSearch?: boolean;
	disableNewButton?: boolean;
	textFieldProps?: TextFieldProps;
	filtersButtonDropdownProps?: ButtonDropdownProps;
	filters?: Array<FilterObject>;
	curriculumFiltersProps?: CurriculumFiltersProps;
}

/* 
	Dashboard Page Header is a header component of each page.
	It has a search field on the left side with filters and add a new entity button on the right

	filters - an array of FilterObject objects with each being rendered in a DropdownMenuSection component
	curriculumFiltersProps - an object that determines which curriculum filters should be present on this page
*/
export default function DashboardPageHeader({
	allowSearch = false,
	disableNewButton = false,
	textFieldProps,
	filtersButtonDropdownProps,
	filters,
	newButtonText,
	curriculumFiltersProps,
	rightSideAdornment,
}: Props) {
	const inputRef = useRef();
	const additionalFilters = useAppSelector(
		(state) => state.filters.additionalFilters
	);
	const dispatch = useAppDispatch();
	const hasFilters = Array.isArray(filters) && filters.length > 0;
	const hasCurriculumFilters = Boolean(curriculumFiltersProps);

	const onInputChange = useDebounce(({ target }) => {
		dispatch(
			setAdditionalFilter({
				key: 'query',
				value: (target as HTMLInputElement).value,
			})
		);
	}, 1000);

	useEffect(() => {
		if (inputRef.current) {
			(inputRef.current as HTMLInputElement).value =
				additionalFilters?.query ?? '';
		}
	}, []);

	return (
		<Grid container spacing={2} justifyContent="space-between" mb={4}>
			{allowSearch && (
				<Grid item xs={12} md={5}>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						onChange={onInputChange}
						inputRef={inputRef}
						InputProps={{
							endAdornment: additionalFilters?.query && (
								<IconButton
									edge="end"
									onClick={() => {
										dispatch(setAdditionalFilter({ key: 'query', value: '' }));
										if (inputRef.current) {
											(inputRef.current as HTMLInputElement).value = '';
										}
									}}
								>
									<Close />
								</IconButton>
							),
						}}
						{...textFieldProps}
					/>
				</Grid>
			)}
			<Grid
				item
				container
				spacing={2}
				xs={12}
				md={allowSearch ? 'auto' : 12}
				justifyContent="space-between"
			>
				{(hasFilters || hasCurriculumFilters) && (
					<Grid item xs={12} sm={allowSearch ? true : 'auto'}>
						<ButtonDropdown
							{...filtersButtonDropdownProps}
							buttonProps={{ fullWidth: true }}
							menuProps={{
								keepMounted: true,
								anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
								transformOrigin: {
									vertical: 'top',
									horizontal: 'center',
								},
								PaperProps: {
									sx: {
										py: 1,
										px: 2,
										width: '100%',
										maxWidth: {
											md: hasCurriculumFilters && hasFilters ? 700 : 300,
										},
									},
								},
							}}
							menuItems={
								<Grid container rowSpacing={2} columnSpacing={4}>
									{hasCurriculumFilters && (
										<Grid item xs={12} sm>
											<CurriculumFilters {...curriculumFiltersProps} />
										</Grid>
									)}

									{hasFilters && (
										<Grid item xs={12} sm>
											{filters?.map((el, i) => (
												<DropdownMenuSection
													key={`filters-dropdown-${el.title}-${i}`}
													onChange={el.onChange}
													title={el.title}
													value={el.value}
													options={el.options}
													hasAllOption={el.hasAllOption}
													noDivider={i + 1 === filters.length}
												/>
											))}
										</Grid>
									)}
								</Grid>
							}
						>
							Фильтры
						</ButtonDropdown>
					</Grid>
				)}
				{!disableNewButton && (
					<Grid item xs={12} sm={allowSearch ? true : 'auto'}>
						<Button
							component={RouterLink}
							to="new"
							fullWidth
							endIcon={<Add />}
							color="inherit"
							variant="outlined"
							disableElevation
						>
							{newButtonText}
						</Button>
					</Grid>
				)}
				{rightSideAdornment && (
					<Grid item xs={12} sm={allowSearch ? true : 'auto'}>
						{rightSideAdornment}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
}
