import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

export default function GlobalStyles() {
	return (
		<MuiGlobalStyles
			styles={(theme) => ({
				'*': {
					boxSizing: 'border-box',
				},
				':root': {
					fontSize: 16,
				},
				body: {
					backgroundColor: theme.palette.background.default,
					color: theme.palette.text.primary,
					overflowX: 'hidden',
					fontFamily: theme.typography.fontFamily,
				},
			})}
		/>
	);
}
