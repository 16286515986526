import MainContainer from "../MainContainer";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const LessonActivity = ({data: dataFromQuery}: any) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );
    const options = {
        scales: {
            x: {
                ticks: {
                    display: false,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: true,
                text: 'Время просмотра уроков',
            },
        },
    };

    const labels = dataFromQuery.map((d: any) => d.lesson);

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Секунд',
                data: dataFromQuery.map((d: any) => d.seconds),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <MainContainer>
            {dataFromQuery.length ?
                <Line height={window.innerWidth < 1200 ? '300px' : 'auto'} options={options} data={data} />
                :
                <span>Нет актуальных данных</span>
            }

        </MainContainer>
    )
}

export default LessonActivity;