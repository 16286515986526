import { Divider, ListItemText, ListSubheader, MenuItem } from '@mui/material';
import { v4 as uuid } from 'uuid';

interface Option {
	label: string;
	value: any;
}

interface Props {
	title?: string;
	noDivider?: boolean;
	onChange: (value: any) => any;
	options: Option[];
	hasAllOption?: boolean;
	value: any;
}

export default function DropdownMenuSection({
	title,
	onChange,
	options,
	value,
	hasAllOption,
	noDivider,
}: Props) {
	const onOptionClick = (value: any) => {
		return () => onChange(value);
	};

	return (
		<>
			{title && (
				<ListSubheader>
					<ListItemText>{title}</ListItemText>
				</ListSubheader>
			)}

			{hasAllOption && (
				<MenuItem
					className="dropdown-menu-item"
					onClick={onOptionClick(undefined)}
					selected={value === undefined}
				>
					<ListItemText>Все</ListItemText>
				</MenuItem>
			)}
			{options.map((option, i) => (
				<MenuItem
					key={uuid()}
					className="dropdown-menu-item"
					onClick={onOptionClick(option.value)}
					selected={option.value === value}
				>
					<ListItemText>{option.label}</ListItemText>
				</MenuItem>
			))}
			{!noDivider && <Divider sx={{ mx: 2 }} />}
		</>
	);
}
