import { Visibility } from '@mui/icons-material';
import { IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import CurriculumFilters from '../../components/CurriculumFilters';
import CustomDataGrid from '../../components/CustomDataGrid';
import DashboardFullPageContainer from '../../components/DashboardFullPageContainer';
import Typography from '../../components/material/Typography';
import { ContextMenuAction } from '../../hooks/useContextMenu';
import SubmissionsService from '../../services/SubmissionsService';
import { useAppSelector } from '../../store';
import { AssignmentSubmission } from '../../types';
import formatDate from '../../utils/formatDate';
import AssignmentStatusChip from './AssignmentStatusChip';
import CheckAssignmentSubmissionModal from './CheckAssignmentSubmissionModal';

interface Props {
  assignmentId?: string;
  isLoading: boolean;
}

export default function AssignmentsGrid({
  assignmentId,
  isLoading: isLoadingLessonData,
}: Props) {
  const [isCheckSubmissionOpen, setIsCheckSubmissionOpen] = useState(false);
  const [activeSubmissionId, setActiveSubmissionId] = useState<
    undefined | string
  >();

  const { program, course, unit, lesson } = useAppSelector(
    (state) => state.filters
  );

  const {
    data: submissions,
    isLoading: isLoadingSubmissions,
    refetch,
  } = useQuery(
    ['getAssigmentSubmissions', assignmentId],
    () => SubmissionsService.getSubmissionsByAssignmentId(assignmentId ?? ''),
    {
      enabled: Boolean(assignmentId),
    }
  );

  const isLoading = isLoadingLessonData || isLoadingSubmissions;

  const onCheckSubmission = (id: string) => {
    if (isCheckSubmissionOpen) {
      return;
    }

    setActiveSubmissionId(id);
    setIsCheckSubmissionOpen(true);
  };

  const onCheckSubmissionClose = () => {
    setIsCheckSubmissionOpen(false);
  };

  const columns: GridColumns<AssignmentSubmission> = [
    {
      field: 'accepted',
      headerName: 'Статус',
      width: 160,
      renderCell: ({ value }) => (
        <AssignmentStatusChip accepted={value} size="small" />
      ),
    },
    {
      field: 'answer',
      headerName: 'Ответ',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'date',
      headerName: 'Дата',
      flex: 0,
      minWidth: 200,
      renderCell: (params) =>
        formatDate(params.value, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }),
    },
    {
      field: '',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      flex: 0,
      maxWidth: 60,
      renderCell: (params) => (
        <Stack direction="row">
          <Tooltip title="Посмотреть задание">
            <IconButton onClick={() => onCheckSubmission(params.row.id)}>
              <Visibility />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const actions: ContextMenuAction[] = [
    {
      label: 'Проверить',
      icon: <Visibility />,
      onClick: (submissionId) => onCheckSubmission(submissionId),
    },
    // {
    // 	label: 'Пользователи',
    // 	icon: <PeopleOutlined />,
    // 	onClick: (courseId) =>
    // 		navigateWithFilters('users', courseId, { courseId }),
    // },
    // {
    // 	label: 'Редактировать',
    // 	icon: <EditOutlined />,
    // 	onClick: (courseId) => navigate(courseId),
    // },
    // {
    // 	label: 'Удалить',
    // 	icon: <DeleteOutlined />,
    // 	onClick: (courseId) =>
    // 		withConfirm({
    // 			title: 'Удалить курс?',
    // 			confirmButtonText: 'Удалить',
    // 			cancelButtonText: 'Отмена',
    // 			onConfirm: () => deleteCourse(courseId),
    // 		}),
    // },
  ];

  if (!course.value || !unit.value || !lesson.value) {
    return (
      <DashboardFullPageContainer>
        <Paper sx={{ p: 2 }}>
          <Typography variant="body1" mb={1}>
            Пожалуйста, выберите программу, курс, блок и урок:
          </Typography>
          <CurriculumFilters program course unit lesson />
        </Paper>
      </DashboardFullPageContainer>
    );
  }

  return (
    <>
      <CheckAssignmentSubmissionModal
        submissionId={activeSubmissionId}
        assignmentId={assignmentId ?? ''}
        isOpen={isCheckSubmissionOpen}
        onClose={onCheckSubmissionClose}
        refetchSubmissions={refetch}
      />
      <CustomDataGrid
        columns={columns}
        contextMenuItems={actions}
        rows={submissions ?? []}
        loading={isLoading}
        rowHeight={40}
        onRowClick={(params) => onCheckSubmission(params.row.id)}
      />
    </>
  );
}
