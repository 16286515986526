import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import MainLayout from './layouts/MainLayout';
import AssignmentsPage from './pages/AssignmentsPage';
import BannersPage from './pages/BannersPage';
import CoursesPage from './pages/Curriculum/CoursesPage';
import EditCoursePage from './pages/Curriculum/EditCoursePage';
import EditLessonPage from './pages/Curriculum/EditLessonPage';
import EditProgramPage from './pages/Curriculum/EditProgramPage';
import NewUnitPage from './pages/Curriculum/EditUnitPage';
import LessonsPage from './pages/Curriculum/LessonsPage';
import ProgramsPage from './pages/Curriculum/ProgramsPage';
import UnitsPage from './pages/Curriculum/UnitsPage';
import EditBannerPage from './pages/EditBannerPage';
import EditUserPage from './pages/EditUserPage';
import HomePage from './pages/HomePage';
import NPSPage from './pages/NPSPage';
import ProfilePage from './pages/ProfilePage';
import QuizzesPage from './pages/QuizzesPage';
import SelfAssignmentsPage from './pages/SelfAssignmentsPage';
import SettingsPage from './pages/SettingsPage';
import SignInPage from './pages/SignInPage';
import UsersBatchUploadPage from './pages/UsersBatchUploadPage';
import UsersPage from './pages/UsersPage';

export default function AppRoutes() {
	return (
		<Routes>
			<Route path="auth" element={<AuthLayout />}>
				<Route path="sign-in" element={<SignInPage />} />
				<Route path="*" element={<Navigate to="/auth/sign-in" />} />
			</Route>
			<Route path="/" element={<MainLayout />}>
				<Route path="profile" element={<ProfilePage />} />
				<Route path="users" element={<UsersPage />} />
				<Route path="users/new" element={<EditUserPage />} />
				<Route path="users/batch-upload" element={<UsersBatchUploadPage />} />
				<Route path="users/:id" element={<EditUserPage />} />
				<Route path="banners" element={<BannersPage />} />
				<Route path="banners/new" element={<EditBannerPage />} />
				<Route path="banners/:id" element={<EditBannerPage />} />
				<Route path="curriculum">
					<Route path="programs" element={<ProgramsPage />} />
					<Route path="programs/new" element={<EditProgramPage />} />
					<Route path="programs/:id" element={<EditProgramPage />} />
					<Route path="courses" element={<CoursesPage />} />
					<Route path="courses/new" element={<EditCoursePage />} />
					<Route path="courses/:id" element={<EditCoursePage />} />
					<Route path="units" element={<UnitsPage />} />
					<Route path="units/new" element={<NewUnitPage />} />
					<Route path="units/:id" element={<NewUnitPage />} />
					<Route path="lessons" element={<LessonsPage />} />
					<Route path="lessons/new" element={<EditLessonPage />} />
					<Route path="lessons/:id" element={<EditLessonPage />} />
				</Route>
				<Route path="nps" element={<NPSPage />} />
				<Route path="assignments" element={<AssignmentsPage />} />
				<Route path="selfassignments" element={<SelfAssignmentsPage />} />
				<Route path="quizzes" element={<QuizzesPage />} />
				<Route path="settings" element={<SettingsPage />} />

				<Route index element={<HomePage />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Route>
		</Routes>
	);
}
