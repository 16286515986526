import { DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import CheckboxField from '../../../components/CheckboxField';
import DateTimePickerField from '../../../components/DateTimePickerField ';
import FileUploadField from '../../../components/FileUploadField';
import TextField from '../../../components/material/TextField';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../../constants';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import useToast from '../../../hooks/useToast';
import LessonsService from '../../../services/LessonsService';
import {
	Assignment,
	PostAssignment,
	PostSelfAssignment,
	SelfAssignment,
} from '../../../types';
import getTextFieldErrorProps from '../../../utils/getTextFieldErrorProps';

type AssignmentFormValues = PostSelfAssignment & {
	isActive: boolean;
};

interface Props {
	isDisabled: boolean;
	assignment?: SelfAssignment;
	defaultValues?: Partial<PostSelfAssignment>;
}

export default function SelfAssignmentForm({
	isDisabled,
	assignment,
	defaultValues,
}: Props) {
	const isUpdate = assignment?.id !== undefined;
	const {
		control,
		watch,
		register,
		setValue,
		formState: { errors, isDirty },
		handleSubmit,
		reset,
	} = useForm<AssignmentFormValues>({
		defaultValues: {
			...defaultValues,
			isActive: isUpdate,
		},
	});
	const withConfirm = useConfirmDialog();
	const toast = useToast();
	const queryClient = useQueryClient();
	const { id } = useParams();

	const saveAssignmentMutation = useMutation(
		isUpdate ? 'updateSelfAssignment' : 'createSelfAssignment',
		(data: PostSelfAssignment) =>
			isUpdate
				? LessonsService.updateSelfAssignment(assignment.id, data)
				: LessonsService.createSelfAssignment(id ?? '', data),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				queryClient.refetchQueries(['lesson', id]);
			},
		}
	);

	const deleteAssignmentMutation = useMutation(
		'deleteSelfAssignment',
		() => LessonsService.deleteSelfAssignment(assignment?.id ?? ''),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				setValue('isActive', false);

				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				queryClient.refetchQueries(['lesson', id]);
			},
		}
	);

	const isLoading =
		saveAssignmentMutation.isLoading || deleteAssignmentMutation.isLoading;

	const watchIsActive = watch('isActive');

	const onCancel = () => {
		if (!assignment?.id) {
			setValue('isActive', false);
			return;
		}

		if (isLoading) {
			return;
		}

		withConfirm({
			title: 'Удалить самостоятельную работу?',
			cancelButtonText: 'Отмена',
			confirmButtonText: 'Удалить',
			onConfirm: () => deleteAssignmentMutation.mutate(),
		});
	};

	const onSubmit = (data: AssignmentFormValues) => {
		if (isLoading) {
			return;
		}

		saveAssignmentMutation.mutate(data);
	};

	const onReset = () => {
		reset({
			...defaultValues,
			isActive: isUpdate,
		});
	};

	useEffect(() => {
		if (!watchIsActive) {
			if (assignment) {
				setValue('isActive', true);
				onCancel();
				return;
			}

			reset();
		}
	}, [watchIsActive, isDisabled]);

	useEffect(() => {
		onReset();
	}, [defaultValues]);

	return (
		<Grid item xs={12} component="form" onSubmit={handleSubmit(onSubmit)}>
			<Divider sx={{ mb: 1 }} />
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				sx={{
					mb: 1,
					position: 'relative',
					zIndex: 1,
				}}
				justifyContent="space-between"
			>
				<CheckboxField
					control={control}
					name="isActive"
					label="Самостоятельная работа"
					formControlLabelProps={{
						disabled: isDisabled || isLoading,
					}}
				/>

				<Box sx={(theme) => ({ color: theme.palette.grey[400] })}>
					{isLoading ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						assignment && (
							<Tooltip title="Удалить">
								<IconButton disabled={isLoading} onClick={onCancel} edge="end">
									<DeleteOutline />
								</IconButton>
							</Tooltip>
						)
					)}
				</Box>
			</Stack>
			{watchIsActive && (
				<Grid container spacing={{ xs: 2, md: 4 }}>
					<Grid item xs={12}>
						<TextField
							separateLabel
							fullWidth
							label="Заголовок"
							inputProps={{
								...register(`title`, {
									required: 'Введите заголовок',
								}),
							}}
							{...getTextFieldErrorProps(errors.title)}
							disabled={isLoading}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							separateLabel
							fullWidth
							multiline
							rows={3}
							label="Описание"
							inputProps={{
								...register(`description`),
							}}
							disabled={isLoading}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<FileUploadField
							control={control}
							name="example"
							label="Пример"
							formControlProps={{ fullWidth: true, disabled: isLoading }}
						/>
					</Grid>
					{isDirty && (
						<Grid item container spacing={2}>
							<Grid item ml="auto">
								<Button
									fullWidth
									color="inherit"
									onClick={onCancel}
									disabled={isLoading}
								>
									Отмена
								</Button>
							</Grid>
							<Grid item>
								<LoadingButton
									fullWidth
									variant="contained"
									type="submit"
									color="secondary"
									loading={isLoading}
								>
									Сохранить задание
								</LoadingButton>
							</Grid>
						</Grid>
					)}
				</Grid>
			)}
		</Grid>
	);
}
