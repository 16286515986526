import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import DashboardPageContainer from '../components/DashboardPageContainer';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import LessonsService from '../services/LessonsService';
import { useAppSelector } from '../store';
import QuizzesGrid from '../views/QuizzesPage/QuizzesGrid';
import QuizzesPageHeader from '../views/QuizzesPage/QuizzesPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

export default function QuizzesPage() {
	const { program, course, unit, lesson } = useAppSelector(
		(state) => state.filters
	);
	useSetHeaderTitle(
		'Ответы на тесты',
		`${program.value ? program.value.title : ''}${
			course.value ? ' - ' + course.value.title : ''
		}${unit.value ? ' - ' + unit.value.title : ''}${
			lesson.value ? ' - ' + lesson.value.title : ''
		}`
	);

	const { data: lessonData, isLoading: isLoadingLessonData } = useQuery(
		['getLesson', lesson.id],
		() => LessonsService.getLesson(lesson.id ?? ''),
		{
			enabled: Boolean(lesson.id),
		}
	);

	const quizId = useMemo<string | undefined>(() => {
		if (!lessonData?.quizzes || lessonData.quizzes.length === 0) {
			return;
		}

		return lessonData.quizzes[0];
	}, [lessonData]);

	return (
		<PageContainer>
			<QuizzesPageHeader />
			<QuizzesGrid quizId={quizId} isLoading={isLoadingLessonData} />
		</PageContainer>
	);
}
