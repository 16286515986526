import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
	DragDropContext,
	DropResult,
	ResponderProvided,
} from 'react-beautiful-dnd';
import { useMutation, useQuery } from 'react-query';
import Loader from '../../components/Loader';
import ImageZoomModal from '../../components/modals/ImageZoomModal';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import BannersService from '../../services/BannersService';
import { Banner } from '../../types';
import BannersControlRow from './BannersControlRow';

const ACTIVE_LIST_ID = 'ACTIVE';
const INACTIVE_LIST_ID = 'INACTIVE';

export default function BannersControl() {
	const toast = useToast();

	const [isOpenZoomedImageModal, setIsOpenZoomedImageModal] = useState(false);
	const [zoomedImageSrc, setZoomedImageSrc] = useState<string | undefined>();
	const [isOrderChanged, setIsOrderChanged] = useState(false);

	const [activeBanners, setActiveBanners] = useState<Banner[]>([]);
	const [inactiveBanners, setInactiveBanners] = useState<Banner[]>([]);

	const {
		data: banners,
		isLoading: isLoadingBanners,
		refetch,
	} = useQuery('banners', BannersService.getBanners);

	const updateBannersOrderMutation = useMutation(
		'updateBannersOrder',
		(data: { ids: string[]; activeCount: number }) =>
			BannersService.updateBannersOrder(data.ids, data.activeCount),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				refetch();
				setIsOrderChanged(false);
			},
		}
	);

	const onCancelOrderClick = () => {
		setIsOrderChanged(false);
		resetBanners();
	};

	const onSaveOrderClick = () => {
		const result = [
			...activeBanners.map((el) => el.id),
			...inactiveBanners.map((el) => el.id),
		];

		updateBannersOrderMutation.mutate({
			ids: result,
			activeCount: activeBanners.length,
		});
	};

	const onImageZoomModalClose = () => {
		setIsOpenZoomedImageModal(false);
	};

	const onBannerImageClick = (src: string) => {
		setIsOpenZoomedImageModal(true);
		setZoomedImageSrc(src);
	};

	const onDragStart = () => {
		if (!isOrderChanged) {
			setIsOrderChanged(true);
		}
	};

	const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}

		let element: Banner;
		let sourceArray,
			destinationArray = [];

		if (source.droppableId === INACTIVE_LIST_ID) {
			element = inactiveBanners[source.index];
			sourceArray = Array.from(inactiveBanners);
			destinationArray = Array.from(activeBanners);
		} else {
			element = activeBanners[source.index];
			sourceArray = Array.from(activeBanners);
			destinationArray = Array.from(inactiveBanners);
		}

		sourceArray.splice(source.index, 1);

		if (destination.droppableId === source.droppableId) {
			// If dropped into the same list
			sourceArray.splice(destination.index, 0, element);

			if (destination.droppableId === ACTIVE_LIST_ID) {
				setActiveBanners(sourceArray);
			} else {
				setInactiveBanners(sourceArray);
			}
		} else {
			// If dropped to another list
			destinationArray.splice(destination.index, 0, element);

			if (destination.droppableId === ACTIVE_LIST_ID) {
				// From inactive to active
				setInactiveBanners(sourceArray);

				setActiveBanners(destinationArray);
			} else {
				// From active to inactive
				setActiveBanners(sourceArray);

				setInactiveBanners(destinationArray);
			}
		}
	};

	const resetBanners = () => {
		if (!banners) {
			return;
		}

		setActiveBanners(banners.filter((el) => el.isActive));

		setInactiveBanners(banners.filter((el) => !el.isActive));
	};

	useEffect(() => {
		resetBanners();
	}, [banners]);

	const isLoading = isLoadingBanners || updateBannersOrderMutation.isLoading;

	return (
		<>
			{isLoadingBanners ? (
				<Loader />
			) : (
				banners && (
					<>
						<ImageZoomModal
							src={zoomedImageSrc}
							onClose={onImageZoomModalClose}
							isOpen={isOpenZoomedImageModal}
						/>

						{isOrderChanged && (
							<Box
								ml="auto"
								sx={(theme) => ({ mb: { xs: 2, sm: 1 }, height: { sm: 0 } })}
							>
								<div></div>
								<Stack direction="row" alignItems="center" spacing={2}>
									<Button
										color="inherit"
										onClick={onCancelOrderClick}
										disabled={isLoading}
									>
										Отменить
									</Button>
									<LoadingButton
										variant="contained"
										onClick={onSaveOrderClick}
										loading={isLoading}
									>
										Сохранить
									</LoadingButton>
								</Stack>
							</Box>
						)}

						<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
							<Stack spacing={2}>
								<BannersControlRow
									title="Активные баннеры"
									onBannerImageClick={onBannerImageClick}
									banners={activeBanners}
									droppableId={ACTIVE_LIST_ID}
									isDisabled={isLoading}
									refetch={refetch}
								/>

								<BannersControlRow
									title="Неактивные баннеры"
									onBannerImageClick={onBannerImageClick}
									banners={inactiveBanners}
									droppableId={INACTIVE_LIST_ID}
									isDisabled={isLoading}
									refetch={refetch}
								/>
							</Stack>
						</DragDropContext>
					</>
				)
			)}
		</>
	);
}
