import axios from 'axios';
import { SERVICE_URLS } from '../constants';
import { AssignmentSubmission, QuizSubmission } from '../types';

const SubmissionsService = {
	async getSubmissionsByAssignmentId(assignmentId: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/assignment/${assignmentId}/submissions`;

		const res = await axios.get<AssignmentSubmission[]>(url);

		return res.data;
	},
	async getSubmissionsBySelfAssignmentId(assignmentId: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/selfassignment/${assignmentId}/submissions`;

		const res = await axios.get<AssignmentSubmission[]>(url);

		return res.data;
	},
	async getAssignmentSubmission(id: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/assignment/submissions/${id}`;

		const res = await axios.get<AssignmentSubmission>(url);

		return res.data;
	},
	async getSelfAssignmentSubmission(id: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/selfassignment/submissions/${id}`;

		const res = await axios.get<AssignmentSubmission>(url);

		return res.data;
	},
	async getQuizSubmission(id: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/quiz/submissions/${id}`;

		const res = await axios.get<QuizSubmission>(url);

		return res.data;
	},
	async validateSubmission(
		assignmentId: string,
		userId: string,
		accepted: boolean
	) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/assignment/${assignmentId}/validate`;

		const res = await axios.post(url, { user: userId, success: accepted });
	},
	async validateSelfSubmission(
		assignmentId: string,
		userId: string,
		accepted: boolean
	) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/selfassignment/${assignmentId}/validate`;

		const res = await axios.post(url, { user: userId, success: accepted });
	},
	async validateAllSubmissions(assignmentId: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/assignment/${assignmentId}/submissions/validate`;

		const res = await axios.post(url, {});
	},
	async validateAllSelfSubmissions(assignmentId: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/selfassignment/${assignmentId}/submissions/validate`;

		const res = await axios.post(url, {});
	},
	async getSubmissionsByQuizId(quizId: string) {
		const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/quiz/${quizId}/submissions`;
		// const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/quiz/submissions`;

		const res = await axios.get<QuizSubmission[]>(url);

		return res.data;
	},
};

export default SubmissionsService;
