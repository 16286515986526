import MainContainer from "../MainContainer";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

export interface NPSActivityProps {
    score: number;
    unit: string;
}

const NPSActivity = ({data: dataFromQuery}: any) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        scales: {
            x: {
                ticks: {
                    display: false,
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: true,
                text: 'Оценка блоков',
            },
        },
    };

    const labels = dataFromQuery.map((d: any) => d.unit);

    const data = {
        labels,
        datasets: [
            {
                label: 'Оценка в баллах',
                data: dataFromQuery.map((d: any) => d.score),
                backgroundColor: '#FE3E6D',
            },
        ],
    };
    return(
        <MainContainer>
            {dataFromQuery.length > 0 ?
                <Bar height='300px' options={options} data={data} />
                :
                <span>Нет актуальных данных</span>
            }
        </MainContainer>
    )
}

export default NPSActivity;