import { Box, Paper } from '@mui/material';
import CurriculumFilters from '../components/CurriculumFilters';
import DashboardFullPageContainer from '../components/DashboardFullPageContainer';
import DashboardPageContainer from '../components/DashboardPageContainer';
import Typography from '../components/material/Typography';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import { useAppSelector } from '../store';
import NPSChart from '../views/NPSPage/NPSChart';
import NPSGrid from '../views/NPSPage/NPSGrid';
import NPSPageHeader from '../views/NPSPage/NPSPageHeader';

export default function NPSPage() {
  const { program, course } = useAppSelector((state) => state.filters);

  useSetHeaderTitle(
    'NPS',
    course.value &&
      `${program?.value?.title ? `${program.value.title} - ` : ''}${
        course.value.title
      }`
  );

  return (
    <DashboardPageContainer>
      <NPSPageHeader />

      {!course.value && (
        <DashboardFullPageContainer>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" mb={1}>
              Пожалуйста, выберите программу и курс
            </Typography>
            <CurriculumFilters program course />
          </Paper>
        </DashboardFullPageContainer>
      )}

      {course.value && (
        <>
          <Box sx={{ height: 400 }} mb={6}>
            <Typography variant="h6">NPS по курсу</Typography>
            <NPSChart />
          </Box>
          <NPSGrid />
        </>
      )}
    </DashboardPageContainer>
  );
}
