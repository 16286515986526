import { Box, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import AutocompleteField from '../../components/AutocompleteField';
import CustomDataGrid from '../../components/CustomDataGrid';
import Typography from '../../components/material/Typography';
import { STUDENT_TYPE_LABELS } from '../../constants';
import NPSService from '../../services/NPSService';
import UnitsService from '../../services/UnitsService';
import { useAppDispatch, useAppSelector } from '../../store';
import { setUnitValue } from '../../store/filtersSlice';
import { TNPSRecord } from '../../types';
import { Unit } from '../../types/units';
import CheckNPSModal from './CheckNPSModal';

export default function NPSGrid() {
	const columns: GridColDef<TNPSRecord>[] = useMemo(
		() =>
			[
				{
					field: 'firstName',
					headerName: 'Имя',
					width: 150,
					flex: 1,
					renderCell: (params) =>
						`${params.row.firstName} ${params.row.lastName}`,
				},
				{
					field: 'email',
					headerName: 'Email',
					width: 150,
					flex: 1,
				},
				{
					field: 'studentType',
					headerName: 'Тип студента',
					width: 150,
					flex: 1,
					renderCell: (params) =>
						STUDENT_TYPE_LABELS[params.row.studentType ?? ''],
				},
				{
					field: 'title',
					headerName: 'Профессия',
					width: 150,
					flex: 1,
				},
				{
					field: 'score',
					headerName: 'Оценка',
					width: 100,
				},
				{
					field: 'commentary',
					headerName: 'Комментарий',
					flex: 1,
				},
			] as GridColDef<TNPSRecord>[],
		[]
	);

	const { control } = useForm();
	const dispatch = useAppDispatch();
	const program = useAppSelector((state) => state.filters.program);
	const course = useAppSelector((state) => state.filters.course);
	const unit = useAppSelector((state) => state.filters.unit);

	const [activeRecord, setActiveRecord] = useState<TNPSRecord | null>(null);
	const [isCheckNPSModalOpen, setIsCheckNPSModalOpen] = useState(false);

	const { data: units, isLoading: unitsIsLoading } = useQuery(
		['units', course?.id],
		() => UnitsService.getUnitsByCourse(unit?.id ?? ''),
		{
			enabled: Boolean(program.value && course.value),
		}
	);

	const { data: nps = [], isLoading: npsIsLoading } = useQuery(
		['nps', unit?.id],
		() => NPSService.getNPSForUnit(unit?.id ?? ''),
		{
			enabled: Boolean(program.value && course.value && unit.value),
		}
	);

	const onRowClick = useCallback((params: any) => {
		setActiveRecord(params.row as TNPSRecord);
		setIsCheckNPSModalOpen(true);
	}, []);

	return (
		<Box>
			<Grid container spacing={2} alignItems="center" mb={4}>
				<CheckNPSModal
					isOpen={isCheckNPSModalOpen}
					onClose={() => setIsCheckNPSModalOpen(false)}
					npsRecord={activeRecord}
				/>
				<Grid xs={12} sm="auto" item>
					<Typography variant="h6">NPS по блоку</Typography>
				</Grid>

				<Grid xs={12} sm={6} md={4} lg={3} item>
					<AutocompleteField
						formControlProps={{ fullWidth: true }}
						autocompleteProps={{
							value: unit.value ?? null,
							options: units,
							loading: unit.isLoading || unitsIsLoading,
							onChange: (event, value) => dispatch(setUnitValue(value)),
							getOptionLabel: (value) => (value as Unit).title,
							isOptionEqualToValue: (option, value) => option?.id === value?.id,
						}}
						defaultValue={null}
						control={control}
						name="unit"
						disabled={!program.value || !course.value}
					/>
				</Grid>
			</Grid>
			{!unit.value ? (
				<Typography color="gray" textAlign="center">
					Выберите блок
				</Typography>
			) : (
				<CustomDataGrid
					contextMenuItems={[]}
					columns={columns}
					rows={nps}
					loading={npsIsLoading}
					autoHeight
					onRowClick={onRowClick}
				/>
			)}
		</Box>
	);
}
