import { Add, Check, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Divider,
	Grid,
	IconButton,
	styled,
	Tooltip,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import FileUploadField from '../../../components/FileUploadField';
import TextField from '../../../components/material/TextField';
import Typography from '../../../components/material/Typography';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../../constants';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import useToast from '../../../hooks/useToast';
import QuizzesService from '../../../services/QuizzesService';
import {
	PostQuizAnswer,
	PostQuizQuestion,
	Quiz,
	QuizAnswer,
	QuizQuestion,
} from '../../../types';
import getTextFieldErrorProps from '../../../utils/getTextFieldErrorProps';
import QuizFormAnswer from './QuizFormAnswer';

interface Props {
	quiz: Quiz;
	question: QuizQuestion | PostQuizQuestion;
	index: number;
	disabled?: boolean;
	onDelete: (index: number) => any;
	onUpdate: (index: number, data: QuizQuestion) => any;
}

export interface QuizAnswersValues {
	answers: Array<QuizAnswer | PostQuizAnswer>;
}

const QuestionContainer = styled(Grid)(({ theme }) => ({
	'.MuiButton-root.answer-submit, .MuiButton-root.question-submit': {
		maxWidth: 38,
		minHeight: 38,
		marginTop: theme.spacing(4.1),

		'.MuiTypography-root': {
			display: 'none',
		},

		[theme.breakpoints.up('sm')]: {
			'.MuiButton-endIcon': {
				marginLeft: theme.spacing(-0.5),
			},
		},

		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
			maxWidth: 1000,

			'.MuiTypography-root': {
				display: 'block',
			},
		},
	},
}));

export default function QuizFormQuestion({
	quiz,
	question,
	index,
	onDelete,
	onUpdate,
	disabled,
}: Props) {

	const toast = useToast();
	const withConfirm = useConfirmDialog();

	const isUpdate = (question as QuizQuestion)?.id !== undefined;

	const {
		handleSubmit,
		control,
		register,
		formState: { errors },
	} = useForm<PostQuizQuestion>({
		defaultValues: {
			...(question as PostQuizQuestion),
			answers: question?.answers ?? [],
		},
	});

	const {
		fields: answers,
		append,
		update,
		remove,
	} = useFieldArray({
		control,
		name: 'answers',
		keyName: 'key',
	});

	const saveQuestionMutation = useMutation(
		isUpdate ? 'updateQuestion' : 'createQuestion',
		(data: PostQuizQuestion) =>
			isUpdate
				? QuizzesService.updateQuestion(
						(question as QuizQuestion)?.id ?? '',
						data
				  )
				: QuizzesService.createQuestion(quiz.id ?? '', data),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				onUpdate(index, data);
			},
		}
	);

	const deleteQuestionMutation = useMutation(
		'deleteQuestion',
		() => QuizzesService.deleteQuestion((question as QuizQuestion)?.id),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				onDelete(index);
			},
		}
	);

	const isLoading =
		saveQuestionMutation.isLoading || deleteQuestionMutation.isLoading;

	const onDeleteClick = () => {
		if (!isUpdate) {
			onDelete(index);
			return;
		}

		if (isLoading) {
			return;
		}

		withConfirm({
			title: 'Удалить вопрос?',
			onConfirm() {
				deleteQuestionMutation.mutate();
			},
			confirmButtonText: 'Удалить',
			cancelButtonText: 'Отмена',
		});
	};

	const onAddAnswer = () => {
		append({ assets: [], isCorrect: false, text: '' });
	};

	const onDeleteAnswer = (index: number) => {
		remove(index);
	};

	const onUpdateAnswer = (index: number, data: QuizAnswer) => {
		update(index, data);
	};

	const onSubmit = (data: PostQuizQuestion) => {
		if (isLoading) {
			return
		}

		saveQuestionMutation.mutate(data);
	};

	return (
		<QuestionContainer item container spacing={2}>
			<Grid item container xs={12} sm spacing={1}>
				<Grid item xs="auto">
					<IconButton
						sx={{ mt: 4 }}
						edge="start"
						onClick={onDeleteClick}
						disabled={disabled}
					>
						<RemoveCircleOutlineOutlined />
					</IconButton>
				</Grid>
				<Grid item xs>
					<TextField
						separateLabel
						label={`Вопрос №${index + 1}`}
						fullWidth
						inputProps={{
							...register('question.text', {
								required: 'Введите вопрос',
							}),
						}}
						{...getTextFieldErrorProps(errors.question?.text)}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={5} lg={4}>
				<FileUploadField
					control={control}
					name={`question.assets.0`}
					label="Изображение"
					formControlProps={{ fullWidth: true }}
				/>
			</Grid>
			<Grid item xs={12} sm={'auto'}>
				<Tooltip title="Сохранить вопрос">
					<LoadingButton
						fullWidth
						variant="contained"
						color="secondary"
						loading={isLoading}
						onClick={handleSubmit(onSubmit)}
						className="question-submit"
						endIcon={<Check />}
					>
						<Typography>Сохранить вопрос </Typography>
					</LoadingButton>
				</Tooltip>
			</Grid>

			<Grid item container xs={12} spacing={1}>
				{question &&
					'id' in question &&
					answers.map((el, i) => (
						<QuizFormAnswer
							question={question}
							key={el.key}
							answer={el}
							disabled={answers.length === 0}
							index={i}
							questionIndex={index}
							onDelete={onDeleteAnswer}
							onUpdate={onUpdateAnswer}
						/>
					))}

				{isUpdate && (
					<Grid item xs="auto" ml="auto">
						<Button onClick={onAddAnswer} endIcon={<Add />}>
							Добавить ответ
						</Button>
					</Grid>
				)}
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>
		</QuestionContainer>
	);
}
