import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import UserActivityService from "../../services/UserActivity";
import NPSActivity from "./NPSActivity";
import {Grid} from "@mui/material";
import LessonActivity from "./LessonActivity";
import QuizActivity from "./QuizActivity";
import AssignmentActivity from "./AssignmentActivity";
import CourseInfoActivity from "./CourseInfoActivity";

const ActivityDiagrams = () => {
    const { id } = useParams();

    const { data: userAssignmentSubmissions = [], isLoading: isLoadingAssignments } = useQuery(
        ['getAssignmentSubmission', id],
        () => UserActivityService.getAllSubmissionsByUserId(id as string),
        {
            enabled: id !== undefined,
        }
    );

    const { data: userNPSSubmissions = [], isLoading: isLoadingNPS } = useQuery(
        ['getNPSSubmission', id],
        () => UserActivityService.getAllNPSSubmissionsByUserId(id as string),
        {
            enabled: id !== undefined,
        }
    );

    const { data: userQuizSubmissions = [], isLoading: isLoadingQuizzes } = useQuery(
        ['getQuizSubmission', id],
        () => UserActivityService.getAllQuizSubmissionsByUserId(id as string),
        {
            enabled: id !== undefined,
        }
    );

    const { data: userSelfAssignmentSubmissions = [], isLoading: isLoadingSelfAssignments } = useQuery(
        ['getSelfAssignmentSubmission', id],
        () => UserActivityService.getAllSelfAssignmentSubmissionsByUserId(id as string),
        {
            enabled: id !== undefined,
        }
    );

    const { data: userCertificateInfos = [], isLoading: isLoadingCertificateInfo } = useQuery(
        ['getCertificateInfo', id],
        () => UserActivityService.getAllCertificateInfoByUserId(id as string),
        {
            enabled: id !== undefined,
        }
    );

    const { data: userCourseInfos = [], isLoading: isLoadingCourseInfo } = useQuery(
        ['getCourseInfo', id],
        () => UserActivityService.getAllCourseInfoByUserId(id as string),
        {
            enabled: id !== undefined,
        }
    );

    const { data: userCurriculumInfos = [], isLoading: isLoadingCurriculumInfo } = useQuery(
        ['getCurriculumInfo', id],
        () => UserActivityService.getAllCurriculumsInfoByUserId(id as string),
        {
            enabled:  id !== undefined,
        }
    );

    const { data: userLessonInfos = [], isLoading: isLoadingLessonInfo } = useQuery(
        ['getLessonInfo', id],
        () => UserActivityService.getAllLessonsInfoByUserId(id as string),
        {
            enabled:  id !== undefined,
        }
    );

    return(
        <>
            <h1>Статистика</h1>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <LessonActivity data={userLessonInfos} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <AssignmentActivity data={userAssignmentSubmissions} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <NPSActivity data={userNPSSubmissions} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CourseInfoActivity data={userCourseInfos} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuizActivity data={userQuizSubmissions} />
                </Grid>
            </Grid>
        </>
    )
}

export default ActivityDiagrams;