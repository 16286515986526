import { styled } from '@mui/material';
import DashboardPageContainer from '../components/DashboardPageContainer';
import withWhitelistRoles from '../components/withWhitelistRoles';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import BannersControl from '../views/BannersPage/BannersControl';
import BannersPageHeader from '../views/BannersPage/BannersPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function BannersPage() {
	useSetHeaderTitle('Баннеры');
	return (
		<PageContainer>
			<BannersPageHeader />
			<BannersControl />
		</PageContainer>
	);
}

export default withWhitelistRoles(BannersPage, ['admin']);
