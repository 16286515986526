import {
	ButtonBase as MuiButtonBase,
	ButtonBaseProps,
	styled,
} from '@mui/material';
import React from 'react';
import { To } from 'react-router-dom';

interface AdditionalProps extends ButtonBaseProps {
	component?: React.ElementType;
	to?: To;
}

const ButtonBase = styled(MuiButtonBase)<AdditionalProps>(({ theme }) => ({}));

export default ButtonBase;
