import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import CoursesService from '../services/CoursesService';
import LessonsService from '../services/LessonsService';
import ProgramsService from '../services/ProgramsService';
import UnitsService from '../services/UnitsService';
import { useAppDispatch, useAppSelector } from '../store';
import {
  setCourseValue,
  setLessonValue,
  setProgramValue,
  setUnitValue,
} from '../store/filtersSlice';
import { Course, Lesson, Program } from '../types';
import { Unit } from '../types/units';
import AutocompleteField from './AutocompleteField';

export interface CurriculumFiltersProps {
  program?: boolean;
  course?: boolean;
  unit?: boolean;
  lesson?: boolean;
}

interface FormValues {
  program: Program | null;
  course: Course | null;
  unit: Unit | null;
  lesson: any;
  isCommon: boolean;
}

export default function CurriculumFilters({
  program: enableProgram,
  course: enableCourse,
  unit: enableUnit,
  lesson: enableLesson,
}: CurriculumFiltersProps) {
  const isCommon = useAppSelector(
    (state) => state.filters.additionalFilters?.isCommon
  );
  const { program, course, unit, lesson } = useAppSelector(
    (state) => state.filters
  );
  const dispatch = useAppDispatch();

  const { control } = useForm<FormValues>({
    defaultValues: {
      program: program.value ?? null,
      course: course.value ?? null,
      unit: unit.value ?? null,
      lesson: lesson.value ?? null,
      isCommon: isCommon ?? false,
    },
  });

  const { data: programs, isLoading: programsIsLoading } = useQuery(
    'programs',
    () => ProgramsService.getPrograms()
  );

  const { data: courses, isLoading: coursesIsLoading } = useQuery(
    ['courses', program?.id],
    () => CoursesService.getCoursesByProgram(program?.id)
  );

  const { data: units, isLoading: unitsIsLoading } = useQuery(
    ['units', course?.id],
    () => UnitsService.getUnitsByCourse(course?.id),
    {
      enabled: Boolean(course.value),
    }
  );

  const { data: lessons, isLoading: lessonsIsLoading } = useQuery(
    ['lessons', unit?.id],
    () => LessonsService.getLessonsByUnit(unit?.id),
    {
      enabled: Boolean(unit.value),
    }
  );

  return (
    <Stack spacing={2} px={{ xs: 2, sm: 0 }}>
      {!isCommon && enableProgram && (
        <AutocompleteField
          label="Программа"
          formControlProps={{ fullWidth: true }}
          autocompleteProps={{
            value: program.value ?? null,
            onChange: (event, value) => dispatch(setProgramValue(value)),
            options: programs,
            loading: programsIsLoading || program.isLoading,
            getOptionLabel: (value) => (value as Program).title,
            isOptionEqualToValue: (option, value) => option?.id === value?.id,
          }}
          defaultValue={null}
          disabled={programsIsLoading || program.isLoading}
          control={control}
          name="program"
        />
      )}

      {enableCourse && (
        <AutocompleteField
          label="Курс"
          formControlProps={{ fullWidth: true }}
          autocompleteProps={{
            value: course.value ?? null,
            options: courses,
            loading: coursesIsLoading || program.isLoading || course.isLoading,
            onChange: (event, value) => dispatch(setCourseValue(value)),
            getOptionLabel: (value) => (value as Course).title,
            isOptionEqualToValue: (option, value) => option?.id === value?.id,
            groupBy: (option: Course) =>
              option.common ? 'Общие курсы' : 'По направлению',
          }}
          defaultValue={null}
          control={control}
          name="course"
          // disabled={!Boolean(program.value)}
        />
      )}
      {enableUnit && (
        <AutocompleteField
          label="Блок"
          formControlProps={{ fullWidth: true }}
          autocompleteProps={{
            value: unit.value ?? null,
            options: units,
            loading:
              coursesIsLoading ||
              program.isLoading ||
              course.isLoading ||
              unit.isLoading ||
              unitsIsLoading,
            onChange: (event, value) => dispatch(setUnitValue(value)),
            getOptionLabel: (value) => (value as Unit).title,
            isOptionEqualToValue: (option, value) => option?.id === value?.id,
          }}
          defaultValue={null}
          control={control}
          name="unit"
          disabled={!course.value}
        />
      )}
      {enableLesson && (
        <AutocompleteField
          label="Урок"
          formControlProps={{ fullWidth: true }}
          autocompleteProps={{
            value: lesson.value ?? null,
            options: lessons,
            loading:
              coursesIsLoading ||
              program.isLoading ||
              course.isLoading ||
              unit.isLoading ||
              unitsIsLoading ||
              lesson.isLoading ||
              lessonsIsLoading,
            onChange: (event, value) => dispatch(setLessonValue(value)),
            getOptionLabel: (value) => (value as Lesson).title,
            isOptionEqualToValue: (option, value) => option?.id === value?.id,
          }}
          control={control}
          name="lesson"
          disabled={!unit.value}
        />
      )}
    </Stack>
  );
}
