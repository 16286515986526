import DashboardPageHeader from '../../components/DashboardPageHeader';
import { COURSE_TYPES } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';
import { setAdditionalFilter } from '../../store/filtersSlice';

export default function CoursesPageHeader() {
	const additionalFilters = useAppSelector(
		(state) => state.filters.additionalFilters
	);
	const dispatch = useAppDispatch();

	return (
		<DashboardPageHeader
			newButtonText="Добавить курс"
			textFieldProps={{ placeholder: 'Найти курс...' }}
			filters={[
				{
					title: 'Активность',
					onChange: (value: string) =>
						dispatch(setAdditionalFilter({ key: 'courseIsActive', value })),
					hasAllOption: true,
					value: additionalFilters?.courseIsActive,
					options: [
						{ label: 'Активный', value: true },
						{ label: 'Неактивный', value: false },
					],
				},
				{
					hasAllOption: true,
					title: 'Тип курса',
					value: additionalFilters?.courseType,
					options: COURSE_TYPES.map((el) => ({ label: el, value: el })),
					onChange: (value: string) =>
						dispatch(setAdditionalFilter({ key: 'courseType', value })),
				},
			]}
			curriculumFiltersProps={{ program: true }}
		/>
	);
}
