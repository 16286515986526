import {
	Autocomplete as MuiAutocomplete,
	AutocompleteProps,
	AutocompleteRenderInputParams,
	CircularProgress,
	FormControlProps,
	FormHelperText,
	FormLabel,
} from '@mui/material';
import React, { forwardRef, ReactNode } from 'react';
import { FormControl } from '@mui/material';
import {
	Control,
	Controller,
	ControllerFieldState,
	ControllerProps,
} from 'react-hook-form';
import TextField, { TextFieldProps } from './material/TextField';

interface MyAutocomplete<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
> extends Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
	renderInput?: (props: AutocompleteRenderInputParams) => ReactNode;
	options?: Array<any>;
}

interface Props<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
> extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	render?: any;
	label?: string;
	disabled?: boolean;
	autocompleteProps?: MyAutocomplete<T, Multiple, DisableClearable, FreeSolo>;
	formControlProps?: FormControlProps;
	textFieldProps?: TextFieldProps;
}

export default function AutocompleteField<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
>({
	formControlProps,
	autocompleteProps,
	textFieldProps,
	label,
	disabled,
	...props
}: Props<T, Multiple, DisableClearable, FreeSolo>) {
	const renderInput =
		(fieldState: ControllerFieldState) =>
		(props: AutocompleteRenderInputParams) =>
			(
				<TextField
					{...props}
					{...textFieldProps}
					error={Boolean(fieldState.error)}
				/>
			);

	const defaultValue = autocompleteProps?.multiple ? [] : null;

	return (
		<Controller
			{...props}
			control={props.control}
			name={props.name}
			render={({ field: { value, onChange, ...field }, fieldState }) => (
				<FormControl
					{...formControlProps}
					error={Boolean(fieldState.error)}
					disabled={disabled}
				>
					{label && (
						<FormLabel
							sx={(theme) => ({
								mb: 1,
								opacity: disabled ? 0.7 : 1,
							})}
						>
							{label}
						</FormLabel>
					)}
					<MuiAutocomplete
						value={value ?? defaultValue}
						onChange={(event, value) => onChange(value)}
						{...autocompleteProps}
						options={autocompleteProps?.options ?? []}
						renderInput={
							autocompleteProps?.renderInput ?? renderInput(fieldState)
						}
						noOptionsText="Ничего не найдено"
						disabled={disabled}
					/>

					{fieldState.error && (
						<FormHelperText>{fieldState.error.message}</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	);
}
