import { DoneAll } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import DashboardPageHeader from '../../components/DashboardPageHeader';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useToast from '../../hooks/useToast';
import SubmissionsService from '../../services/SubmissionsService';
import { useAppSelector } from '../../store';

interface Props {
	assignmentId?: string;
}

export default function SelfAssignmentsPageHeader({ assignmentId }: Props) {
	const toast = useToast();
	const withConfirm = useConfirmDialog();

	const { lesson } = useAppSelector((state) => state.filters);

	const queryClient = useQueryClient();
	const validateAllMutation = useMutation(
		['validateAllMutation'],
		() => SubmissionsService.validateAllSelfSubmissions(assignmentId ?? ''),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				queryClient.refetchQueries([
					'getSelfAssignmentSubmissions',
					assignmentId,
				]);
			},
		}
	);

	const onValidateAll = () => {
		if (validateAllMutation.isLoading) {
			return;
		}

		withConfirm({
			title: 'Принять все задания?',
			subtitle:
				'Все домашние задания студентов для данного урока будут помечены как принятые',
			cancelButtonText: 'Отмена',
			confirmButtonText: 'Принять',
			onConfirm: () => validateAllMutation.mutate(),
		});
	};

	return (
		<DashboardPageHeader
			allowSearch
			newButtonText="Добавить самостоятельную работу"
			textFieldProps={{ placeholder: 'Найти самостоятельную работу...' }}
			curriculumFiltersProps={{
				program: true,
				course: true,
				lesson: true,
				unit: true,
			}}
			rightSideAdornment={
				lesson.value && (
					<>
						{
							<Button
								fullWidth
								endIcon={<DoneAll />}
								color="success"
								variant="outlined"
								disableElevation
								onClick={onValidateAll}
							>
								Принять все
							</Button>
						}
					</>
				)
			}
			disableNewButton
		/>
	);
}
