import { ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import DashboardPageContainer from '../components/DashboardPageContainer';
import FileUploadField from '../components/FileUploadField';
import RouterLink from '../components/RouterLink';
import withWhitelistRoles from '../components/withWhitelistRoles';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from '../constants';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import useToast from '../hooks/useToast';
import UsersService from '../services/UsersService';
import { User } from '../types';

interface FormValues {
	file: File;
}

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function UsersBatchUploadPage() {
	const toast = useToast();
	const navigate = useNavigate();
	useSetHeaderTitle('Массовая загрузка', undefined, '/users');

	const [erroredUsers, setErroredUsers] = useState<User[]>([]);
	const columns: GridColumns = [
		{ field: 'firstName', headerName: 'Имя*', minWidth: 120, flex: 1 },
		{ field: 'lastName', headerName: 'Фамилия*', minWidth: 120, flex: 1 },
		{ field: 'email', headerName: 'Почта*', minWidth: 250, flex: 1 },
		{ field: 'password', headerName: 'Пароль*', minWidth: 120, flex: 1 },
		{ field: 'programName', headerName: 'Поток', minWidth: 120, flex: 1 },
		{
			field: 'phoneNumber',
			headerName: 'Номер телефона',
			minWidth: 130,
			flex: 1,
		},
		{ field: 'birthdate', headerName: 'Дата рождения', minWidth: 130, flex: 1 },
		{
			field: 'userType',
			headerName: 'Тип пользователя*',
			minWidth: 150,
			flex: 1,
		},
	];

	const { control, handleSubmit } = useForm<FormValues>();

	const saveFormMutation = useMutation(
		'batchCreateUsers',
		(file: File) => UsersService.batchUpload(file),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				const { erroredUsers } = data;

				if (!erroredUsers?.length) {
					toast(DEFAULT_SUCCESS_MESSAGE, 'success');
					navigate('/users');
					return;
				}

				toast(
					'Не все пользователи были созданы. Пожалуйста, исправьте ошибки и повторно загрузите файл.',
					'warning'
				);
				setErroredUsers(erroredUsers.map((el) => ({ ...el, id: uuid() })));
			},
		}
	);

	const { isLoading } = saveFormMutation;

	const onSubmit = (data: FormValues) => {
		if (isLoading) {
			return;
		}

		setErroredUsers([]);
		saveFormMutation.mutate(data.file);
	};

	return (
		<PageContainer>
			<Grid
				container
				spacing={2}
				component="form"
				onSubmit={handleSubmit(onSubmit)}
				mb={4}
			>
				<Grid item xs={12}>
					<Typography color="text.secondary">
						Массовая загрузка пользователей выполняется через .xlsx и .csv
						файлы.
					</Typography>
					<Typography color="text.secondary">
						Пожалуйста, убедитесь, что в файле нет пустых строк и все значения
						валидны.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8} md={6} lg={4}>
					<FileUploadField
						control={control}
						name="file"
						label="Пользователи"
						rules={{ required: 'Загрузите файл' }}
						formControlProps={{ disabled: isLoading, fullWidth: true }}
						allowedExtensions={[
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							'application/vnd.ms-excel',
							'text/csv',
						]}
					/>
				</Grid>
				<Grid item xs={12} sm={4} md={6} lg={8}>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							mt: { xs: 0, sm: 4 },

							'.MuiButton-root.MuiButton-sizeMedium': {
								minWidth: 150,
							},
						}}
					>
						<LoadingButton
							variant="contained"
							color="secondary"
							type="submit"
							loading={isLoading}
						>
							Загрузить
						</LoadingButton>

						<Button
							component={RouterLink}
							to="/users"
							endIcon={<ArrowForwardIos />}
							color="inherit"
						>
							Назад к пользователям
						</Button>
					</Stack>
				</Grid>
			</Grid>
			{erroredUsers && erroredUsers.length > 0 && (
				<Box>
					<Typography variant="h2" mb={1}>
						Ошибки
					</Typography>
					<Typography color="text.secondary" mb={2}>
						Данные пользователи не были созданы из-за ошибок в данных.
						Пожалуйста, исправьте ошибки и повторно загрузите файл.
					</Typography>
					<Typography color="text.secondary" mb={1} fontSize="small">
						Обязательные поля помечены звездочкой *
					</Typography>

					<DataGrid
						disableSelectionOnClick
						sx={{ height: 600 }}
						rows={erroredUsers}
						columns={columns}
						paginationMode="client"
					/>
				</Box>
			)}
		</PageContainer>
	);
}

export default withWhitelistRoles(UsersBatchUploadPage, ['admin']);
