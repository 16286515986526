import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import DashboardPageContainer from '../components/DashboardPageContainer';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import LessonsService from '../services/LessonsService';
import { useAppSelector } from '../store';
import AssignmentsGrid from '../views/AssignmentsPage/AssignmentsGrid';
import AssignmentsPageHeader from '../views/AssignmentsPage/AssignmentsPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

export default function AssignmentsPage() {
  const { program, course, unit, lesson } = useAppSelector(
    (state) => state.filters
  );

  useSetHeaderTitle(
    'Домашнее задание',
    course.value &&
      unit.value &&
      lesson.value &&
      `${program?.value?.title ? `${program.value.title} - ` : ''}${
        course.value.title
      } - ${unit.value.title} - ${lesson.value.title}`
  );

  const { data: lessonData, isLoading: isLoadingLessonData } = useQuery(
    ['getLesson', lesson.id],
    () => LessonsService.getLesson(lesson.id ?? ''),
    {
      enabled: Boolean(lesson.id),
    }
  );

  const assignmentId = useMemo<string | undefined>(() => {
    if (!lessonData?.assignments || lessonData?.assignments?.length === 0) {
      return;
    }

    return lessonData.assignments[0].id;
  }, [lessonData]);

  return (
    <PageContainer>
      <AssignmentsPageHeader assignmentId={assignmentId} />

      <AssignmentsGrid
        assignmentId={assignmentId}
        isLoading={isLoadingLessonData}
      />
    </PageContainer>
  );
}
