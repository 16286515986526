export default function fillFormData(formData: FormData, data: Object) {
	Object.entries(data).forEach(([key, value]) => {
		if (
			typeof value === 'object' &&
			Object.keys(value).length === 0 &&
			Object.getPrototypeOf(value) === Object.prototype
		) {
			return;
		}

		if (value instanceof Date) {
			formData.append(key, value.toISOString());
			return;
		}

		if (Array.isArray(value) && value.length > 0) {
			value.forEach((el) => el && formData.append(`${key}[]`, el));
			return;
		}

		if (value === undefined) {
			return;
		}

		formData.append(key, value);
	});
}
