import { v4 as uuid } from 'uuid';
import { Launch } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	Input,
	InputProps,
	Link as MuiLink,
	Stack,
	styled,
} from '@mui/material';
import {FormEvent, forwardRef, DragEvent, useRef} from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import Typography from './material/Typography';
import useToast from '../hooks/useToast';

const FieldContainer = styled(FormControl)(({ theme }) => ({
	maxWidth: '100%',

	'.MuiInput-root, input': {
		display: 'none',
	},

	'.field-label': {
		minHeight: theme.spacing(4),
		maxHeight: theme.spacing(4),
	},
}));

interface FieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	render?: any;
	label?: string;
	inputProps?: InputProps;
	formControlProps?: FormControlProps;
	allowedExtensions?: string[];
}

/* 
    A file upload field component with label.
*/

const FileUploadField = forwardRef<HTMLDivElement, FieldProps>(
	(
		{ label, inputProps, formControlProps,
			allowedExtensions, ...props }, ref) => {
		const toast = useToast();
		const inputRef = useRef();
		const { current: _id } = useRef(uuid());

		const onFormControlChange =
			(onChange: (value: any) => void) =>
			(event: FormEvent<HTMLInputElement>) => {
				const files = (event.target as HTMLInputElement).files;

				if (!files || files.length === 0) {
					return;
				}

				const file = files[0];

				if (!file) {
					return;
				}

				const fileType = file.type;

				if (allowedExtensions && !allowedExtensions.includes(fileType)) {
					toast(
						`Неподдерживаемый формат файла. Доступные форматы: ${allowedExtensions
							.join(', ')
							.replace(/image\//g, '.')}`,
						'error'
					);
					return;
				}

				onChange(files[0]);
			};

		const onFileRemove = (onChange: (value: any) => void) => () => {
			if (formControlProps?.disabled) {
				return;
			}

			onChange(undefined);

			if (inputRef.current) {
				(inputRef.current as HTMLInputElement).value = '';
			}
		};

		const onDraggingStyle = (e: DragEvent<HTMLDivElement>) => {
			const elem = e.currentTarget;
			if(elem) {
				elem.style.border = '1px solid #5aace7';
				elem.style.boxShadow = '0 0 15px #5aace7'
			}
		}
		const onDraggingStyleClear = (e: DragEvent<HTMLDivElement>) => {
			const elem = e.currentTarget;
			if(elem) {
				elem.style.removeProperty('border');
				elem.style.removeProperty('box-shadow');
			}
		}


		return (
			<Controller
				{...props}
				render={({ field: { onChange, ...field }, fieldState }) => {
					// if (typeof field.value === 'string') {
					// 	return (
					// 		<Box>
					// 			<FormLabel htmlFor={_id} sx={{ mb: 1 }}>
					// 				{label}
					// 			</FormLabel>

					// 			<Typography
					// 				variant="body2"
					// 				sx={{ position: 'relative', zIndex: 10, mt: 2 }}
					// 			>
					// 				<MuiLink href={field.value} target="_blank">
					// 					Посмотреть файл <Launch fontSize="small" />
					// 				</MuiLink>
					// 			</Typography>
					// 		</Box>
					// 	);
					// }

					return (
						<FieldContainer
							{...field}
							{...formControlProps}
							onChange={onFormControlChange(onChange)}
							ref={ref}
							error={Boolean(fieldState.error)}
						>
							<Stack
								direction="row"
								justifyContent="space-between"
								className="field-label"
							>
								<FormLabel htmlFor={_id}>{label}</FormLabel>

								{typeof field.value === 'string' && (
									<Typography
										variant="body2"
										sx={{
											position: 'relative',
											zIndex: 10,
										}}
									>
										<MuiLink href={field.value} target="_blank">
											Файл&nbsp;
											<Launch fontSize="small" />
										</MuiLink>
									</Typography>
								)}

								{/* </Stack>(
								 	field.value &&
									field.value.name && (
										<Button
											size="small"
											endIcon={<HighlightOff />}
											color="inherit"
											onClick={onFileRemove(onChange)}
											disabled={formControlProps?.disabled}
										>
											Удалить
										</Button>
									)
								)} */}
							</Stack>
							<Button
								component="label"
								variant="outlined"
								color={fieldState.error ? 'error' : 'inherit'}
								htmlFor={_id}
								disabled={formControlProps?.disabled}
								onDrop={(e: any) => {
									e.preventDefault();
									e.stopPropagation();
									onChange(e.dataTransfer.files[0]);
									onDraggingStyleClear(e);
								}}
								onDragOver={(e: any) => {
									e.preventDefault();
									e.stopPropagation();
								}}
								onDragEnter={(e: any) => {
									e.preventDefault();
									e.stopPropagation();
									onDraggingStyle(e);
								}}
								onDragLeave={(e: any) => {
									if(!e.currentTarget.contains(e.relatedTarget)){
										onDraggingStyleClear(e);
									}
									e.stopPropagation();
								}}
							>
								<Typography maxLines={1} maxWidth="100%">
									{field.value?.name ?? 'Загрузить'}
								</Typography>
							</Button>

							<Input
								type="file"
								{...inputProps}
								inputRef={inputRef}
								id={_id}
								name={inputProps?.id ?? props.name}

								inputProps={{
									...inputProps?.inputProps,

									...(allowedExtensions && {
										accept: allowedExtensions.join(','),
									}),
								}}
							/>

							{Boolean(fieldState.error) && (
								<FormHelperText>{fieldState.error?.message}</FormHelperText>
							)}
						</FieldContainer>
					);
				}}
			/>
		);
	}
);

export default FileUploadField;
