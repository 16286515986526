import {
	Box,
	FormLabel,
	Grid,
	MenuItem,
	Pagination,
	Select,
} from '@mui/material';
import {
	gridPageCountSelector,
	gridPageSelector,
	gridPageSizeSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid';

export default function CustomPagination() {
	const apiRef = useGridApiContext();
	const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<Grid container spacing={1} width="fit-content" sx={{ py: 1 }}>
			<Grid
				item
				xs={12}
				sm="auto"
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<FormLabel
					id="gridPageSizeSelectLabel"
					htmlFor="gridPageSizeSelect"
					sx={{ mr: 1 }}
					className="regular"
				>
					Строк на странице:
				</FormLabel>
				<Select
					name="gridPageSizeSelect"
					id="gridPageSizeSelect"
					labelId="gridPageSizeSelectLabel"
					value={pageSize}
					variant="standard"
					onChange={({ target: { value } }) => {
						let res: number = +value;

						apiRef.current.setPageSize(res);
					}}
					sx={{ mr: 1 }}
				>
					{[10, 20, 50, 100].map((size, i) => (
						<MenuItem value={size} key={`grid-size-option-${size}-${i}`}>
							{size}
						</MenuItem>
					))}
				</Select>
			</Grid>
			<Grid
				item
				xs={12}
				sm="auto"
				sx={{ display: 'flex', justifyContent: 'center' }}
			>
				<Pagination
					count={pageCount}
					page={page + 1}
					onChange={(event, value) => apiRef.current.setPage(value - 1)}
					siblingCount={1}
					boundaryCount={1}
				/>
			</Grid>
		</Grid>
	);
}
