import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import Loader from '../../components/Loader';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import {ATTACHMENT_TYPES} from '../../constants';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import LessonsService from '../../services/LessonsService';
import {Assignment, Attachment, PostLesson, SelfAssignment,} from '../../types';
import LessonForm from '../../views/forms/LessonForm';

function EditLessonPage() {
  const { id } = useParams();

  const isUpdate = id !== undefined;

  const { data: lesson, isLoading } = useQuery(
    ['lesson', id],
    () => LessonsService.getLesson(id ?? ''),
    {
      refetchOnWindowFocus: false,
      enabled: isUpdate,
    }
  );

  const formValues = useMemo<Partial<PostLesson> | undefined>(() => {
    if (!isUpdate || !lesson) {
      return;
    }

    return {
      title: lesson.title,
      description: lesson.description,
      playlist: lesson.playlist,
      isLastLesson: lesson?.isLastLesson,
    } as Partial<PostLesson>;
  }, [lesson, isUpdate]);

  const attachments = useMemo<Attachment[] | []>(() => {
    if (!isUpdate || !lesson || !lesson.attachments) {
      return [];
    }

    return lesson.attachments.filter(
        (el) => el.type === ATTACHMENT_TYPES.DEFAULT
    );
  }, [lesson, isUpdate]);

  const assignment = useMemo<Assignment | undefined>(() => {
    if (!isUpdate || !lesson || !lesson.assignments) {
      return;
    }

    return lesson.assignments[0];
  }, [lesson, isUpdate]);

  const selfAssignment = useMemo<SelfAssignment | undefined>(() => {
    if (!isUpdate || !lesson || !lesson.selfAssignments) {
      return;
    }

    return lesson.selfAssignments[0];
  }, [lesson, isUpdate]);

  const quizId = useMemo(
    () =>
      isUpdate && lesson?.quizzes && lesson.quizzes.length > 0
        ? lesson.quizzes[0]
        : undefined,
    [lesson, isUpdate]
  );

  useSetHeaderTitle(
    isUpdate ? 'Редактировать урок' : 'Новый урок',
    undefined,
    '/curriculum/lessons'
  );

  return (
    <DashboardPageContainer>
      {isUpdate && isLoading ? (
        <Loader />
      ) : (
        <LessonForm
          isUpdate={isUpdate}
          defaultValues={formValues}
          attachments={attachments}
          glossary={lesson?.glossary}
          assignment={assignment}
          selfAssignment={selfAssignment}
          quizId={quizId}
        />
      )}
    </DashboardPageContainer>
  );
}

export default withWhitelistRoles(EditLessonPage, ['admin']);
