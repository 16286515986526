import { Add, DeleteOutline, RemoveCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { MouseEventHandler, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import CheckboxField from '../../../components/CheckboxField';
import TextField from '../../../components/material/TextField';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../../constants';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import useToast from '../../../hooks/useToast';
import LessonsService from '../../../services/LessonsService';
import { Glossary, PostGlossary } from '../../../types';
import getTextFieldErrorProps from '../../../utils/getTextFieldErrorProps';

type GlossaryFormValues = PostGlossary & {
	isActive: boolean;
};

interface Props {
	isDisabled: boolean;
	glossary?: Glossary;
}

export default function GlossaryForm({ isDisabled, glossary }: Props) {
	const isUpdate = glossary?.id !== undefined;
	const {
		control,
		watch,
		register,
		setValue,
		formState: { errors, isDirty },
		resetField,
		handleSubmit,
		reset,
	} = useForm<GlossaryFormValues>({
		defaultValues: {
			isActive: Boolean(glossary?.entries),
			entries: glossary?.entries,
		},
	});

	const queryClient = useQueryClient();

	const toast = useToast();
	const withConfirm = useConfirmDialog();
	const { id } = useParams();

	const saveGlossaryMutation = useMutation(
		isUpdate ? 'updateGlossary' : 'updateGlossary',
		(data: PostGlossary) =>
			isUpdate
				? LessonsService.updateGlossary(glossary.id, data)
				: LessonsService.createGlossary(id ?? '', data),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				queryClient.refetchQueries(['lesson', id]);
			},
		}
	);

	const deleteGlossaryMutation = useMutation(
		'deleteGlossary',
		() => LessonsService.deleteGlossary(glossary?.id ?? ''),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				queryClient.refetchQueries(['lesson', id]);
			},
		}
	);

	const isLoading =
		saveGlossaryMutation.isLoading || deleteGlossaryMutation.isLoading;

	const watchIsActive = watch('isActive');

	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: 'entries',
	});

	const onAddTermClick = () => {
		append({ definition: '', term: '' });
	};

	const onRemoveTermClick: (index: number) => MouseEventHandler =
		(index: number) => () => {
			if (fields.length === 1) {
				onCancel();
				return;
			}

			remove(index);
		};

	const onCancel = () => {
		if (!glossary?.id) {
			setValue('isActive', false);
			return;
		}

		if (isLoading) {
			return;
		}

		withConfirm({
			title: 'Удалить словарь?',
			cancelButtonText: 'Отмена',
			confirmButtonText: 'Удалить',
			onConfirm: () => deleteGlossaryMutation.mutate(),
		});
	};

	const onReset = () => {
		reset({
			isActive: isUpdate,
			entries: glossary?.entries,
		});
	};

	const onSubmit = (data: GlossaryFormValues) => {
		if (isLoading) {
			return;
		}

		saveGlossaryMutation.mutate({ entries: data.entries });
	};

	useEffect(() => {
		if (watchIsActive) {
			if (fields?.length === 0 && !isDisabled) {
				append({ definition: '', term: '' });
			}
		} else {
			if (glossary) {
				setValue('isActive', true);
				onCancel();
				return;
			}

			replace([]);
			resetField('entries');
		}
	}, [watchIsActive, isDisabled]);

	useEffect(() => {
		onReset();
	}, [glossary]);

	return (
		<Grid item xs={12} component="form" onSubmit={handleSubmit(onSubmit)}>
			<Divider sx={{ mb: 1 }} />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}
				sx={{
					mb: 1,
					position: 'relative',
					zIndex: 1,
				}}
			>
				<CheckboxField
					control={control}
					name="isActive"
					label="Словарь"
					formControlLabelProps={{
						disabled: isDisabled || isLoading,
					}}
				/>

				<Box sx={(theme) => ({ color: theme.palette.grey[400] })}>
					{isLoading ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						glossary?.id && (
							<Tooltip title="Удалить">
								<IconButton disabled={isLoading} onClick={onCancel} edge="end">
									<DeleteOutline />
								</IconButton>
							</Tooltip>
						)
					)}
				</Box>
			</Stack>
			{watchIsActive && (
				<Grid container spacing={2}>
					<>
						{fields.map((el, i) => (
							<Grid item container xs={12} spacing={1} key={uuid()}>
								<Grid item xs="auto">
									<IconButton
										edge="start"
										onClick={onRemoveTermClick(i)}
										sx={{ mt: 3.8 }}
										disabled={isLoading}
									>
										<RemoveCircle />
									</IconButton>
								</Grid>
								<Grid item xs>
									<TextField
										separateLabel
										fullWidth
										label="Термин"
										inputProps={{
											...register(`entries.${i}.term`, {
												required: 'Введите термин',
											}),
										}}
										// @ts-ignore
										{...getTextFieldErrorProps(errors.entries?.at(i)?.term)}
										disabled={isLoading}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										multiline
										rows={3}
										separateLabel
										fullWidth
										label="Значение"
										inputProps={{
											...register(`entries.${i}.definition`, {
												required: 'Введите значение',
											}),
										}}
										{...getTextFieldErrorProps(
											// @ts-ignore
											errors.entries?.at(i)?.definition
										)}
										disabled={isLoading}
									/>
								</Grid>
							</Grid>
						))}
						<Grid item xs="auto" ml="auto" mb={4}>
							<Button
								endIcon={<Add />}
								onClick={onAddTermClick}
								disabled={isLoading}
							>
								Добавить термин
							</Button>
						</Grid>
						{isDirty && (
							<Grid container spacing={2}>
								<Grid item ml="auto">
									<Button
										fullWidth
										color="inherit"
										onClick={onReset}
										disabled={isLoading}
									>
										Отмена
									</Button>
								</Grid>
								<Grid item>
									<LoadingButton
										fullWidth
										variant="contained"
										type="submit"
										color="secondary"
										loading={isLoading}
									>
										Сохранить словарь
									</LoadingButton>
								</Grid>
							</Grid>
						)}
					</>
				</Grid>
			)}
		</Grid>
	);
}
