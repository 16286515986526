import React from 'react';

/* 
    HOC that wraps a component in a React.Context Provider
*/
export default function withContext(
	Component: React.FC<any>,
	Provider: React.FC<any>
) {
	return () => {
		return (
			<Provider>
				<Component />
			</Provider>
		);
	};
}
