import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DashboardPageContainer from '../components/DashboardPageContainer';
import Loader from '../components/Loader';
import withWhitelistRoles from '../components/withWhitelistRoles';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import UsersService from '../services/UsersService';
import { PostUser } from '../types';
import parseDateString from '../utils/parseDateString';
import UserForm from '../views/forms/UserForm';
import ActivityDiagrams from "../views/UserActivityPage/ActivityDiagrams";

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function EditUserPage() {
  const { id } = useParams();

  const isUpdate = id !== undefined;

  const { data: user, isLoading } = useQuery(
    ['user', id],
    () => UsersService.getUser(id ?? ''),
    { enabled: isUpdate }
  );

  const { data: roles, isLoading: isLoadingRoles } = useQuery(
    'getRoles',
    UsersService.getRoles
  );

  const { data: programs, isLoading: isLoadingPrograms } = useQuery(
    ['userPrograms', id],
    () => UsersService.getUserPrograms(id ?? ''),
    {
      enabled: isUpdate,
    }
  );

  const { data: userRoles, isLoading: isLoadingUserRoles } = useQuery(
    ['userRoles', id],
    () => UsersService.getUserRoles(id ?? ''),
    {
      enabled: isUpdate,
    }
  );

  const userRole = useMemo(() => {
    if (!userRoles || userRoles.length === 0) {
      return undefined;
    }

    return userRoles[0];
  }, [userRoles]);

  const isStudent = useMemo(
    () => userRole?.name.toLowerCase() === 'student',
    [userRole]
  );

  useSetHeaderTitle(
    isUpdate
      ? `Редактирование ${isStudent ? 'студента' : 'администратора'}`
      : 'Новый пользователь',
    undefined,
    '/users'
  );

  const formValues = useMemo<Partial<PostUser> | undefined>(() => {
    if (!isUpdate || !user) {
      return;
    }

    const studentRole = roles?.find(
      (role) => role.name.toLowerCase() === 'student'
    );

    const userRole =
      userRoles && userRoles.length > 0 ? userRoles[0] : undefined;

    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      role: userRole?._id ?? studentRole?.id,
      studentType: user.studentType,
      noLimitVideo: user.noLimitVideo,
      studentProgram: programs,

      ...(user.birthdate && { birthdate: parseDateString(user.birthdate) }),
    } as Partial<PostUser>;
  }, [isUpdate, user, roles, programs, userRoles]);

  return (
    <PageContainer>
      {isUpdate && isLoading ? (
        <Loader />
      ) : (
        <>
          <UserForm
          isUpdate={isUpdate}
          defaultValues={formValues}
          isStudent={isStudent}
          />
          {isUpdate && <ActivityDiagrams /> }
        </>
      )}
    </PageContainer>
  );
}

export default withWhitelistRoles(EditUserPage, ['admin']);
