import { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { useQuery } from 'react-query';
import NPSService from '../../services/NPSService';
import { useAppSelector } from '../../store';
import { INPSUnit } from '../../types';

export default function NPSChart() {
	const course = useAppSelector((state) => state.filters.course);

	const { data: npsList = [] } = useQuery(
		['NPS', course.value],
		() => NPSService.getNPSForCourse(course.value?.id ?? ''),
		{
			enabled: Boolean(course.value?.id),
		}
	);

	const primaryAxis = useMemo(
		(): AxisOptions<INPSUnit> => ({
			getValue: (value) => value.position,
			tickCount: Math.max(npsList.length - 1, 1),
			showGrid: true,
			scaleType: 'linear',
			formatters: {
				tooltip: (value) => npsList[value - 1]?.title,
				scale: (value) => npsList[value - 1]?.title,
			},
		}),
		[npsList]
	);

	const secondaryAxes = useMemo(
		(): AxisOptions<INPSUnit>[] => [
			{
				getValue: (value) => value.nps,
				min: -100,
				max: 100,
				showGrid: true,
				scaleType: 'linear',
				elementType: 'area',
				innerBandPadding: 0.5,
			},
		],
		[]
	);

	return (
		<Chart
			options={{
				primaryAxis,
				secondaryAxes,
				data: [
					{
						label: 'NPS',
						data: npsList,
					},
				],
			}}
		/>
	);
}
