import { ArrowBackIosNew } from '@mui/icons-material';
import {
	AppBar,
	Avatar,
	Box,
	IconButton,
	Link,
	Stack,
	styled,
	Toolbar,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Turn as Hamburger } from 'hamburger-react';
import { Helmet } from 'react-helmet-async';
import { ROLES_MAP, SIDEBAR_WIDTH } from '../constants';
import { useAppDispatch, useAppSelector } from '../store';
import { closeSidebar, toggleSidebar } from '../store/appSlice';
import formatTitle from '../utils/formatTitle';
import Typography from './material/Typography';
import RouterLink from './RouterLink';

const HeaderContainer = styled(AppBar, {
	shouldForwardProp: (prop: string) => !['isSidebarOpen'].includes(prop),
})<{ isSidebarOpen?: boolean }>(({ theme, isSidebarOpen }) => ({
	position: 'fixed',
	transition: theme.transitions.create('all', {
		duration: theme.transitions.duration.short,
	}),
	paddingRight: `0 !important`,
	backgroundColor: theme.palette.background.default,

	'.MuiToolbar-root': {
		maxWidth: '100%',
	},

	[theme.breakpoints.up('md')]: {
		width: `calc(100vw - ${
			isSidebarOpen ? `${SIDEBAR_WIDTH}px` : theme.spacing(7)
		})`,
		left: isSidebarOpen ? SIDEBAR_WIDTH : theme.spacing(7),
		padding: theme.spacing(4, 5, 2),
		paddingRight: `${theme.spacing(5)} !important`,
		marginBottom: 2,

		'.MuiAvatar-root': {
			width: 56,
			height: 56,
		},
	},

	[theme.breakpoints.down('md')]: {
		backgroundColor: isSidebarOpen
			? theme.palette.secondary.main
			: theme.palette.background.default,
		color: isSidebarOpen
			? theme.palette.common.white
			: theme.palette.text.primary,
	},
}));

export default function Header() {
	const theme = useTheme();
	const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const {
		isSidebarOpen,
		headerTitle,
		headerSubtitle,
		profile,
		claims,
		backButtonPath,
	} = useAppSelector((state) => state.app);
	const dispatch = useAppDispatch();

	const onSidebarToggleClick = () => {
		dispatch(toggleSidebar());
	};

	return (
		<HeaderContainer
			elevation={0}
			isSidebarOpen={isSidebarOpen}
			color="inherit"
		>
			<Helmet>
				<title>{formatTitle(headerTitle)}</title>
			</Helmet>
			<Toolbar component={Stack} direction="row" spacing={2}>
				<Stack
					direction="row"
					alignItems={{ xs: 'center', md: 'flex-start', maxWidth: '100%' }}
					flex="1 1 auto"
				>
					{matchesMdDown && (
						<IconButton
							onClick={onSidebarToggleClick}
							edge="start"
							sx={{ minWidth: 58, ml: -2 }}
							size="small"
						>
							<Hamburger
								color={
									isSidebarOpen
										? theme.palette.background.default
										: theme.palette.common.black
								}
								toggled={isSidebarOpen}
								size={23}
								direction="right"
							/>
						</IconButton>
					)}

					<Stack
						position="relative"
						minWidth={250}
						flex="1 1 auto"
						maxWidth={{ xs: 350, sm: 500, md: 100000 }}
					>
						{headerTitle && <Typography variant="h1">{headerTitle}</Typography>}
						{backButtonPath && (
							<Link
								component={RouterLink}
								to={backButtonPath}
								color={
									matchesMdDown && isSidebarOpen ? 'inherit' : 'text.secondary'
								}
								width="fit-content"
								underline="none"
								onClick={() => dispatch(closeSidebar())}
							>
								<Stack direction="row" alignItems="center" spacing={0.5}>
									<ArrowBackIosNew sx={{ fontSize: '1rem' }} />

									<Typography>Назад</Typography>
								</Stack>
							</Link>
						)}
						{headerSubtitle && (
							<Typography
								maxLines={1}
								variant="subtitle2"
								color={
									matchesMdDown && isSidebarOpen ? 'inherit' : 'text.secondary'
								}
							>
								{headerSubtitle}
							</Typography>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" alignItems="center" spacing={{ xs: 2, md: 3 }}>
					{/* <Tooltip title="Оповещения">
						<Fab
							color={matchesMdDown && isSidebarOpen ? 'default' : 'inherit'}
							size={matchesMdDown ? 'small' : 'medium'}
							className="header-button"
						>
							<NotificationsOutlined
								fontSize={matchesMdDown ? 'medium' : 'large'}
							/>
						</Fab>
					</Tooltip> */}

					{profile && (
						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							sx={{ textTransform: 'capitalize' }}
							display={{ xs: 'none', md: 'flex' }}
						>
							<Box>
								<Typography fontWeight={600} textAlign="right">
									{profile.firstName} {profile.lastName?.substring(0, 1)}.
								</Typography>
								<Typography color="grey.500" textAlign="right">
									{claims && claims?.length > 0 && ROLES_MAP[claims[0].name]}
								</Typography>
							</Box>
							<Avatar src="/sfdadd" />
						</Stack>
					)}
				</Stack>
			</Toolbar>
		</HeaderContainer>
	);
}
