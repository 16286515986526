import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { appReducer, AppState } from './appSlice';
import filtersReducer, { FiltersState } from './filtersSlice';
import { sideMenuMiddleware } from './middleware';

export interface StoreState {
	app: AppState;
	filters: FiltersState;
}

const store = configureStore({
	reducer: {
		app: appReducer,
		filters: filtersReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(sideMenuMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;

export default store;
