import {
	AssignmentIndOutlined,
	AssignmentOutlined,
	AssignmentTurnedInOutlined,
	CampaignOutlined,
	InsertChartOutlined,
	MenuBookOutlined,
	PeopleOutline,
} from '@mui/icons-material';
import { NavLink } from '../types';

export const NAV_LINKS: NavLink[] = [
	{
		text: 'Пользователи',
		icon: <PeopleOutline />,
		path: '/users',
		roles: ['admin'],
	},
	{
		text: 'Баннеры',
		icon: <CampaignOutlined />,
		path: '/banners',
		roles: ['admin'],
	},
	{
		text: 'Программа',
		icon: <MenuBookOutlined />,
		hasNested: true,
		path: '/curriculum',
		roles: ['admin'],
		children: [
			{
				text: 'Программы',
				path: '/programs',
			},
			{
				text: 'Курсы',
				path: '/courses',
			},
			{
				text: 'Блоки',
				path: '/units',
			},
			{
				text: 'Уроки',
				path: '/lessons',
			},
		],
	},
	{
		text: 'NPS',
		icon: <InsertChartOutlined />,
		path: '/nps',
	},
	{
		text: 'Домашнее задание',
		icon: <AssignmentOutlined />,
		path: '/assignments',
	},
	{
		text: 'Самостоятельные работы',
		icon: <AssignmentIndOutlined />,
		path: '/selfassignments',
	},
	{
		text: 'Тесты',
		icon: <AssignmentTurnedInOutlined />,
		path: '/quizzes',
	},
];
