import {
	CancelOutlined,
	Check,
	CheckCircle,
	CheckCircleOutline,
	Close,
	DangerousOutlined,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Link,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import Loader from '../../components/Loader';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import SubmissionsService from '../../services/SubmissionsService';
import UsersService from '../../services/UsersService';
import {
	Entry,
	Quiz,
	QuizAnswer,
	QuizQuestion,
	QuizSubmission,
} from '../../types';
import formatDate from '../../utils/formatDate';

interface Props {
	submission?: QuizSubmission;
	quiz?: Quiz;
	isOpen: boolean;
	refetchSubmissions: () => any;
	onClose: () => any;
}

export default function CheckQuizSubmissionModal({
	submission,
	quiz,
	refetchSubmissions,
	isOpen,
	onClose,
}: Props) {
	const toast = useToast();

	const { data: user, isLoading: isLoadingUser } = useQuery(
		['getUser', submission?.user],
		() => UsersService.getUser(submission?.user ?? ''),
		{
			enabled: Boolean(submission?.user),
		}
	);

	const questions = useMemo<{ [key: string]: QuizQuestion } | undefined>(() => {
		if (!quiz || !quiz.entries || quiz.entries.length === 0) {
			return;
		}

		const res: { [key: string]: QuizQuestion } = {};

		quiz.entries.forEach((el) => (res[el.id] = el));
		return res;
	}, [quiz]);

	const answers = useMemo<{ [key: string]: QuizAnswer } | undefined>(() => {
		if (!quiz || !quiz.entries || quiz.entries.length === 0) {
			return;
		}

		const res: { [key: string]: QuizAnswer } = {};

		quiz.entries.forEach((el) =>
			el.answers?.forEach((ans) => 'id' in ans && (res[ans.id] = ans))
		);
		return res;
	}, [quiz]);

	const isLoading = isLoadingUser;

	const onDialogClose = () => {
		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			maxWidth="md"
			PaperProps={{
				sx: {
					width: '100%',
				},
			}}
			onClose={onDialogClose}
		>
			<DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs>
						Проверка работы
					</Grid>
					<Grid item xs="auto">
						<Tooltip title="Закрыть окно">
							<IconButton edge="end" onClick={onDialogClose}>
								<Close />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<Box py={4}>
						<Loader />
					</Box>
				) : (
					submission &&
					user &&
					quiz && (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md>
								<Stack spacing={0.5}>
									<Typography variant="subtitle2">Пользователь</Typography>
									<Typography variant="body2" textTransform="capitalize">
										{`ФИО: ${user.firstName} ${user.lastName}`}
									</Typography>
									{user.phoneNumber && (
										<Typography variant="body2" textTransform="capitalize">
											{`Телефон: ${user.phoneNumber}`}
										</Typography>
									)}
									<Typography variant="body2">
										{`Почта: ${user.email}`}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={4} md>
								<Stack spacing={0.5}>
									<Typography variant="subtitle2">Дата</Typography>
									<Typography variant="body2">
										{formatDate(submission.date, {
											hour: '2-digit',
											minute: '2-digit',
											second: '2-digit',
										})}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={2} md>
								<Stack spacing={0.5}>
									<Typography variant="subtitle2">Баллы</Typography>
									<Typography
										variant="body2"
										color={
											submission.score >= quiz.threshold
												? 'success.main'
												: 'error.main'
										}
									>{`${Math.round(submission.score)} / ${
										quiz.threshold
									}`}</Typography>
								</Stack>
							</Grid>

							{questions &&
								answers &&
								quiz.entries.map((question, i) => (
									<Grid item xs={12} key={`entry-${i}`}>
										<Stack spacing={0.5}>
											<Typography variant="subtitle2">{`${i + 1}. ${
												question.question.text
											}`}</Typography>

											{submission.entries[question.id] ? (
												<Stack spacing={1}>
													<Stack
														direction="row"
														spacing={0.5}
														color={
															answers[submission.entries[question.id]].isCorrect
																? 'success.main'
																: 'error.light'
														}
														alignItems="center"
													>
														{answers[submission.entries[question.id]]
															.isCorrect ? (
															<CheckCircleOutline fontSize="small" />
														) : (
															<DangerousOutlined fontSize="small" />
														)}
														<Typography variant="body2" whiteSpace="pre-line">
															{answers[submission.entries[question.id]].text}
														</Typography>
													</Stack>

													{!answers[submission.entries[question.id]]
														.isCorrect && (
														<Typography
															variant="body2"
															color="text.secondary"
															fontSize="small"
														>
															{`Правильный ответ: ${question.answers
																?.filter((el) => el.isCorrect)
																.map((el) => el.text)
																.join(', ')}`}
														</Typography>
													)}
												</Stack>
											) : (
												<Typography variant="body2">-</Typography>
											)}
										</Stack>
									</Grid>
								))}
						</Grid>
					)
				)}
			</DialogContent>
			{/* <DialogActions>
				<Grid container spacing={2}>
					<Grid item xs={12} sm="auto" order={{ xs: 2, sm: 1 }}>
						<Button
							fullWidth
							color="error"
							variant="contained"
							endIcon={<Close />}
							onClick={onValidate(false)}
							disabled={isLoading || validateSubmissionMutation.isLoading}
						>
							Отклонить
						</Button>
					</Grid>

					<Grid item ml="auto" xs={12} sm="auto" order={{ xs: 1, sm: 2 }}>
						<Button
							fullWidth
							color="success"
							variant="contained"
							endIcon={<Check />}
							onClick={onValidate(true)}
							disabled={isLoading || validateSubmissionMutation.isLoading}
						>
							Принять
						</Button>
					</Grid>
				</Grid>
			</DialogActions> */}
		</Dialog>
	);
}
