import { HighlightOff } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	FormControlProps,
	FormHelperText,
	Input,
	InputProps,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import {
	Control,
	Controller,
	ControllerFieldState,
	ControllerProps,
	ControllerRenderProps,
	UseFormStateReturn,
} from 'react-hook-form';
import { IMAGE_UPLOAD_TYPES } from '../constants';
import useFileDragAndDrop from '../hooks/useFileDragAndDrop';
import useFileObjectUrl from '../hooks/useFileObjectUrl';
import useFileUpload from '../hooks/useFileUpload';
import ImageCropModal from './modals/ImageCropModal';

const FieldContainer = styled(FormControl, {
	shouldForwardProp: (prop: string) =>
		!['ratio', 'isDraggingOver', 'previewUrl', 'isDisabled'].includes(prop),
})<{
	ratio?: number;
	isDraggingOver: boolean;
	previewUrl?: string;
	isDisabled?: boolean;
}>(({ theme, ratio = 1, isDraggingOver, previewUrl, error, isDisabled }) => ({
	position: 'relative',
	width: '100%',

	'.MuiInput-root': {
		display: 'none',
	},

	'.field-label': {
		minHeight: theme.spacing(4),
		maxHeight: theme.spacing(4),
	},

	label: {
		'.image-preview': {
			cursor: isDisabled ? 'not-allowed' : 'pointer',
			backgroundColor: '#ffffff',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			borderRadius: theme.shape.borderRadius,
			paddingBottom: `${(1 / ratio) * 100}%`,
			position: 'relative',

			...(previewUrl && {
				backgroundImage: `url(${previewUrl})`,
			}),

			'.borders, .content': {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				transition: theme.transitions.create('all'),

				'&.borders': {
					opacity: 0.1,
					border: `dashed 2px ${
						error
							? theme.palette.error.light
							: theme.palette.action.disabledBackground
					}`,
					borderImageSource: `url("/images/dashed-image${
						error ? '-error' : ''
					}.png")`,
					borderImageSlice: 2,
					borderImageRepeat: 'round',
				},

				'&.content': {
					opacity: 0.3,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',

					'.MuiTypography-root': {
						maxWidth: 200,
						color: error ? theme.palette.error.main : 'inherit',
					},
				},

				...((isDraggingOver || error) &&
					!isDisabled && {
						opacity: `0.8 !important`,
					}),
			},

			...(!isDisabled && {
				'&:hover': {
					'.borders, .content': {
						opacity: 0.8,
					},
				},
			}),

			...(isDisabled && {
				opacity: 0.5,
			}),
		},
	},
}));

interface FieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	ratio?: number;
	label?: string;
	inputProps?: InputProps;
	formControlProps?: FormControlProps;
	defaultFile?: File;
}

/* 
    An image upload field component. 
    Accepts ratio param which controls the aspect ratio of the image preview window. Example: 1 / 1, 3 / 4, 16 / 9, etc.
*/

function InnerFieldComponent({
	parentProps: props,
	controllerRenderProps: {
		field: { onChange, ...field },
		fieldState,
	},
}: {
	parentProps: FieldProps;
	controllerRenderProps: {
		field: ControllerRenderProps<any, string>;
		fieldState: ControllerFieldState;
		formState: UseFormStateReturn<any>;
	};
}) {
	const [file, setFile] = useState<File | undefined>();
	const [croppedFile, setCroppedFile] = useState<File | string | undefined>(
		field.value
	);

	const isDisabled = props.formControlProps?.disabled;

	const onUpload = (file?: File) => {
		setFile(file);
	};

	const previewUrl = useFileObjectUrl(croppedFile);
	const onFileChange = useFileUpload(onUpload, IMAGE_UPLOAD_TYPES);

	const { isDraggingOver, callbacks } = useFileDragAndDrop(
		onUpload,
		IMAGE_UPLOAD_TYPES
	);

	const onCropSave = (onChange: (event: any) => void) => (file: File) => {
		setCroppedFile(file);
		onChange(file);
		setFile(undefined);
	};

	const onCropClose = () => {
		setFile(undefined);
	};

	const onCroppedFileRemove = (onChange: (event: any) => void) => () => {
		if (isDisabled) {
			return;
		}

		onChange(undefined);
		setCroppedFile(undefined);
	};

	const id = props.inputProps?.id ?? props.name;

	useEffect(() => {
		setCroppedFile(field.value);
	}, [field.value]);

	return (
		<FieldContainer
			previewUrl={previewUrl}
			isDraggingOver={isDraggingOver}
			ratio={props.ratio}
			// ref={ref}
			{...props.formControlProps}
			error={Boolean(fieldState.error)}
			isDisabled={isDisabled}
		>
			<ImageCropModal
				file={file}
				onSave={onCropSave(onChange)}
				onClose={onCropClose}
				ratio={props.ratio}
			/>
			<Stack
				direction="row"
				justifyContent="space-between"
				className="field-label"
			>
				<Typography
					fontWeight={600}
					color={isDisabled ? 'text.disabled' : 'inherit'}
				>
					{props.label}
				</Typography>

				{croppedFile && (
					<Button
						size="small"
						endIcon={<HighlightOff />}
						color="inherit"
						onClick={onCroppedFileRemove(onChange)}
						disabled={isDisabled}
					>
						Удалить
					</Button>
				)}
			</Stack>
			<label htmlFor={id}>
				<Box className="image-preview" {...callbacks}>
					<Box className="borders"></Box>
					<Box className="content">
						{!croppedFile && (
							<Typography variant="body2" color="text.primary">
								{isDraggingOver
									? 'Бросьте файл сюда'
									: 'Перетащите или нажмите здесь, чтобы выбрать файл'}
							</Typography>
						)}
					</Box>
				</Box>
			</label>
			<Input
				type="file"
				{...props.inputProps}
				id={id}
				onChange={onFileChange}
			/>
			{Boolean(fieldState.error) && (
				<FormHelperText sx={{ mt: 0.5 }}>
					{fieldState.error?.message}
				</FormHelperText>
			)}
		</FieldContainer>
	);
}

const ImageUploadField = forwardRef<HTMLDivElement, FieldProps>(
	(props, ref) => {
		return (
			<Controller
				{...props}
				render={(params) => (
					<InnerFieldComponent
						parentProps={props}
						controllerRenderProps={params}
					/>
				)}
			/>
		);
	}
);

export default ImageUploadField;
