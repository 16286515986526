import { Paper, Stack, styled, Typography } from '@mui/material';
import DashboardPageContainer from '../components/DashboardPageContainer';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({
	textAlign: 'center',
	justifyContent: 'center',

	'.MuiPaper-root': {
		maxWidth: 600,
		width: '100%',
		margin: 'auto',
		padding: theme.spacing(2),
	},
}));

export default function HomePage() {
	useSetHeaderTitle('Управление');

	return (
		<PageContainer>
			<Paper>
				<Typography variant="h5" mb={1}>
					Добро пожаловать в панель управления!
				</Typography>
				<Typography color="text.secondary">
					Для продолжения воспольуйтесь панелью навигации
				</Typography>
			</Paper>
		</PageContainer>
	);
}
