import { Box, styled } from '@mui/material';

const AbsoluteCoverContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export default AbsoluteCoverContainer;
