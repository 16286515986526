import { Box, styled } from '@mui/material';

const DashboardFullPageContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100%',
	flex: '1 1 auto',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export default DashboardFullPageContainer;
