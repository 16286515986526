import {
	Checkbox,
	CheckboxProps,
	FormControlLabel,
	FormControlLabelProps,
} from '@mui/material';
import { Control, Controller, ControllerProps } from 'react-hook-form';

interface FieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	ratio?: number;
	label?: string;
	checkboxProps?: CheckboxProps;
	formControlLabelProps?: Partial<FormControlLabelProps>;
}

export default function CheckboxField({
	control,
	label,
	formControlLabelProps,
	checkboxProps,
	...props
}: FieldProps) {
	return (
		<Controller
			control={control}
			render={({ field: { value, onChange, ...field } }) => (
				<FormControlLabel
					checked={value ?? false}
					control={
						<Checkbox
							checked={value}
							onChange={(event, checked) => {
								onChange(checked);
							}}
							{...checkboxProps}
						/>
					}
					label={label}
					{...field}
					{...formControlLabelProps}
					sx={{
						...formControlLabelProps?.sx,
						mr: label && label.length > 0 ? 1 : 0,
					}}
				/>
			)}
			{...props}
		/>
	);
}
