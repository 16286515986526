import { GridSelectionModel } from '@mui/x-data-grid';
import { createContext, ReactNode, useState } from 'react';
import { useQuery } from 'react-query';
import UsersService from '../services/UsersService';
import { useAppSelector } from '../store';
import { User } from '../types';

interface ContextProps {
  users: User[];
  isLoadingUsers: boolean;
  selection: {
    setModel: (users: GridSelectionModel) => void;
    model: GridSelectionModel;
  };
  pagination: {
    setPage: (page: number) => void;
    setPageSize: (size: number) => void;
    page: number;
    pageSize: number;
    rowCount: number;
  };
  refetch: () => any;
}

const initialState: ContextProps = {
  users: [],
  isLoadingUsers: true,
  selection: {
    model: [],
    setModel: () => null,
  },
  pagination: {
    page: 0,
    setPage: () => null,
    pageSize: 8,
    setPageSize: () => null,
    rowCount: 8,
  },
  refetch: () => null,
};

export const UsersPageContext = createContext<ContextProps>(initialState);

export function UsersPageContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(10);
  const program = useAppSelector((state) => state.filters.program);
  const course = useAppSelector((state) => state.filters.course);
  const additionalFilters = useAppSelector(
    (state) => state.filters.additionalFilters
  );

  const {
    data,
    isLoading: isLoadingUsers,
    refetch,
  } = useQuery<{ count: number; users: User[] }>(
    [
      'users',
      page,
      pageSize,
      // additionalFilters?.userIsActive,
      // additionalFilters?.userRole,
      additionalFilters?.query,
      program.id,
      course.id,
    ],
    () =>
      UsersService.getUsers(page, pageSize, {
        // isActive: additionalFilters?.userIsActive,
        // role: additionalFilters?.userRole,
        query: additionalFilters?.query,
        program: program.id,
        course: course.id,
      }),
    {
      onSuccess: (data) => {
        setRowCount(data.count);
        // setRowCount(data * pageSize);
      },
    }
  );
  const [selectedUsers, setSelectedUsers] = useState<GridSelectionModel>([]);

  const contextValue: ContextProps = {
    users: data?.users ?? [],
    isLoadingUsers,
    selection: {
      model: selectedUsers,
      setModel: setSelectedUsers,
    },
    pagination: {
      page,
      setPage,
      pageSize,
      setPageSize,
      rowCount,
    },
    refetch,
  };

  return (
    <UsersPageContext.Provider value={contextValue}>
      {children}
    </UsersPageContext.Provider>
  );
}
