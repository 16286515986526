import { DataGrid, DataGridProps, GridSelectionModel } from '@mui/x-data-grid';
import React, { forwardRef, useState } from 'react';
import useContextMenu, { ContextMenuAction } from '../../hooks/useContextMenu';
import CustomPagination from '../CustomPagination';
import SelectionStateRow, { SelectionAction } from '../SelectionStateRow';

interface Props extends DataGridProps {
	selection?: {
		model: GridSelectionModel;
		setModel: (users: GridSelectionModel) => void;
		actions: SelectionAction[];
	};
	contextMenuItems: ContextMenuAction[];
}

/* 
	A custom data grid component with selection functionality and context menu 
*/

const CustomDataGrid = forwardRef<HTMLDivElement, Props>(
	({ selection, contextMenuItems, ...props }, ref) => {
		const [contextMenuItemId, setContextMenuItemId] = useState<any>();
		const { menu, setAnchorPosition } = useContextMenu(
			contextMenuItems,
			contextMenuItemId
		);

		const onRowContextClick = (event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();

			setAnchorPosition({ left: event.clientX, top: event.clientY });

			const id = event.currentTarget.getAttribute('data-id');

			setContextMenuItemId(id);
		};

		return (
			<>
				{menu}
				{/* {selection && (
					<SelectionStateRow
						selection={selection.model as Array<any>}
						actions={selection.actions}
						clearSelection={() => selection.setModel([])}
					/>
				)} */}

				<DataGrid
					ref={ref}
					{...props}
					density="comfortable"
					rowsPerPageOptions={[5, 10, 30, 50, 100]}
					disableColumnFilter
					disableColumnMenu
					disableColumnSelector
					{...(selection !== undefined && {
						onSelectionModelChange: (newSelectionModel) =>
							selection.setModel(newSelectionModel),
						selectionModel: selection.model,
					})}
					sx={
						props.sx ??
						((theme) => ({
							minHeight: 450,
							flexGrow: 1,
							[theme.breakpoints.down('sm')]: {
								minHeight: 550,
								'.MuiDataGrid-footerContainer': {
									justifyContent: 'center',
								},
							},
						}))
					}
					components={{
						Pagination: CustomPagination,
						...props.components,
					}}
					componentsProps={{
						row: {
							onContextMenu: onRowContextClick,
							style: { cursor: 'context-menu' },
						},
						...props.componentsProps,
					}}
					keepNonExistentRowsSelected
					hideFooterSelectedRowCount
				/>
			</>
		);
	}
);

export default CustomDataGrid;
