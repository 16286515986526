import { LoadingButton } from '@mui/lab';
import { Button, Grid, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import CheckboxField from '../../components/CheckboxField';
import DatePickerField from '../../components/DatePickerField';
import FileUploadField from '../../components/FileUploadField';
import ImageUploadField from '../../components/ImageUploadField';
import TextField from '../../components/material/TextField';
import RouterLink from '../../components/RouterLink';
import Select from '../../components/SelectField';
import {
  COURSE_TYPES,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import CoursesService from '../../services/CoursesService';
import { PostCourse } from '../../types';
import getTextFieldErrorProps from '../../utils/getTextFieldErrorProps';

interface Props {
  isUpdate?: boolean;
  defaultValues?: Partial<PostCourse>;
}

export default function CourseForm({ isUpdate = false, defaultValues }: Props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<PostCourse>({ defaultValues });
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const watchCommon = watch('common');

  const saveCourseMutation = useMutation(
    isUpdate ? 'updateCourse' : 'createCourse',
    (data: PostCourse) =>
      isUpdate
        ? CoursesService.updateCourse(id ?? '', data)
        : CoursesService.createCourse(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        navigate('/curriculum/courses');
      },
    }
  );

  const { isLoading } = saveCourseMutation;

  const onSubmit = ({ type, ...data }: PostCourse) => {
    if (isLoading) {
      return;
    }

    saveCourseMutation.mutate({
      ...data,
      type: type ?? COURSE_TYPES[2],
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <Grid
      container
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item container spacing={2}>
        <Grid item container rowSpacing={2} columnSpacing={4} xs={12}>
          <Grid
            item
            xs={12}
            sm={6}
            md="auto"
            minWidth={{ xs: 'initial', sm: 300, md: 350 }}
          >
            <ImageUploadField
              control={control}
              name="coverImage"
              label="Фото"
              ratio={16 / 9}
              formControlProps={{ disabled: isLoading }}
            />
          </Grid>
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md
            minWidth={{ xs: 0, md: 500 }}
            height="fit-content"
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Название"
                separateLabel
                inputProps={{
                  ...register('title', { required: 'Введите название' }),
                }}
                {...getTextFieldErrorProps(errors.title)}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Описание"
                separateLabel
                multiline
                minRows={3}
                maxRows={3}
                inputProps={{
                  ...register('description'),
                }}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container rowSpacing={2} columnSpacing={4} xs={12}>
          <Grid item xs={12} sm={6} lg={3}>
            <DatePickerField
              control={control}
              name="startDate"
              label="Дата старта"
              datePickerProps={{
                disabled: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DatePickerField
              control={control}
              name="endDate"
              label="Дата завершения"
              datePickerProps={{
                disabled: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} alignItems="flex-end" display="flex">
            <CheckboxField
              control={control}
              name="common"
              label="Общий курс"
              formControlLabelProps={{ disabled: isLoading }}
            />
          </Grid>
        </Grid>
        <Grid item container rowSpacing={2} columnSpacing={4} xs={12}>
          {!watchCommon && (
            <Grid item xs={12} sm={6} lg={3}>
              <Select
                name="type"
                control={control}
                rules={{ required: 'Выберите тип' }}
                label="Тип курса"
                formControlProps={{ fullWidth: true, disabled: isLoading }}
              >
                {COURSE_TYPES.map((el) => (
                  <MenuItem value={el} key={`course-${el}`}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={12} sm={6} lg={3}>
            <FileUploadField
              formControlProps={{ fullWidth: true, disabled: isLoading }}
              control={control}
              name="certificate"
              label="Сертификат"
              allowedExtensions={['application/pdf']}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item ml="auto">
          <Button
            fullWidth
            color="inherit"
            component={RouterLink}
            to="/curriculum/courses"
            disabled={isLoading}
          >
            Отменить
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            loading={isLoading}
          >
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
