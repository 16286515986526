import { ChangeEventHandler, useContext } from 'react';
import { ToastContext } from '../contexts/ToastContext';

export default function useFileUpload(
	onUpload: (file?: File) => void,
	fileTypes: Array<string>,
	disabled?: boolean
): ChangeEventHandler {
	const { toast } = useContext(ToastContext);

	const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		const files = event.target.files;

		if (!files || files.length === 0 || disabled) {
			return;
		}

		const newFile = files.item(0);

		if (!newFile) {
			return;
		}

		if (!fileTypes.includes(newFile.type)) {
			onUpload(undefined);
			event.target.value = '';
			toast(
				`Неподдерживаемый формат файла. Доступные форматы: ${fileTypes
					.join(', ')
					.replace(/image\//g, '.')}`,
				'error'
			);
			return;
		}

		onUpload(newFile);
		event.target.value = '';
	};

	return onChange;
}
