import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import Loader from '../../components/Loader';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import useFileFromURL from '../../hooks/useFileFromURL';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import CoursesService from '../../services/CoursesService';
import ProgramsService from '../../services/ProgramsService';
import { PostProgram } from '../../types';
import parseDateString from '../../utils/parseDateString';
import ProgramForm from '../../views/forms/ProgramForm';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function EditProgramPage() {
	const { id } = useParams();

	const isUpdate = id !== undefined;

	const { data: program, isLoading } = useQuery(
		['program', id],
		() => ProgramsService.getProgram(id ?? ''),
		{ enabled: isUpdate }
	);

	const { data: courses, isLoading: isLoadingCourses } = useQuery(
		['courses', id],
		() => CoursesService.getCoursesByProgram(id ?? '')
	);

	useSetHeaderTitle(
		isUpdate ? 'Редактирование программы' : 'Новая программа',
		undefined,
		'/curriculum/programs'
	);

	const formValues = useMemo<Partial<PostProgram> | undefined>(() => {
		if (!isUpdate || !program) {
			return;
		}

		return {
			title: program.title,
			subtitle: program.subtitle,
			description: program.description,
			startDate: new Date(program.startDate),
			endDate: new Date(program.endDate),
			coverImage: program.coverImage,
			courses: courses ?? [],
		} as Partial<PostProgram>;
	}, [isUpdate, program, courses]);

	return (
		<PageContainer>
			{isUpdate && isLoading ? (
				<Loader />
			) : (
				<ProgramForm isUpdate={isUpdate} defaultValues={formValues} />
			)}
		</PageContainer>
	);
}

export default withWhitelistRoles(EditProgramPage, ['admin']);
