import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import {
	resetAdditionalFilters,
	setCourseById,
	setLessonById,
	setProgramById,
	setUnitById,
} from '../store/filtersSlice';
import objectToSearchParams from '../utils/objectToSearchParams';

export default function useFiltersSearchParamsControl() {
	const [query] = useSearchParams();
	const { pathname } = useLocation();
	const { program, course, unit, lesson } = useAppSelector(
		(state) => state.filters
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		let res: {
			program?: string;
			course?: string;
			unit?: string;
			lesson?: string;
		} = {};

		if (program.id) {
			res.program = program.id;
		}

		if (course.id) {
			res.course = course.id;
		}

		if (unit.id) {
			res.unit = unit.id;
		}

		if (lesson.id) {
			res.lesson = lesson.id;
		}

		navigate(
			{
				search: objectToSearchParams(res),
			},
			{ replace: true }
		);
	}, [program, course, unit, lesson]);

	useLayoutEffect(() => {
		const queryProgram = query.get('program');
		const queryCourse = query.get('course');
		const queryUnit = query.get('unit');
		const queryLesson = query.get('lesson');

		if (queryProgram) {
			dispatch(setProgramById(queryProgram));
		}

		if (queryCourse) {
			dispatch(setCourseById(queryCourse));
		}

		if (queryUnit) {
			dispatch(setUnitById(queryUnit));
		}

		if (queryLesson) {
			dispatch(setLessonById(queryLesson));
		}
	}, []);
}
