import {
  Delete,
  DeleteOutlined,
  EditOutlined,
  PeopleOutlined,
  ViewDayOutlined,
} from '@mui/icons-material';
import { IconButton, Paper, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CurriculumFilters from '../../components/CurriculumFilters';
import CustomDataGrid from '../../components/CustomDataGrid';
import DashboardFullPageContainer from '../../components/DashboardFullPageContainer';
import Loader from '../../components/Loader';
import Typography from '../../components/material/Typography';
import { CoursesPageContext } from '../../contexts/CoursesPageContext';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import { ContextMenuAction } from '../../hooks/useContextMenu';
import useNavigateWithFilters from '../../hooks/useNavigateWithFilters';
import { useAppSelector } from '../../store';
import { Course } from '../../types';
import formatDate from '../../utils/formatDate';

export default function CoursesGrid() {
  const { courses, isLoadingCourses, selection, deleteCourse } =
    useContext(CoursesPageContext);
  const navigateWithFilters = useNavigateWithFilters();
  const navigate = useNavigate();
  const withConfirm = useConfirmDialog();

  const { program } = useAppSelector((state) => state.filters);

  const actions: ContextMenuAction[] = [
    {
      label: 'Блоки',
      icon: <ViewDayOutlined />,
      onClick: (courseId) => navigateWithFilters('units', courseId),
    },
    {
      label: 'Пользователи',
      icon: <PeopleOutlined />,
      onClick: (courseId) =>
        navigateWithFilters('users', courseId, { courseId }),
    },
    {
      label: 'Редактировать',
      icon: <EditOutlined />,
      onClick: (courseId) => navigate(courseId),
    },
    {
      label: 'Удалить',
      icon: <DeleteOutlined />,
      onClick: (courseId) =>
        withConfirm({
          title: 'Удалить курс?',
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          onConfirm: () => deleteCourse(courseId),
        }),
    },
  ];

  const columns: GridColumns<Course> = [
    {
      field: 'title',
      headerName: 'Название',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'common',
      headerName: 'Общий',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (params.value ? 'Да' : 'Нет'),
    },
    {
      field: 'type',
      headerName: 'Тип',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Старт',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'endDate',
      headerName: 'Конец',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: '',
      width: 210,
      renderCell: (params) =>
        actions.map((el) => (
          <Tooltip title={el.label} key={`courses-action-${el.label}`}>
            <IconButton onClick={() => el.onClick(params.id.toString())}>
              {el.icon}
            </IconButton>
          </Tooltip>
        )),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  if (program.isLoading) {
    return <Loader />;
  }

  if (!program.value) {
    return (
      <DashboardFullPageContainer>
        <Paper sx={{ p: 2 }}>
          <Typography variant="body1" mb={1}>
            Пожалуйста, выберите программу:
          </Typography>
          <CurriculumFilters program />
        </Paper>
      </DashboardFullPageContainer>
    );
  }

  return (
    <CustomDataGrid
      contextMenuItems={actions}
      selection={{
        model: selection.model,
        setModel: selection.setModel,
        actions: [
          {
            text: 'Удалить выбранные курсы',
            onClick: (items) => {},
            icon: <Delete color="error" />,
            color: 'error',
          },
        ],
      }}
      getRowId={(row: Course) => row.id ?? row._id ?? ''}
      paginationMode="client"
      columns={columns}
      rows={courses}
      loading={isLoadingCourses}
      // checkboxSelection
      pageSize={10}
    />
  );
}
