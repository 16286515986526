import axios from 'axios';
import { SERVICE_URLS } from '../constants';
import { PostUnit, Unit } from '../types/units';
import sortByPosition from '../utils/sortByPosition';

interface UnitsServiceTypes {
  getUnitsByCourse: (
    courseId?: string,
    options?: { isActive?: boolean }
  ) => Promise<Unit[]>;
  updateUnitsOrder: (ids: string[]) => Promise<any>;
  getUnit: (id: string) => Promise<Unit>;
  createUnit: (data: PostUnit) => Promise<Unit>;
  updateUnit: (id: string, data: PostUnit) => Promise<Unit>;
  deleteUnit: (id: string) => Promise<any>;
}

const UnitsService: UnitsServiceTypes = {
  async getUnitsByCourse(courseId, options) {
    const res = await axios.get<Unit[]>(
      `${SERVICE_URLS.CURRICULUM}/curriculum/unit`,
      {
        params: {
          ...(courseId && {
            group_type: 'COURSE',
            group_id: courseId,
          }),
          ...(options?.isActive !== undefined && {
            is_active: options.isActive,
          }),
        },
      }
    );

    sortByPosition(res.data);

    return res.data;
  },
  async getUnit(id) {
    const res = await axios.get<Unit>(
      `${SERVICE_URLS.CURRICULUM}/curriculum/unit/${id}`
    );

    return res.data;
  },
  async updateUnit(id, { course, program, ...data }) {
    const res = await axios.patch<Unit>(
      `${SERVICE_URLS.CURRICULUM}/curriculum/unit/${id}`,
      data
    );

    return res.data;
  },
  async updateUnitsOrder(ids) {
    await axios.post(`${SERVICE_URLS.CURRICULUM}/curriculum/unit/order`, {
      positions: ids,
    });
  },
  async createUnit({ course, program, ...data }) {
    const courseId = course?.id;

    const res = await axios.post<Unit>(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course/${courseId}/unit`,
      {
        ...data,
        startDate: data.startDate.toISOString(),
        endDate: data.endDate.toISOString(),
      }
    );

    return res.data;
  },
  async deleteUnit(id) {
    await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/unit/${id}`);
  },
};

export default UnitsService;
