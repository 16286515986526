import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import DashboardPageContainer from '../components/DashboardPageContainer';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import LessonsService from '../services/LessonsService';
import { useAppSelector } from '../store';
import AssignmentsGrid from '../views/AssignmentsPage/AssignmentsGrid';
import AssignmentsPageHeader from '../views/AssignmentsPage/AssignmentsPageHeader';
import SelfAssignmentsGrid from '../views/SelfAssignmentsPage/SelfAssignmentsGrid';
import SelfAssignmentsPageHeader from '../views/SelfAssignmentsPage/SelfAssignmentsPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

export default function SelfAssignmentsPage() {
	const { program, course, unit, lesson } = useAppSelector(
		(state) => state.filters
	);

	useSetHeaderTitle(
		'Самостоятельные работы',
		program.value &&
			course.value &&
			unit.value &&
			lesson.value &&
			`${program.value.title} - ${course.value.title} - ${unit.value.title} - ${lesson.value.title}`
	);

	const { data: lessonData, isLoading: isLoadingLessonData } = useQuery(
		['getLesson', lesson.id],
		() => LessonsService.getLesson(lesson.id ?? ''),
		{
			enabled: Boolean(lesson.id),
		}
	);

	const assignmentId = useMemo<string | undefined>(() => {
		if (
			!lessonData?.selfAssignments ||
			lessonData?.selfAssignments?.length === 0
		) {
			return;
		}

		return lessonData.selfAssignments[0].id;
	}, [lessonData]);

	return (
		<PageContainer>
			<SelfAssignmentsPageHeader assignmentId={assignmentId} />

			<SelfAssignmentsGrid
				assignmentId={assignmentId}
				isLoading={isLoadingLessonData}
			/>
		</PageContainer>
	);
}
