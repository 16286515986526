import axios, { AxiosResponse } from 'axios';
import { SERVICE_URLS } from '../constants';
import { PostProgram, Program } from '../types';
import fillFormData from '../utils/fillFormData';

interface ProgramsServiceTypes {
	getPrograms: (programName?: string) => Promise<Program[]>;
	getProgram: (id: string) => Promise<Program>;
	updateProgram: (id: string, data: PostProgram) => Promise<Program>;
	createProgram: (data: PostProgram) => Promise<Program>;
	deleteProgram: (id: string) => Promise<any>;
	sendCertificates: (id: string) => Promise<any>;
}

const ProgramsService: ProgramsServiceTypes = {
	async getPrograms(programName?: string) {
		const res: AxiosResponse<Program[]> = await axios.get(
			`${SERVICE_URLS.CURRICULUM}/curriculum/flow`
		);

		return res.data;
	},
	async getProgram(id) {
		const res = await axios.get<Program>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/flow/${id}`
		);

		return res.data;
	},
	async updateProgram(id, { courses, coverImage, ...data }) {
		const formData = new FormData();

		fillFormData(formData, {
			...data,
			...(courses && { courses: courses.map((el) => el.id) }),
			...(coverImage && typeof coverImage !== 'string' && { coverImage }),
		});

		const res = await axios.patch<Program>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/flow/${id}`,
			formData
		);

		return res.data;
	},
	async createProgram({ courses, ...data }) {
		const formData = new FormData();

		fillFormData(formData, data);

		if (courses) {
			courses.forEach((el, i) => {
				formData.append('courses[]', el.id);
			});
		}

		const res: AxiosResponse<Program> = await axios.post(
			`${SERVICE_URLS.CURRICULUM}/curriculum/flow`,
			formData
		);

		return res.data;
	},

	async deleteProgram(id) {
		await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/flow/${id}`);
	},
	async sendCertificates(id) {
		await axios.post(
			`${SERVICE_URLS.CURRICULUM}/curriculum/certificates/send`,
			{
				curriculumId: id,
			}
		);
	},
};

export default ProgramsService;
