import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	styled,
} from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import Cropper, { Point, Area } from 'react-easy-crop';
import useFileObjectUrl from '../../hooks/useFileObjectUrl';
import { getCroppedImage } from '../../utils/getCroppedImage';

const ModalContainer = styled(Dialog)(({ theme }) => ({
	'.MuiDialog-paper': {
		minWidth: 700,
		margin: theme.spacing(2),
	},

	'.MuiDialogContent-root': {
		position: 'relative',
		textAlign: 'center',
		padding: theme.spacing(1, 1),
		paddingBottom: '100%',
	},

	'.MuiDialogActions-root': {
		minHeight: 60,
		position: 'sticky',
		bottom: 0,
		background: '#fff',
	},

	img: {
		width: '100%',
	},

	[theme.breakpoints.down(700 + 32)]: {
		'.MuiDialog-paper': {
			minWidth: 'initial',
			width: '100%',
		},
	},
}));

interface Props {
	file?: File;
	onSave: (file: File) => void;
	onClose: () => void;
	ratio?: number;
}

const initialCrop: Point = {
	x: 0,
	y: 0,
};

export default function ImageCropModal({
	file,
	onSave,
	onClose,
	ratio = 1,
}: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [crop, setCrop] = useState<Point>(initialCrop);
	const [zoom, setZoom] = useState(1);
	const [completedCrop, setCompletedCrop] = useState<Area | undefined>();

	const fileUrl = useFileObjectUrl(file);

	const onSaveButtonClick: MouseEventHandler = async () => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);

		const img = new Image();
		img.src = fileUrl ?? '';

		img.onload = async ({ target }) => {
			if (!completedCrop) {
				return;
			}

			const croppedImg = await getCroppedImage(
				target as HTMLImageElement,
				completedCrop,
				'image'
			);

			setIsLoading(false);
			setCrop(initialCrop);
			setCompletedCrop(undefined);
			setZoom(1);
			onSave(croppedImg);
		};
	};

	const onComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
		setCompletedCrop(croppedAreaPixels);
	};

	return (
		<ModalContainer open={Boolean(file)} onClose={onClose}>
			<DialogTitle>Обрезка фото</DialogTitle>
			<DialogContent>
				<Cropper
					image={fileUrl}
					crop={crop}
					zoom={zoom}
					aspect={ratio}
					onCropChange={setCrop}
					onCropComplete={onComplete}
					onZoomChange={setZoom}
					cropShape="rect"
					objectFit="horizontal-cover"
					maxZoom={4}
					minZoom={1}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="error" onClick={onClose}>
					Отмена
				</Button>
				<Button onClick={onSaveButtonClick} variant="contained">
					Сохранить
				</Button>
			</DialogActions>
		</ModalContainer>
	);
}
