import { ArrowForwardIos } from '@mui/icons-material';
import {
	Button,
	ButtonProps,
	Fade,
	Menu,
	MenuProps,
	SxProps,
} from '@mui/material';
import { ReactNode, useRef, useState } from 'react';

interface CustomMenuProps extends Partial<MenuProps> {
	open?: boolean;
}

export interface ButtonDropdownProps {
	children: ReactNode;
	buttonProps?: ButtonProps;
	menuProps?: CustomMenuProps;
	menuItems: ReactNode;
}

export default function ButtonDropdown({
	children,
	menuItems,
	menuProps,
	buttonProps,
}: ButtonDropdownProps) {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLButtonElement | null>(null);

	const toggleMenu = () => {
		setIsOpen((prev) => !prev);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const sx: SxProps = {
		// ...(buttonProps !== undefined && buttonProps.sx),
		justifyContent: 'space-between',
		textAlign: 'left',
		minWidth: 150,
	};

	return (
		<>
			<Button
				onClick={toggleMenu}
				variant="outlined"
				color="inherit"
				{...buttonProps}
				ref={ref}
				sx={sx}
				endIcon={
					<ArrowForwardIos
						sx={(theme) => ({
							transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
							transition: theme.transitions.create('all', {
								duration: theme.transitions.duration.short,
							}),
							width: '0.75em',
						})}
					/>
				}
			>
				{children}
			</Button>
			<Menu
				anchorEl={ref.current}
				open={isOpen}
				onClose={onClose}
				MenuListProps={{
					dense: true,
					...menuProps?.MenuListProps,
				}}
				TransitionComponent={Fade}
				PaperProps={{ sx: { minWidth: 300 } }}
				{...menuProps}
				sx={{ px: 2, ...menuProps?.sx }}
			>
				{menuItems}
			</Menu>
		</>
	);
}
