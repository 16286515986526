import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../store';

export default function withWhitelistRoles(
	Component: React.ComponentType,
	whitelist: string[],
	fallbackPath?: string
) {
	return () => {
		const { claims } = useAppSelector((state) => state.app);

		if (
			claims &&
			claims.length > 0 &&
			!whitelist.includes(claims[0].name.toLowerCase())
		) {
			return <Navigate to={fallbackPath ?? '/'} />;
		}
		return <Component />;
	};
}
