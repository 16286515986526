import DashboardPageHeader from '../../components/DashboardPageHeader';

export default function BannersPageHeader() {
	return (
		<DashboardPageHeader
			newButtonText="Добавить баннер"
			textFieldProps={{ placeholder: 'Найти баннеры...' }}
		/>
	);
}
