import {Add} from '@mui/icons-material';
import {Button, Divider, Grid, Stack} from '@mui/material';
import React, {SetStateAction, useCallback, useEffect, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import CheckboxField from '../../../components/CheckboxField';
import {ATTACHMENT_TYPES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE} from '../../../constants';
import {Attachment, PostAttachment} from '../../../types';
import AttachmentItem from "./AttachmentItem";
import useConfirmDialog from "../../../hooks/useConfirmDialog";
import {useMutation} from "react-query";
import LessonsService from "../../../services/LessonsService";
import useToast from "../../../hooks/useToast";
import {useParams} from "react-router-dom";

type AttachmentFormValues = PostAttachment & {
	isActive: boolean;
};

interface AttachmentsFormValues {
	attachments: Array<Attachment | PostAttachment>;
}

interface Props {
	isDisabled: boolean;
	attachments: Attachment[];
	defaultValues?: Partial<AttachmentFormValues>;
}

export default function AttachmentForm({
	isDisabled,
	attachments,
	defaultValues,
}: Props) {
	const [attachmentsList, setAttachmentsList] = useState(attachments);
	const isUpdate = attachmentsList.length > 0;
	const withConfirm = useConfirmDialog();
	const {control, watch, reset, setValue} = useForm<AttachmentFormValues>({
		defaultValues: {
			isActive: isUpdate,
		},
	});

	const attachmentsFormMethods = useForm<AttachmentsFormValues>({
		defaultValues: {
			attachments: attachmentsList ?? [],
		},
	});

	const updateAttachmentsList = useCallback((data: SetStateAction<Attachment[]>) => {
		return setAttachmentsList(data);
	}, [attachments])
	const {
		fields: materials,
		append,
		remove,
		update,
	} = useFieldArray({
		control: attachmentsFormMethods.control,
		name: 'attachments',
		keyName: 'key',
	});
	const watchIsActive = watch('isActive');
	const toast = useToast();
	const {id} = useParams();
	const deleteAllAttachmentsMutation = useMutation(
		'deleteAttachment',
		() => LessonsService.deleteAllAttachments(id ?? ''),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				setAttachmentsList([])
				setValue('isActive', false);
			},
		}
	);

	const onReset = () => {
		reset({
			...defaultValues,
			isActive: isUpdate,
			type: ATTACHMENT_TYPES.DEFAULT,
		});
	};

	const closeModal = () => {
		setValue('isActive', false);
	}

	const onDeleteAttachment = (index: number | undefined) => {
		remove(index);
	};

	const onUpdateAttachment = (index = 0, data: Attachment) => {
		update(index, data);
	};

	const onAddAttachment = () => {
		append({ id: '', title: '', type: '', file:'' });
	};

	const onCancel = () => {
		if (attachmentsList.length === 0) {
			setValue('isActive', false);
			return;
		}

		withConfirm({
			title: 'Удалить весь материал?',
			onConfirm() {
				deleteAllAttachmentsMutation.mutate();
			},
			confirmButtonText: 'Удалить',
			cancelButtonText: 'Отмена',
		});
	};

	useEffect(() => {
		onReset();
	}, [defaultValues]);

	useEffect(() => {
		if (!watchIsActive) {
			if (attachments) {
				setValue('isActive', true);
				onCancel();
				return;
			}

			reset();
		}
	}, [watchIsActive, isDisabled]);
	return (
		<Grid item xs={12} >
			<Divider sx={{mb: 1}}/>
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				sx={{
					mb: 1,
					position: 'relative',
					zIndex: 1,
				}}
				justifyContent="space-between"
			>
				<CheckboxField
					control={control}
					name="isActive"
					label="Дополнительный материал"
					formControlLabelProps={{
						disabled: isDisabled,
					}}
					/>
			</Stack>
			{watchIsActive && (
				<>
					{materials && materials.length > 0 ? materials.map((el: any, i) => (
						<AttachmentItem
							key={el.key}
							attachment={el}
							index={i}
							attachments={attachmentsList}
							setAttachmentsList={updateAttachmentsList}
							onDelete={onDeleteAttachment}
							onUpdate={onUpdateAttachment}
							closeModal={closeModal}
							isUpdate={attachmentsList.some((item: Attachment) => item.id === el?.id)}
						/>
					))
						: (
							<AttachmentItem
								attachments={attachmentsList}
								isUpdate={false}
								closeModal={closeModal}
								setAttachmentsList={updateAttachmentsList}
								onDelete={onDeleteAttachment}
								onUpdate={onUpdateAttachment}
							/>
						)
					}

					<Grid item container xs={12} spacing={1} display="flex">
						<Grid item xs="auto" ml="auto">
							<Button endIcon={<Add/>} onClick={onAddAttachment} >
								Добавить материал
							</Button>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
}
