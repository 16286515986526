import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { createContext, ReactNode, useState } from 'react';

interface DialogOptions {
	title: string;
	subtitle?: string;
	confirmButtonText: string;
	cancelButtonText: string;
	onConfirm: () => any;
	onClose?: () => any;
}

interface ConfirmDialogContext {
	withConfirm: (options: Partial<DialogOptions>) => void;
}

const initialState: ConfirmDialogContext = {
	withConfirm: () => null,
};

export const ConfirmDialogContext =
	createContext<ConfirmDialogContext>(initialState);

interface ConfirmDialogContextProviderProps {
	children: ReactNode;
}

const initialOptions: DialogOptions = {
	title: 'Подтвердить?',
	confirmButtonText: 'Подтвердить',
	cancelButtonText: 'Отмена',
	onConfirm: () => null,
};

/* 
    A context that allows to show confirm dialog.
  */
export function ConfirmDialogContextProvider({
	children,
}: ConfirmDialogContextProviderProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [dialogOptions, setDialogOptions] =
		useState<DialogOptions>(initialOptions);

	const contextValue: ConfirmDialogContext = {
		withConfirm(options) {
			setIsOpen(true);
			setDialogOptions({ ...initialOptions, ...options });
		},
	};

	const onClose = () => {
		setIsOpen(false);

		if (dialogOptions.onClose) {
			dialogOptions.onClose();
		}
	};

	const onConfirm = () => {
		setIsOpen(false);
		dialogOptions.onConfirm();
	};

	return (
		<ConfirmDialogContext.Provider value={contextValue}>
			<Dialog
				open={isOpen}
				onClose={onClose}
				PaperProps={{
					sx: {
						minWidth: 300,
						textAlign: 'center',
					},
				}}
			>
				<DialogTitle>{dialogOptions.title}</DialogTitle>
				<DialogContent sx={{ minHeight: 50 }}>
					<DialogContentText>{dialogOptions.subtitle}</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Button variant="outlined" color="inherit" onClick={onClose}>
						{dialogOptions.cancelButtonText}
					</Button>

					<Button variant="contained" onClick={onConfirm}>
						{dialogOptions.confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
			{children}
		</ConfirmDialogContext.Provider>
	);
}
