import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_HEADER_TITLE } from '../constants';
import TokenService from '../services/TokenService';
import { Profile, Claim } from '../types';
import jwt_decode from 'jwt-decode';

export interface AppState {
	profile?: Profile;
	isSidebarOpen: boolean;
	headerTitle: string;
	isSignedIn: boolean;
	headerSubtitle?: string;
	backButtonPath?: string;
	claims?: Claim[];
}

const initialState: AppState = {
	isSidebarOpen: false,
	headerTitle: DEFAULT_HEADER_TITLE,
	isSignedIn: TokenService.tokenExists(),
	profile: TokenService.tokenExists()
		? jwt_decode(TokenService.getToken() ?? '')
		: undefined,
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setIsSignedIn: (state, { payload }) => ({
			...state,
			isSignedIn: payload,
		}),
		setHeaderTitle: (state, { payload }) => ({
			...state,
			headerTitle: payload,
		}),
		setHeaderSubtitle: (state, { payload }) => ({
			...state,
			headerSubtitle: payload,
		}),
		setBackButtonPath: (state, { payload }) => ({
			...state,
			backButtonPath: payload,
		}),
		resetHeaderSubtitle: (state) => ({
			...state,
			headerSubtitle: undefined,
		}),
		resetHeaderTitle: (state) => ({
			...state,
			headerTitle: DEFAULT_HEADER_TITLE,
		}),
		resetBackButtonPath: (state) => ({
			...state,
			backButtonPath: undefined,
		}),
		toggleSidebar: (state) => ({
			...state,
			isSidebarOpen: !state.isSidebarOpen,
		}),
		openSidebar: (state) => ({
			...state,
			isSidebarOpen: true,
		}),
		closeSidebar: (state) => ({
			...state,
			isSidebarOpen: false,
		}),
		setProfile: (state, { payload }) => ({
			...state,
			profile: payload,
		}),
		setClaims: (state, { payload }) => ({
			...state,
			claims: payload,
		}),
	},
});

export const {
	setIsSignedIn,
	setHeaderTitle,
	setHeaderSubtitle,
	setBackButtonPath,
	resetHeaderTitle,
	resetHeaderSubtitle,
	resetBackButtonPath,
	toggleSidebar,
	openSidebar,
	closeSidebar,
	setProfile,
	setClaims,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
