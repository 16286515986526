import { Box, styled } from '@mui/material';
import DashboardPageContainer from '../components/DashboardPageContainer';
import withContext from '../components/withContext';
import withWhitelistRoles from '../components/withWhitelistRoles';
import { UsersPageContextProvider } from '../contexts/UsersPageContext';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import UsersGrid from '../views/UsersPage/UsersGrid';
import UsersPageHeader from '../views/UsersPage/UsersPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({
	'.grid-container': {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
	},
}));

function UsersPage() {
	useSetHeaderTitle('Пользователи');

	return (
		<PageContainer>
			<UsersPageHeader />

			<Box className="grid-container">
				<UsersGrid />
			</Box>
		</PageContainer>
	);
}

export default withWhitelistRoles(
	withContext(UsersPage, UsersPageContextProvider),
	['admin']
);
