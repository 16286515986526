import DashboardPageHeader from '../../components/DashboardPageHeader';
import { useAppDispatch, useAppSelector } from '../../store';
import { setAdditionalFilter } from '../../store/filtersSlice';

export default function UnitsPageHeader() {
	const additionalFilters = useAppSelector(
		(state) => state.filters.additionalFilters
	);
	const dispatch = useAppDispatch();

	return (
		<DashboardPageHeader
			newButtonText="Добавить блок"
			textFieldProps={{ placeholder: 'Найти блоки...' }}
			curriculumFiltersProps={{
				program: true,
				course: true,
			}}
			filters={[
				{
					title: 'Активность',
					onChange: (value: string) =>
						dispatch(setAdditionalFilter({ key: 'unitIsActive', value })),
					hasAllOption: true,
					value: additionalFilters?.unitIsActive,
					options: [
						{ label: 'Активен', value: true },
						{ label: 'Неактивен', value: false },
					],
				},
			]}
		/>
	);
}
