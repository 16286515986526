import MainContainer from "../MainContainer";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

const CourseInfoActivity = ({data: dataFromQuery}: any) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        scales: {
            x: {
                ticks: {
                    display: false,
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: true,
                text: 'Прогресс прохождения курса',
            },
        },
    };

    const labels = dataFromQuery.map((d: any) => d.courseTitle);

    const data = {
        labels,
        datasets: [
            {
                label: 'Процент прогресса',
                data: dataFromQuery.map((d: any) => d.progress),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: '#29A8FA',
            },
        ],
    };
    return (
        <MainContainer>
            {dataFromQuery.length ?
                <Bar height="300px" options={options} data={data} />
                :
                <span>Нет актуальных данных</span>
            }
        </MainContainer>
    )
}

export default CourseInfoActivity;