import { ChangeEvent, useRef } from 'react';

export default function useDebounce(
	onChange: (event: ChangeEvent) => any,
	timeout: number = 1000
) {
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const onInputChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => onChange(event), timeout);
	};

	return onInputChange;
}
