import {
  DeleteOutlined,
  EditOutlined,
  PeopleOutlined,
  ViewDayOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../components/CustomDataGrid';
import { DEFAULT_ERROR_MESSAGE } from '../../constants';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import { ContextMenuAction } from '../../hooks/useContextMenu';
import useNavigateWithFilters from '../../hooks/useNavigateWithFilters';
import useToast from '../../hooks/useToast';
import CoursesService from '../../services/CoursesService';
import { Course } from '../../types';
import formatDate from '../../utils/formatDate';

export default function CommmonCoursesGrid() {
  const toast = useToast();
  const navigateWithFilters = useNavigateWithFilters();
  const navigate = useNavigate();
  const withConfirm = useConfirmDialog();

  const {
    data: courses = [],
    isLoading: isLoadingCourses,
    refetch,
  } = useQuery<Course[]>(['courses', true], () =>
    CoursesService.getCommonCourses()
  );

  const deleteCourseMutation = useMutation(
    'deleteCourse',
    (id: string) => CoursesService.deleteCourse(id),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        refetch();
      },
    }
  );

  const deleteCourse = (id: string) => {
    if (deleteCourseMutation.isLoading) {
      return;
    }

    deleteCourseMutation.mutate(id);
  };

  const actions: ContextMenuAction[] = [
    {
      label: 'Блоки',
      icon: <ViewDayOutlined />,
      onClick: (courseId) => navigateWithFilters('units', courseId),
    },
    {
      label: 'Пользователи',
      icon: <PeopleOutlined />,
      onClick: (courseId) =>
        navigateWithFilters('users', courseId, { courseId }),
    },
    {
      label: 'Редактировать',
      icon: <EditOutlined />,
      onClick: (courseId) => navigate(courseId),
    },
    {
      label: 'Удалить',
      icon: <DeleteOutlined />,
      onClick: (courseId) =>
        withConfirm({
          title: 'Удалить курс?',
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          onConfirm: () => deleteCourse(courseId),
        }),
    },
  ];

  const columns: GridColumns<Course> = [
    {
      field: 'title',
      headerName: 'Название',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'common',
      headerName: 'Общий',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (params.value ? 'Да' : 'Нет'),
    },
    {
      field: 'startDate',
      headerName: 'Старт',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'endDate',
      headerName: 'Конец',
      minWidth: 180,
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: '',
      width: 210,
      renderCell: (params) =>
        actions.map((el) => (
          <Tooltip title={el.label} key={`courses-action-${el.label}`}>
            <IconButton onClick={() => el.onClick(params.id.toString())}>
              {el.icon}
            </IconButton>
          </Tooltip>
        )),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <CustomDataGrid
      contextMenuItems={actions}
      getRowId={(row: Course) => row.id ?? row._id ?? ''}
      paginationMode="client"
      columns={columns}
      rows={courses}
      loading={isLoadingCourses}
      // checkboxSelection
      pageSize={10}
    />
  );
}
