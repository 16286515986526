import { ArrowBackIosNew, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	createTheme,
	CSSObject,
	Drawer as MuiDrawer,
	Fab,
	Fade,
	PaperProps,
	styled,
	SwipeableDrawer as MuiSwipeableDrawer,
	Theme,
	ThemeOptions,
	ThemeProvider,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { SIDEBAR_WIDTH } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeSidebar, openSidebar, toggleSidebar } from '../../store/appSlice';
import SidebarContent from './SidebarContent';

const openedMixin = (theme: Theme): CSSObject => ({
	width: SIDEBAR_WIDTH,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop: string) => !['open'].includes(prop),
})(({ theme, open }) => ({
	width: SIDEBAR_WIDTH,

	[theme.breakpoints.up('md')]: {
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	},
}));

const SwipeableDrawer = styled(MuiSwipeableDrawer)(({ theme }) => ({
	zIndex: theme.zIndex.appBar - 10,
}));

export default function Sidebar() {
	const theme = useTheme();
	const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen);
	const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch = useAppDispatch();

	const onOpen = () => {
		dispatch(openSidebar());
	};

	const onClose = () => {
		if (matchesMdDown) {
			dispatch(closeSidebar());
		}
	};

	const onToggleButtonClick = () => {
		dispatch(toggleSidebar());
	};

	const paperProps: PaperProps = {
		elevation: 0,
		sx: (theme) => ({
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,
			width: SIDEBAR_WIDTH,
		}),
	};

	const isIos =
		typeof navigator !== 'undefined' &&
		/iPad|iPhone|iPod/.test(navigator.userAgent);

	return (
		<ThemeProvider
			theme={(theme: ThemeOptions) =>
				createTheme({
					...theme,
					palette: {
						...theme.palette,
						mode: 'dark',
						text: { primary: '#fff' },
						divider: '#3b3b3b',
					},
				})
			}
		>
			{matchesMdDown ? (
				<SwipeableDrawer
					open={isSidebarOpen}
					onClose={onClose}
					onOpen={onOpen}
					anchor="left"
					PaperProps={paperProps}
					disableBackdropTransition={!isIos}
					disableDiscovery={isIos}
					keepMounted
				>
					<Box sx={(theme) => ({ height: theme.spacing(5) })} />
					<SidebarContent onClose={onClose} />
				</SwipeableDrawer>
			) : (
				<Drawer
					open={isSidebarOpen}
					onClose={onClose}
					anchor="left"
					PaperProps={paperProps}
					variant="permanent"
				>
					<SidebarContent onClose={onClose} />
					{!matchesMdDown && (
						<Tooltip title={isSidebarOpen ? 'Скрыть меню' : 'Открыть меню'}>
							<Button
								color="inherit"
								aria-label="collapse"
								sx={(theme) => ({
									position: 'sticky',
									bottom: 0,
									left: 0,
									right: 0,
									transition: theme.transitions.create('all', {
										duration: theme.transitions.duration.standard,
									}),
									zIndex: theme.zIndex.appBar + 200,
									borderRadius: 0,
								})}
								fullWidth
								onClick={onToggleButtonClick}
							>
								<Collapse in={isSidebarOpen} orientation="horizontal">
									<span>Скрыть меню</span>
								</Collapse>

								<KeyboardDoubleArrowLeft
									sx={(theme) => ({
										transform: `rotate(${isSidebarOpen ? '0' : '180'}deg)`,
										transition: theme.transitions.create('all', {
											duration: theme.transitions.duration.short,
										}),
									})}
								/>
							</Button>
						</Tooltip>
					)}
				</Drawer>
			)}
		</ThemeProvider>
	);
}
