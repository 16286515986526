import { CancelOutlined, Check, CheckCircle, Close } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Link,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import Loader from '../../components/Loader';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import SubmissionsService from '../../services/SubmissionsService';
import UsersService from '../../services/UsersService';
import formatDate from '../../utils/formatDate';
import AssignmentStatusChip from './AssignmentStatusChip';

interface Props {
	submissionId?: string;
	assignmentId: string;
	isOpen: boolean;
	refetchSubmissions: () => any;
	onClose: () => any;
}

export default function CheckAssignmentSubmissionModal({
	submissionId,
	assignmentId,
	refetchSubmissions,
	isOpen,
	onClose,
}: Props) {
	const toast = useToast();
	const {
		data,
		isLoading: isLoadingData,
		refetch,
	} = useQuery(
		['getSubmission', submissionId],
		() => SubmissionsService.getAssignmentSubmission(submissionId ?? ''),
		{
			enabled: Boolean(submissionId),
		}
	);

	const { data: user, isLoading: isLoadingUser } = useQuery(
		['getUser', data?.user],
		() => UsersService.getUser(data?.user ?? ''),
		{
			enabled: Boolean(data?.user),
		}
	);

	const validateSubmissionMutation = useMutation(
		['validateSubmission'],
		({ accepted }: { accepted: boolean }) =>
			SubmissionsService.validateSubmission(
				assignmentId,
				data?.user ?? '',
				accepted
			),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				refetch();
				refetchSubmissions();
			},
		}
	);

	const isLoading = isLoadingData || isLoadingUser;

	const onDialogClose = () => {
		onClose();
	};

	const onValidate = (accepted: boolean) => () => {
		if (isLoading || validateSubmissionMutation.isLoading) {
			return;
		}

		validateSubmissionMutation.mutate({ accepted });
	};

	return (
		<Dialog
			open={isOpen}
			maxWidth="md"
			PaperProps={{
				sx: {
					width: '100%',
				},
			}}
			onClose={onDialogClose}
		>
			<DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs>
						Проверка работы
					</Grid>
					<Grid item xs="auto">
						<Tooltip title="Закрыть окно">
							<IconButton
								edge="end"
								onClick={onDialogClose}
								disabled={validateSubmissionMutation.isLoading}
							>
								<Close />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item xs={12}>
						<AssignmentStatusChip accepted={data?.accepted} size="small" />
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<Box py={4}>
						<Loader />
					</Box>
				) : (
					data &&
					user && (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md>
								<Stack spacing={0.5}>
									<Typography variant="subtitle2">Пользователь</Typography>
									<Typography variant="body2" textTransform="capitalize">
										{`ФИО: ${user.firstName} ${user.lastName}`}
									</Typography>
									{user.phoneNumber && (
										<Typography variant="body2" textTransform="capitalize">
											{`Телефон: ${user.phoneNumber}`}
										</Typography>
									)}
									<Typography variant="body2">
										{`Почта: ${user.email}`}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6} md>
								<Stack spacing={0.5}>
									<Typography variant="subtitle2">Дата</Typography>
									<Typography variant="body2">
										{formatDate(data.date, {
											hour: '2-digit',
											minute: '2-digit',
											second: '2-digit',
										})}
									</Typography>
								</Stack>
							</Grid>
							{typeof data.file === 'string' && (
								<Grid item xs={12} sm={6} md={2}>
									<Stack spacing={0.5}>
										<Typography variant="subtitle2">Файл</Typography>

										<Link href={data.file} target="_blank" rel="noreferrer">
											<Typography variant="body2">Ссылка</Typography>
										</Link>
									</Stack>
								</Grid>
							)}
							<Grid item xs={12}>
								<Stack spacing={0.5}>
									<Typography variant="subtitle2">Ответ</Typography>
									<Typography variant="body2" whiteSpace="pre-line">
										{data.answer}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					)
				)}
			</DialogContent>
			<DialogActions>
				<Grid container spacing={2}>
					<Grid item xs={12} sm="auto" order={{ xs: 2, sm: 1 }}>
						<Button
							fullWidth
							color="error"
							variant="contained"
							endIcon={<Close />}
							onClick={onValidate(false)}
							disabled={isLoading || validateSubmissionMutation.isLoading}
						>
							Отклонить
						</Button>
					</Grid>

					<Grid item ml="auto" xs={12} sm="auto" order={{ xs: 1, sm: 2 }}>
						<Button
							fullWidth
							color="success"
							variant="contained"
							endIcon={<Check />}
							onClick={onValidate(true)}
							disabled={isLoading || validateSubmissionMutation.isLoading}
						>
							Принять
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
