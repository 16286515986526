import Cookie from 'js-cookie';

const TokenService = {
	tokenExists() {
		return Boolean(Cookie.get('token'));
	},
	refreshTokenExists() {
		return Boolean(Cookie.get('refreshToken'));
	},
	getToken() {
		return Cookie.get('token');
	},
	getRefreshToken() {
		return Cookie.get('refreshToken');
	},
	setToken(token: string) {
		Cookie.set('token', token, {  path: '/' });
	},
	setRefreshToken(token: string) {
		Cookie.set('refreshToken', token, {  path: '/' });
	},
	removeToken() {
		Cookie.remove('token');
	},
};

export default TokenService;
