import { Action, AsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import {
	setCourseById,
	setCourseValue,
	setLessonById,
	setLessonValue,
	setProgramById,
	setUnitById,
	setUnitValue,
} from '../store/filtersSlice';
import objectToSearchParams from '../utils/objectToSearchParams';

/* 
	Returns a function to navigat and dispatch action to change filters state in one call
*/
export default function useNavigateWithFilters() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { program, course, unit, lesson } = useAppSelector(
		(state) => state.filters
	);

	const navigateWithFilters = (
		to: 'users' | 'courses' | 'units' | 'lessons' | 'assignments' | 'quizzes',
		id: string,
		options?: {
			programId?: string;
			courseId?: string;
			unitId?: string;
			lessonId?: string;
		}
	) => {
		let action: Action | AsyncThunk<any, any, any> | null = null;
		let pathname = '';
		let search: {
			program?: string;
			course?: string;
			unit?: string;
			lesson?: string;
		} = {};

		switch (to) {
			case 'users':
				pathname = `/users`;

				if (options?.programId) {
					const id = options?.programId ?? program.id ?? '';
					search.program = id;

					dispatch(setProgramById(id));
					dispatch(setCourseValue(undefined));
					dispatch(setUnitValue(undefined));
					dispatch(setLessonValue(undefined));
				}

				if (options?.courseId) {
					const id = options?.courseId ?? course.id ?? '';
					search.course = id;

					dispatch(setCourseById(id));
					dispatch(setUnitValue(undefined));
					dispatch(setLessonValue(undefined));
				}

				if (options?.unitId) {
					const id = options?.unitId ?? unit.id ?? '';
					search.unit = id;

					dispatch(setUnitById(id));
					dispatch(setLessonValue(undefined));
				}

				if (options?.lessonId) {
					const id = options?.lessonId ?? lesson.id ?? '';
					search.lesson = id;

					dispatch(setLessonById(id));
				}

				break;
			case 'courses':
				action = setProgramById;
				pathname = `/curriculum/courses`;
				search.program = id;
				break;
			case 'units':
				action = setCourseById;
				pathname = `/curriculum/units`;
				search = {
					program: program.id,
					course: id,
				};
				break;
			case 'lessons':
				action = setUnitById;
				pathname = `/curriculum/lessons`;
				search = {
					program: program.id,
					course: course.id,
					unit: id,
				};
				break;
			case 'assignments':
				action = setLessonById;
				pathname = `/assignments`;
				search = {
					lesson: lesson.id,
				};
				break;
			case 'quizzes':
				action = setLessonById;
				pathname = `/quizzes`;
				search = {
					lesson: lesson.id,
				};
				break;
		}

		if (action) {
			dispatch(action(id));
		}

		navigate({ pathname, search: objectToSearchParams(search) });
	};

	return navigateWithFilters;
}
