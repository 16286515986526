import { styled } from '@mui/material';
import DashboardPageContainer from '../components/DashboardPageContainer';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

export default function SettingsPage() {
	useSetHeaderTitle('Настройки');
	return <PageContainer></PageContainer>;
}
