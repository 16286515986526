import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useMutation, useQuery } from 'react-query';
import CurriculumFilters from '../../components/CurriculumFilters';
import DashboardFullPageContainer from '../../components/DashboardFullPageContainer';
import DraggableList from '../../components/DraggableList';
import Loader from '../../components/Loader';
import Typography from '../../components/material/Typography';
import { DEFAULT_ERROR_MESSAGE, ORDER_SUCCESS_MESSAGE } from '../../constants';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useToast from '../../hooks/useToast';
import LessonsService from '../../services/LessonsService';
import { useAppSelector } from '../../store';
import { Lesson } from '../../types';
import LessonsListRow from './LessonsListRow';

export default function LessonsList() {
  const toast = useToast();
  const withConfirm = useConfirmDialog();
  const { program, course, unit, additionalFilters } = useAppSelector(
    (state) => state.filters
  );
  const {
    data,
    isLoading: filtersIsLoading,
    refetch,
  } = useQuery(
    ['lessonsList', unit?.id, additionalFilters?.lessonIsActive],
    () =>
      LessonsService.getLessonsByUnit(unit?.id, {
        isActive: additionalFilters?.lessonIsActive,
      }),
    { enabled: Boolean(unit.value) }
  );

  const [lessons, setLessons] = useState<Lesson[]>([]);

  const saveLessonsOrderMutation = useMutation(
    'saveLessonsOrder',
    (ids: string[]) => LessonsService.updateLessonsOrder(ids),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        refetch();
        toast(ORDER_SUCCESS_MESSAGE, 'success');
      },
    }
  );

  const deleteLessonMutation = useMutation(
    'deleteLesson',
    (id: string) => LessonsService.deleteLesson(id),
    {
      onSuccess(data, variables, context) {
        refetch();
      },
    }
  );

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const element = lessons[source.index];
    const newArray = Array.from(lessons);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, element);

    setLessons([...newArray]);
  };

  const onDelete = (id: string) => {
    if (deleteLessonMutation.isLoading) {
      return;
    }

    withConfirm({
      title: 'Удалить урок?',
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отмена',
      onConfirm: () => deleteLessonMutation.mutate(id),
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setLessons(data);
  }, [data]);

  if (filtersIsLoading || course.isLoading || unit.isLoading) {
    return <Loader />;
  }

  if (!unit.value) {
    return (
      <DashboardFullPageContainer>
        <Paper sx={{ p: 2 }}>
          <Typography variant="body1" mb={1}>
            Пожалуйста, выберите программу, курс и блок:
          </Typography>
          <CurriculumFilters program course unit />
        </Paper>
      </DashboardFullPageContainer>
    );
  }

  return (
    <Box>
      <DraggableList
        droppableId="lessonsList"
        items={lessons}
        onDragEnd={onDragEnd}
        emptyMessage="Уроки не найдены"
        renderItem={(lesson, i, options) => (
          <LessonsListRow
            lesson={lesson}
            index={i}
            key={`lesson-${lesson.id}`}
            isDisabled={
              saveLessonsOrderMutation.isLoading ||
              deleteLessonMutation.isLoading
            }
            onDelete={onDelete}
            {...options}
          />
        )}
        onChangeSave={() =>
          saveLessonsOrderMutation.mutate(lessons.map((el) => el.id))
        }
        onChangeCancel={() => setLessons(data ?? [])}
      />
    </Box>
  );
}
