import { ArrowBackIosNew } from '@mui/icons-material';
import {
	Box,
	Fab,
	styled,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import withAuth from '../components/withAuth';
import { SIDEBAR_WIDTH } from '../constants';
import useFiltersSearchParamsControl from '../hooks/useFiltersSearchParamsControl';
import TokenService from '../services/TokenService';
import { useAppDispatch, useAppSelector } from '../store';
import {
	closeSidebar,
	openSidebar,
	setClaims,
	setProfile,
	toggleSidebar,
} from '../store/appSlice';
import jwt_decode from 'jwt-decode';
import { JWT } from '../types';

interface AdditionalProps {
	isSidebarOpen: boolean;
}

const LayoutContainer = styled(Box, {
	shouldForwardProp: (prop: string) => !['isSidebarOpen'].includes(prop),
})<AdditionalProps>(({ theme, isSidebarOpen }) => ({
	display: 'flex',
	minHeight: '100vh',
	alignItems: 'stretch',

	'.sidebar-dummy': {
		flexShrink: 0,
		transition: theme.transitions.create('all'),
		width: isSidebarOpen ? SIDEBAR_WIDTH : `calc(${theme.spacing(7)} + 1px)`,
	},

	'.sidebar-container': {
		position: 'fixed',
		flex: '0 1 auto',
		zIndex: theme.zIndex.appBar + 200,
	},

	'.page-container': {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		minHeight: '100vh',
		paddingTop: theme.spacing(14),
		paddingBottom: theme.spacing(8),

		'.page-body': {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2, 7),
		},
	},

	[theme.breakpoints.down('md')]: {
		'.sidebar-dummy': {
			display: 'none',
		},

		'.page-container': {
			paddingTop: theme.spacing(8),

			'.page-body': {
				padding: theme.spacing(2),
			},
		},
	},
}));

function MainLayout() {
	const theme = useTheme();
	const { isSidebarOpen } = useAppSelector((state) => state.app);
	const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const dispatch = useAppDispatch();

	useFiltersSearchParamsControl();

	useEffect(() => {
		const isSidebarOpenLocalStorage = localStorage.getItem('isSidebarOpen');

		if (!matchesMdDown) {
			if (isSidebarOpenLocalStorage !== 'false') {
				dispatch(openSidebar());
			}
		} else {
			dispatch(closeSidebar());
		}
	}, [matchesMdDown]);

	useEffect(() => {
		const token = TokenService.getToken();

		if (token) {
			const tokenDecoded: JWT = jwt_decode(token);
			dispatch(setProfile(tokenDecoded.profile));
			dispatch(setClaims(tokenDecoded.claims));
		} else {
			dispatch(setProfile(undefined));
			dispatch(setClaims(undefined));
		}
	}, []);

	return (
		<LayoutContainer isSidebarOpen={isSidebarOpen}>
			<div className="sidebar-dummy"></div>
			<div className="sidebar-container">
				<Sidebar />
			</div>
			<div className="page-container">
				<Header />
				<div className="page-body">
					<Outlet />
				</div>
			</div>
		</LayoutContainer>
	);
}

export default withAuth(MainLayout, true, false);
