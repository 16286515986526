import { Box } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import BannerCard from '../../components/BannerCard';
import { Banner } from '../../types';

interface Props {
	banner: Banner;
	index: number;
	onBannerImageClick: (src: string) => void;
	onDeleteButtonClick: () => any;
	isDisabled: boolean;
}

export default function BannersControlItem({
	banner,
	index,
	onBannerImageClick,
	onDeleteButtonClick,
	isDisabled,
}: Props) {
	return (
		<Draggable
			draggableId={banner.id}
			index={index}
			isDragDisabled={isDisabled}
		>
			{(provided, snapshot) => (
				<Box
					sx={{ minWidth: 200, width: 200 }}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
				>
					<BannerCard
						banner={banner}
						onImageClick={onBannerImageClick}
						onDeleteClick={onDeleteButtonClick}
						isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
						isDisabled={isDisabled}
					/>
				</Box>
			)}
		</Draggable>
	);
}
