import {
	FormControl,
	FormLabel,
	TextField as MuiTextField,
	TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { forwardRef, useState } from 'react';

export type TextFieldProps = MuiTextFieldProps & {
	separateLabel?: boolean;
};

const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
	({ separateLabel, ...props }, ref) => {
		const [shrink, setShrink] = useState(false);

		return separateLabel ? (
			<>
				<FormControl fullWidth={props.fullWidth} disabled={props.disabled}>
					<FormLabel
						htmlFor={props.id}
						sx={{ mb: 1, whiteSpace: 'nowrap' }}
						className="separate-label"
					>
						{props.label}
					</FormLabel>

					<MuiTextField
						{...props}
						label={undefined}
						variant={props.variant ?? 'outlined'}
						size={props.size ?? 'small'}
					/>
				</FormControl>
			</>
		) : (
			<MuiTextField
				onFocus={() => setShrink(true)}
				onBlur={(e) => {
					!e.target.value && setShrink(false);
				}}
				{...props}
				InputLabelProps={{
					shrink: shrink,
					...props.InputLabelProps,
				}}
				variant={props.variant ?? 'outlined'}
				size={props.size ?? 'small'}
				ref={ref}
			/>
		);
	}
);

export default TextField;
