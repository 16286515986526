import axios, { AxiosResponse } from 'axios';
import { SERVICE_URLS } from '../constants';
import { Course, PostCourse } from '../types';
import fillFormData from '../utils/fillFormData';

interface CoursesServiceTypes {
  getCoursesByProgram: (
    programId?: string,
    options?: {
      isActive?: boolean;
      courseType?: string;
      includeCommon?: boolean;
    }
  ) => Promise<Course[]>;
  getCommonCourses: () => Promise<Course[]>;
  getCourse: (courseId: string) => Promise<Course>;
  updateCourse: (courseId: string, data: PostCourse) => Promise<Course>;
  createCourse: (data: PostCourse) => Promise<Course>;
  deleteCourse: (id: string) => Promise<any>;
}

const CoursesService: CoursesServiceTypes = {
  async getCoursesByProgram(programId, options) {
    const res: AxiosResponse<Course[]> = await axios.get(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course`,
      {
        params: {
          ...(programId && {
            group_type: 'CURRICULUM',
            group_id: programId,
          }),
          ...(options?.isActive !== undefined && {
            is_active: options.isActive,
          }),

          ...(options?.courseType && {
            course_type: options.courseType,
          }),
          size: 150, // old = 100
        },
      }
    );

    if (!options?.includeCommon) {
      return res.data;
    }

    const resCommon: AxiosResponse<Course[]> = await axios.get(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course/commons`
    );

    return [...resCommon.data, ...res.data];
  },
  async getCommonCourses() {
    const res: AxiosResponse<Course[]> = await axios.get(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course/commons`
    );

    return res.data;
  },
  async getCourse(id) {
    const res = await axios.get<Course>(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course/${id}`
    );

    return res.data;
  },
  async updateCourse(id, { coverImage, certificate, ...data }) {
    const formData = new FormData();

    fillFormData(formData, {
      ...data,
      ...(certificate && typeof certificate !== 'string' && { certificate }),
      ...(coverImage && typeof coverImage !== 'string' && { coverImage }),
    });

    const res: AxiosResponse<Course> = await axios.patch(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course/${id}`,
      formData
    );
    return res.data;
  },
  async createCourse(data) {
    const formData = new FormData();

    fillFormData(formData, data);

    const res: AxiosResponse<Course> = await axios.post(
      `${SERVICE_URLS.CURRICULUM}/curriculum/course`,
      formData
    );
    return res.data;
  },
  async deleteCourse(id) {
    await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/course/${id}`);
  },
};

export default CoursesService;
