import DashboardPageHeader from '../../components/DashboardPageHeader';
import { useAppDispatch, useAppSelector } from '../../store';
import { setAdditionalFilter } from '../../store/filtersSlice';

export default function LessonsPageHeader() {
	const additionalFilters = useAppSelector(
		(state) => state.filters.additionalFilters
	);
	const dispatch = useAppDispatch();

	return (
		<DashboardPageHeader
			newButtonText="Добавить урок"
			textFieldProps={{ placeholder: 'Найти уроки...' }}
			curriculumFiltersProps={{ course: true, program: true, unit: true }}
			filters={[
				{
					title: 'Активность',
					onChange: (value: string) =>
						dispatch(setAdditionalFilter({ key: 'lessonIsActive', value })),
					hasAllOption: true,
					value: additionalFilters?.lessonIsActive,
					options: [
						{ label: 'Активен', value: true },
						{ label: 'Неактивен', value: false },
					],
				},
			]}
		/>
	);
}
