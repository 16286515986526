/* 
    Formats date string from format YYYYmmdd to format "dd month, YYYY"
*/
export default function formatDate(
	date: string,
	customOptions?: Intl.DateTimeFormatOptions
) {
	// const year = parseInt(date.substring(0, 4));
	// const month = parseInt(date.substring(4, 6));
	// const day = parseInt(date.substring(6));

	return new Date(date).toLocaleDateString('ru-RU', {
		year: 'numeric',
		month: 'short',
		day: '2-digit',

		...customOptions,
	});
}
