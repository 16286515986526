import { Delete, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Avatar, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../components/CustomDataGrid';
import { DEFAULT_ERROR_MESSAGE } from '../../constants';
import { UsersPageContext } from '../../contexts/UsersPageContext';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import { ContextMenuAction } from '../../hooks/useContextMenu';
import useToast from '../../hooks/useToast';
import UsersService from '../../services/UsersService';
import { User } from '../../types';
import formatDate from '../../utils/formatDate';

export default function UsersGrid() {
  const { users, isLoadingUsers, pagination, selection, refetch } =
    useContext(UsersPageContext);
  const navigate = useNavigate();
  const toast = useToast();

  const withConfirm = useConfirmDialog();

  const deleteUserMutation = useMutation(
    'deleteUser',
    ({ userId }: { userId: string }) => UsersService.deleteUser(userId),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        refetch();
      },
    }
  );

  const actions: ContextMenuAction[] = [
    {
      label: 'Редактировать',
      icon: <EditOutlined />,
      onClick: (userId) => navigate(`/users/${userId}`),
    },
    {
      label: 'Удалить',
      icon: <DeleteOutlined />,
      onClick: (userId) =>
        withConfirm({
          title: 'Удалить пользователя?',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          onConfirm() {
            deleteUserMutation.mutate({ userId });
          },
        }),
    },
  ];

  const columns: GridColumns<User> = [
    {
      field: 'firstName',
      headerName: 'Пользователь',
      minWidth: 200,
      // maxWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <>
          <Avatar src={params.row.avatar} sx={{ mr: 1 }} />
          <Typography>{`${params.row.firstName} ${params.row.lastName}`}</Typography>
        </>
      ),
    },
    {
      field: 'email',
      headerName: 'Почта',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'birthdate',
      headerName: 'День рождения',
      renderCell: (params) => formatDate(params.value),
      minWidth: 200,
      // maxWidth: 250,
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: 'Номер телефона',
      renderCell: (params) => (
        <Link href={`tel:${params.value}`}>{params.value}</Link>
      ),
      minWidth: 160,
      // maxWidth: 250,
      flex: 1,
    },
    {
      field: '',
      width: 100,
      renderCell: (params) =>
        actions.map((el) => (
          <Tooltip title={el.label} key={`users-action-${el.label}`}>
            <IconButton onClick={() => el.onClick(params.id.toString())}>
              {el.icon}
            </IconButton>
          </Tooltip>
        )),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      <CustomDataGrid
        contextMenuItems={actions}
        selection={{
          model: selection.model,
          setModel: selection.setModel,
          actions: [
            {
              text: 'Удалить выбранных пользователей',
              onClick: (items) => {},
              icon: <Delete color="error" />,
              color: 'error',
            },
          ],
        }}
        paginationMode="server"
        columns={columns}
        rows={users}
        pageSize={pagination.pageSize}
        rowCount={pagination.rowCount}
        page={pagination.page}
        onPageChange={(page) => pagination.setPage(page)}
        onPageSizeChange={(size) => pagination.setPageSize(size)}
        loading={isLoadingUsers}
        // checkboxSelection
      />
    </>
  );
}
