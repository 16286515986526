import axios, { AxiosResponse } from 'axios';
import { SERVICE_URLS } from '../constants';
import {
	Assignment,
	Attachment,
	Glossary,
	Lesson,
	PatchLesson,
	PostAssignment,
	PostAttachment,
	PostGlossary,
	PostLesson,
	PostSelfAssignment,
	SelfAssignment,
} from '../types';
import fillFormData from '../utils/fillFormData';
import sortByPosition from '../utils/sortByPosition';

interface LessonsServiceTypes {
	getLessonsByUnit: (
		unitId?: string,
		options?: { isActive?: boolean }
	) => Promise<Lesson[]>;
	getLesson: (id: string) => Promise<Lesson>;
	updateLesson: (id: string, data: PatchLesson) => Promise<Lesson>;
	updateLessonsOrder: (ids: string[]) => Promise<any>;
	createLesson: (data: PostLesson) => Promise<Lesson>;
	deleteLesson: (id: string) => Promise<any>;
	createAttachment: (
		lessonId: string,
		data: PostAttachment
	) => Promise<Attachment>;
	updateAttachment: (
		id: string,
		data: Partial<PostAttachment>
	) => Promise<Attachment>;
	deleteAttachment: (id: string) => Promise<any>;
	deleteAllAttachments: (id: string) => Promise<any>;
	createGlossary: (lessonId: string, data: PostGlossary) => Promise<Glossary>;
	updateGlossary: (id: string, data: PostGlossary) => Promise<Glossary>;
	deleteGlossary: (id: string) => Promise<any>;
	createAssignment: (
		lessonId: string,
		data: PostAssignment
	) => Promise<Assignment>;
	updateAssignment: (id: string, data: PostAssignment) => Promise<Assignment>;
	deleteAssignment: (id: string) => Promise<any>;
	createSelfAssignment: (
		lessonId: string,
		data: PostSelfAssignment
	) => Promise<SelfAssignment>;
	updateSelfAssignment: (
		id: string,
		data: PostSelfAssignment
	) => Promise<SelfAssignment>;
	deleteSelfAssignment: (id: string) => Promise<any>;
}

const LessonsService: LessonsServiceTypes = {
	async getLessonsByUnit(unitId, options) {
		const res: AxiosResponse<Lesson[]> = await axios.get(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson`,
			{
				params: {
					group_type: 'UNIT',
					group_id: unitId,

					...(options?.isActive !== undefined && {
						is_active: options.isActive,
					}),
				},
			}
		);

		sortByPosition(res.data);

		return res.data;
	},
	async getLesson(id) {
		const res = await axios.get<Lesson>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${id}`
		);

		return res.data;
	},
	async updateLesson(id, data) {
		const res: AxiosResponse<Lesson> = await axios.patch(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${id}`,
			{
				...data,
			}
		);

		return res.data;
	},
	async updateLessonsOrder(ids) {
		await axios.post(`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/order`, {
			positions: ids,
		});
	},
	async createLesson({ program, course, unit, ...data }) {
		const res: AxiosResponse<Lesson> = await axios.post(
			`${SERVICE_URLS.CURRICULUM}/curriculum/unit/${unit.id}/lesson`,
			{
				...data,
			}
		);

		return res.data;
	},
	async deleteLesson(id) {
		await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${id}`);
	},
	async createAttachment(lessonId, { file, ...data }) {
		const formData = new FormData();

		fillFormData(formData, data);

		formData.append('asset', file);

		const res = await axios.post<Attachment>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${lessonId}/attachment`,
			formData
		);

		return res.data;
	},
	async updateAttachment(id, { file, ...data }) {
		const formData = new FormData();

		fillFormData(formData, data);

		if (file) {
			formData.append('asset', file);
		}

		const res = await axios.patch<Attachment>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/attachment/${id}`,
			formData
		);

		return res.data;
	},
	async deleteAttachment(id: string) {
		await axios.delete(
			`${SERVICE_URLS.CURRICULUM}/curriculum/attachment/${id}`
		);
	},

	async deleteAllAttachments(id: string) {
		await axios.delete(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${id}/attachments`
		)
	},

	async updateGlossary(id, data) {
		const res = await axios.patch<Glossary>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/glossary/${id}`,
			data
		);

		return res.data;
	},
	async deleteGlossary(id: string) {
		await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/glossary/${id}`);
	},
	async createGlossary(lessonId, data) {
		const res = await axios.post<Glossary>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${lessonId}/glossary`,
			data
		);

		return res.data;
	},
	async createAssignment(lessonId, { ...data }) {
		const formData = new FormData();

		fillFormData(formData, data);

		const res = await axios.post<Assignment>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${lessonId}/assignment`,
			formData
			// {
			// 	...data,
			// 	// example: coverURL,
			// 	deadline: deadline.toISOString().split('T')[0],
			// }
		);

		return res.data;
	},
	async updateAssignment(id, { example, ...data }) {
		const formData = new FormData();

		fillFormData(formData, {
			...data,
			...(typeof example !== 'string' && { example }),
		});

		const res = await axios.patch<Assignment>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/assignment/${id}`,
			formData
		);

		return res.data;
	},
	async deleteAssignment(id: string) {
		await axios.delete(
			`${SERVICE_URLS.CURRICULUM}/curriculum/assignment/${id}`
		);
	},
	async createSelfAssignment(lessonId, { ...data }) {
		const formData = new FormData();

		fillFormData(formData, data);

		const res = await axios.post<SelfAssignment>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${lessonId}/selfassignment`,
			formData
			// {
			// 	...data,
			// 	// example: coverURL,
			// 	deadline: deadline.toISOString().split('T')[0],
			// }
		);

		return res.data;
	},
	async updateSelfAssignment(id, { example, ...data }) {
		const formData = new FormData();

		fillFormData(formData, {
			...data,
			...(typeof example !== 'string' && { example }),
		});

		const res = await axios.patch<SelfAssignment>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/selfassignment/${id}`,
			formData
		);

		return res.data;
	},
	async deleteSelfAssignment(id: string) {
		await axios.delete(
			`${SERVICE_URLS.CURRICULUM}/curriculum/selfassignment/${id}`
		);
	},
};

export default LessonsService;
