import { Add, DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import CheckboxField from '../../../components/CheckboxField';
import TextField from '../../../components/material/TextField';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../../constants';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import useToast from '../../../hooks/useToast';
import QuizzesService from '../../../services/QuizzesService';
import {
	PostQuiz,
	PostQuizQuestion,
	QuizQuestion,
} from '../../../types/quizzes';
import getTextFieldErrorProps from '../../../utils/getTextFieldErrorProps';
import QuizFormQuestion from './QuizFormQuestion';

interface QuestionsFormValues {
	questions: Array<QuizQuestion | PostQuizQuestion>;
}

export type QuizFormValues = PostQuiz & {
	isActive: boolean;
};

interface Props {
	isDisabled: boolean;
	quizId?: string;
}

export default function QuizForm({ isDisabled, quizId }: Props) {
	const { id } = useParams();
	const withConfirm = useConfirmDialog();
	const toast = useToast();
	const queryClient = useQueryClient();

	const {
		data: quiz,
		isLoading: isLoadingQuiz,
		refetch,
		remove: removeQuiz,
	} = useQuery(['quizzes', id], () => QuizzesService.getQuiz(quizId ?? ''), {
		enabled: quizId !== undefined,
		retry: 1,
		refetchOnWindowFocus: false,
	});

	const isUpdate = quiz !== undefined;
	const quizFormMethods = useForm<QuizFormValues>({
		defaultValues: {
			isActive: isUpdate,

			...(quiz && {
				description: quiz.description,
				threshold: quiz.threshold,
				title: quiz.title,
			}),
		},
	});

	const questionsFormMethods = useForm<QuestionsFormValues>({
		defaultValues: {
			questions: quiz?.entries ?? [],
		},
	});

	const {
		control,
		register,
		setValue,
		formState: { errors, isDirty },
		resetField,
		reset,
		handleSubmit,
		watch,
	} = quizFormMethods;

	const watchIsActive = watch('isActive');

	const saveQuizMutation = useMutation(
		isUpdate ? 'updateTest' : 'createTest',
		(data: PostQuiz) =>
			isUpdate
				? QuizzesService.updateQuiz(quiz?.id ?? '', data)
				: QuizzesService.createQuiz(id ?? '', data),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				queryClient.refetchQueries(['lesson', id]);
				refetch();
			},
		}
	);
	const deleteQuizMutation = useMutation(
		'deleteQuiz',
		() => QuizzesService.deleteQuiz(quiz?.id ?? ''),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				setValue('isActive', false);

				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				queryClient.refetchQueries(['lesson', id]);
				removeQuiz();
			},
		}
	);

	const {
		fields: questions,
		append,
		remove,
		update,
	} = useFieldArray({
		control: questionsFormMethods.control,
		name: 'questions',
		keyName: 'key',
	});

	const isLoading =
		saveQuizMutation.isLoading || deleteQuizMutation.isLoading || isLoadingQuiz;

	const onAddQuestion = () => {
		append({
			question: {
				text: '',
				assets: [],
			},
		});
	};

	const onDeleteQuestion = (index: number) => {
		remove(index);
	};

	const onUpdateQuestion = (index: number, data: QuizQuestion) => {
		update(index, data);
	};

	const onCancel = () => {
		if (!quiz) {
			setValue('isActive', false);
			return;
		}

		if (isLoading) {
			return;
		}

		withConfirm({
			title: 'Удалить тест?',
			onConfirm() {
				deleteQuizMutation.mutate();
			},
			confirmButtonText: 'Удалить',
			cancelButtonText: 'Отмена',
		});
	};

	const onReset = () => {
		reset({
			isActive: isUpdate,

			...(quiz && {
				description: quiz.description,
				threshold: quiz.threshold,
				title: quiz.title,
			}),
		});

		questionsFormMethods.reset({
			questions: quiz?.entries ?? [],
		});
	};

	const onSubmit = (data: QuizFormValues) => {
		if (isLoading) {
			return;
		}

		saveQuizMutation.mutate(data as PostQuiz);
	};

	useEffect(() => {
		onReset();
	}, [quiz]);

	useEffect(() => {
		if (!watchIsActive) {
			if (quiz) {
				setValue('isActive', true);
				onCancel();
				return;
			}

			reset();
		}
	}, [watchIsActive, isDisabled]);

	// useEffect(() => {
	// 	if (watchIsActive && fields.length === 0 && !isDisabled) {
	// 		append({
	// 			assets: [],
	// 			title: '',
	// 			answers: [{ assets: [], title: '', isCorrect: false }],
	// 		});
	// 	} else {
	// 		replace([]);
	// 		reset();
	// 		resetField('questions');
	// 	}
	// }, [watchIsActive]);

	return (
		<>
			<Grid item xs={12} component="form" onSubmit={handleSubmit(onSubmit)}>
				<Divider sx={{ mb: 1 }} />
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					sx={{
						mb: 1,
						position: 'relative',
						zIndex: 1,
					}}
					justifyContent="space-between"
				>
					<CheckboxField
						control={control}
						name="isActive"
						label="Тест"
						formControlLabelProps={{
							disabled: isDisabled || isLoading,
						}}
					/>

					<Box sx={(theme) => ({ color: theme.palette.grey[400] })}>
						{isLoading ? (
							<CircularProgress color="inherit" size={20} />
						) : (
							quiz && (
								<Tooltip title="Удалить">
									<IconButton
										disabled={isLoading}
										onClick={onCancel}
										edge="end"
									>
										<DeleteOutline />
									</IconButton>
								</Tooltip>
							)
						)}
					</Box>
				</Stack>
				{watchIsActive && (
					<>
						<Grid container columnSpacing={{ xs: 2, md: 4 }} rowSpacing={2}>
							<Grid item xs={12} sm={8} lg={9}>
								<TextField
									label="Заголовок"
									fullWidth
									separateLabel
									inputProps={{
										...register('title', { required: 'Введите заголовок' }),
									}}
									{...getTextFieldErrorProps(errors.title)}
									disabled={isLoading}
								/>
							</Grid>
							<Grid item xs={12} sm={4} lg={3}>
								<TextField
									label="Процент успеха"
									fullWidth
									separateLabel
									type="number"
									inputProps={{
										...register('threshold', {
											required: 'Введите процент успеха',
											min: {
												value: 0,
												message: 'Процент не должен быть меньше 0',
											},
											max: {
												value: 100,
												message: 'Процент не должен превышать 100',
											},
										}),
									}}
									{...getTextFieldErrorProps(errors.threshold)}
									disabled={isLoading}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									label="Описание"
									fullWidth
									separateLabel
									multiline
									rows={3}
									inputProps={{
										...register('description'),
									}}
									disabled={isLoading}
								/>
							</Grid>
							{isDirty && (
								<Grid item container spacing={2}>
									<Grid item ml="auto">
										<Button
											fullWidth
											color="inherit"
											onClick={onReset}
											disabled={isLoading}
										>
											Отмена
										</Button>
									</Grid>
									<Grid item>
										<LoadingButton
											fullWidth
											variant="contained"
											type="submit"
											color="secondary"
											loading={isLoading}
										>
											Сохранить тест
										</LoadingButton>
									</Grid>
								</Grid>
							)}
						</Grid>
					</>
				)}
			</Grid>
			{watchIsActive && (
				<Grid item container xs={12} spacing={2}>
					<Grid item xs={12}>
						<Divider sx={{ mb: 1 }} />
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography variant="h6">Вопросы</Typography>
							{quiz && questions.length === 0 && (
								<Button endIcon={<Add />} onClick={onAddQuestion}>
									Добавить вопрос
								</Button>
							)}
						</Stack>

						{!quiz ? (
							<Typography variant="body2" color="text.secondary">
								Для добавления вопросов сначала сохраните тест
							</Typography>
						) : (
							questions.length === 0 && (
								<Typography variant="body2" color="text.secondary">
									Начните добавлять вопросы нажав на кнопку справа
								</Typography>
							)
						)}
					</Grid>
					{quiz &&
						questions.map((el, i) => (
							<QuizFormQuestion
								quiz={quiz}
								key={el.key}
								question={el}
								index={i}
								onDelete={onDeleteQuestion}
								onUpdate={onUpdateQuestion}
							/>
						))}
					{questions.length > 0 && (
						<Grid item xs="auto" ml="auto" mb={4}>
							<Button endIcon={<Add />} onClick={onAddQuestion}>
								Добавить вопрос
							</Button>
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
}
