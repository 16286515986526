import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AutocompleteField from '../../components/AutocompleteField';
import DatePickerField from '../../components/DatePickerField';
import TextField from '../../components/material/TextField';
import RouterLink from '../../components/RouterLink';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import CoursesService from '../../services/CoursesService';
import ProgramsService from '../../services/ProgramsService';
import UnitsService from '../../services/UnitsService';
import { useAppSelector } from '../../store';
import { Course, Program } from '../../types';
import { PostUnit } from '../../types/units';
import getTextFieldErrorProps from '../../utils/getTextFieldErrorProps';

interface Props {
  isUpdate?: boolean;
  defaultValues?: Partial<PostUnit>;
}

export default function UnitForm({ isUpdate = false, defaultValues }: Props) {
  const { program, course } = useAppSelector((state) => state.filters);
  const { id } = useParams();
  const {
    control,
    register,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm<PostUnit>({
    defaultValues: {
      ...defaultValues,
      program: program.value,
      course: course.value,
    },
  });
  const navigate = useNavigate();
  const toast = useToast();

  const saveUnitMutation = useMutation(
    isUpdate ? 'updateUnit' : 'createUnit',
    (data: PostUnit) =>
      isUpdate
        ? UnitsService.updateUnit(id ?? '', data)
        : UnitsService.createUnit(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        navigate('/curriculum/units');
      },
    }
  );

  const { isLoading } = saveUnitMutation;

  const onSubmit = (data: PostUnit) => {
    if (isLoading) {
      return;
    }

    saveUnitMutation.mutate(data);
  };

  const selectedProgram = watch('program');

  const programs = useQuery('programs', () => ProgramsService.getPrograms());
  const courses = useQuery(['courses', selectedProgram?.id], () =>
    CoursesService.getCoursesByProgram(selectedProgram?.id)
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!selectedProgram) {
      setValue('course', undefined);
    }
  }, [selectedProgram]);

  return (
    <Grid
      container
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={10}
        xl={8}
        rowSpacing={2}
        columnSpacing={4}
      >
        <Grid item xs={12}>
          <TextField
            separateLabel
            label="Заголовок"
            fullWidth
            inputProps={{
              ...register('title', { required: 'Введите заголовок' }),
            }}
            {...getTextFieldErrorProps(errors.title)}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            separateLabel
            label="Лимит сдачи"
            fullWidth
            type="number"
            inputProps={{
              ...register('submissionLimit', {
                required: 'Введите лимит',
                valueAsNumber: true,
              }),
            }}
            {...getTextFieldErrorProps(errors.submissionLimit)}
            disabled={isLoading}
          />
        </Grid>

        {!isUpdate && (
          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteField
              name="program"
              control={control}
              label="Программа"
              disabled={isLoading}
              formControlProps={{ fullWidth: true }}
              autocompleteProps={{
                getOptionLabel: (option: any) => (option as Program).title,
                options: programs.data,
                loading: programs.isLoading,
              }}
            />
          </Grid>
        )}
        {!isUpdate && (
          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteField
              name="course"
              control={control}
              rules={{ required: 'Выберите курс' }}
              label="Курс"
              disabled={isLoading}
              formControlProps={{ fullWidth: true }}
              autocompleteProps={{
                getOptionLabel: (option: any) => (option as Course).title,
                options: courses.data,
                loading: courses.isLoading,
                groupBy: (option: Course) =>
                  option.common ? 'Общие курсы' : 'По направлению',
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <DatePickerField
            name="startDate"
            label="Дата старта"
            rules={{ required: 'Введите дату старта' }}
            control={control}
            datePickerProps={{
              disabled: isLoading,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <DatePickerField
            name="endDate"
            label="Дата завершения"
            rules={{ required: 'Введите дату конца' }}
            control={control}
            datePickerProps={{
              disabled: isLoading,
            }}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} md={12} lg={10} xl={8} spacing={2}>
        <Grid item ml="auto">
          <Button
            fullWidth
            color="inherit"
            component={RouterLink}
            to="/curriculum/units"
            disabled={isLoading}
          >
            Отменить
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            color="secondary"
            loading={isLoading}
          >
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
