import { DeleteOutlined, DragHandle, EditOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	IconButton,
	Stack,
	styled,
	Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Banner } from '../types';
import Typography from './material/Typography';

const CardContainer = styled(Card, {
	shouldForwardProp: (prop: string) =>
		!['isDragging', 'isDisabled'].includes(prop),
})<{ isDragging: boolean; isDisabled: boolean }>(
	({ theme, isDragging, isDisabled }) => ({
		position: 'relative',
		userSelect: 'none',
		transition: theme.transitions.create(['transform', 'box-shadow']),
		opacity: isDisabled ? 0.7 : 1,

		...(isDragging && {
			transform: 'scale(1.1)',
		}),

		'.MuiCardMedia-root': {
			cursor: 'zoom-in',
		},

		'.MuiCardContent-root': {
			cursor: ['move', 'grab'],
			paddingTop: theme.spacing(1),
			paddingBottom: 0,
		},

		'.MuiCardActions-root': {
			cursor: ['move', 'grab'],
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
		},

		'.drag-indicator': {
			position: 'absolute',
			bottom: theme.spacing(1.1),
			left: '50%',
			marginLeft: '-0.5em',
		},
	})
);

interface Props {
	banner: Banner;
	onImageClick: (src: string) => void;
	isDragging: boolean;
	isDisabled: boolean;
	onDeleteClick: () => any;
}

export default function BannerCard({
	banner,
	onImageClick,
	isDragging,
	isDisabled,
	onDeleteClick,
}: Props) {
	const navigate = useNavigate();

	const onMediaClick = () => {
		onImageClick(banner.file);
	};

	const onEditClick = () => {
		navigate(`/banners/${banner.id}`);
	};

	return (
		<CardContainer
			variant={isDragging ? 'elevation' : 'outlined'}
			isDragging={isDragging}
			isDisabled={isDisabled}
			elevation={5}
		>
			<Box className="drag-indicator">
				<DragHandle color="disabled" />
			</Box>
			<CardMedia
				component="img"
				image={banner.file}
				onClick={onMediaClick}
				height={100}
			/>
			<CardContent>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="h6" className="banner-title" maxLines={1}>
						{banner.name}
					</Typography>
				</Stack>
			</CardContent>
			<CardActions>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
				>
					<Tooltip title="Удалить баннер">
						<IconButton
							color="error"
							disabled={isDisabled}
							onClick={onDeleteClick}
						>
							<DeleteOutlined fontSize="small" />
						</IconButton>
					</Tooltip>
					<Tooltip title="Изменить баннер">
						<IconButton disabled={isDisabled} onClick={onEditClick}>
							<EditOutlined fontSize="small" />
						</IconButton>
					</Tooltip>
				</Stack>
			</CardActions>
		</CardContainer>
	);
}
