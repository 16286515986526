import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CoursesService from '../services/CoursesService';
import LessonsService from '../services/LessonsService';
import ProgramsService from '../services/ProgramsService';
import UnitsService from '../services/UnitsService';
import { Course, Lesson, Program } from '../types';
import { Unit } from '../types/units';

export const setProgramById = createAsyncThunk(
	'setProgramById',
	async (id: string, thunkApi) => {
		try {
			const res = await ProgramsService.getProgram(id);

			return res;
		} catch (e) {
			thunkApi.rejectWithValue(e);
		}
	}
);

export const setCourseById = createAsyncThunk(
	'setCourseById',
	async (id: string, thunkApi) => {
		try {
			const res = await CoursesService.getCourse(id);

			return res;
		} catch (e) {
			thunkApi.rejectWithValue(e);
		}
	}
);

export const setUnitById = createAsyncThunk(
	'setUnitById',
	async (id: string, thunkApi) => {
		try {
			const res = await UnitsService.getUnit(id);

			return res;
		} catch (e) {
			thunkApi.rejectWithValue(e);
		}
	}
);

export const setLessonById = createAsyncThunk(
	'setLessonById',
	async (id: string, thunkApi) => {
		try {
			const res = await LessonsService.getLesson(id);

			return res;
		} catch (e) {
			thunkApi.rejectWithValue(e);
		}
	}
);

export interface FiltersState {
	program: {
		id?: string;
		value?: Program;
		isLoading: boolean;
	};
	course: {
		id?: string;
		value?: Course;
		isLoading: boolean;
	};
	unit: {
		id?: string;
		value?: Unit;
		isLoading: boolean;
	};
	lesson: {
		id?: string;
		value?: Lesson;
		isLoading: boolean;
	};
	additionalFilters?: {
		[key: string]: any;
	};
}

const initialState: FiltersState = {
	program: { isLoading: false },
	course: { isLoading: false },
	unit: { isLoading: false },
	lesson: { isLoading: false },
};

const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		setProgramValue: (state, { payload }) => ({
			...state,
			program: { ...state.program, value: payload, id: payload?.id },
			course: { ...initialState.course },
			unit: { ...initialState.unit },
			lesson: { ...initialState.lesson },
		}),
		setCourseValue: (state, { payload }) => ({
			...state,
			course: { ...state.course, value: payload, id: payload?.id },
			unit: { ...initialState.unit },
			lesson: { ...initialState.lesson },
		}),
		setUnitValue: (state, { payload }) => ({
			...state,
			unit: { ...state.unit, value: payload, id: payload?.id },
			lesson: { ...initialState.lesson },
		}),
		setLessonValue: (state, { payload }) => ({
			...state,
			lesson: { ...state.lesson, value: payload, id: payload?.id },
		}),
		clearProgram: (state) => ({
			...state,
			program: { ...state.program, value: undefined, id: undefined },
		}),
		clearCourse: (state) => ({
			...state,
			course: { ...state.course, value: undefined, id: undefined },
		}),
		clearUnit: (state) => ({
			...state,
			unit: { ...state.unit, value: undefined, id: undefined },
		}),
		setAdditionalFilter: (state, { payload }) => ({
			...state,
			additionalFilters: {
				...state.additionalFilters,
				[payload.key]: payload.value,
			},
		}),
		resetAdditionalFilters: (state) => ({
			...state,
			additionalFilters: undefined,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(setProgramById.pending, (state, action) => {
				state.program.id = undefined;
				state.program.value = undefined;
				state.program.isLoading = true;
			})
			.addCase(setProgramById.fulfilled, (state, { payload }) => {
				state.program.id = payload?.id;
				state.program.value = payload;
				state.program.isLoading = false;
			})
			.addCase(setProgramById.rejected, (state, action) => {
				state.program.id = undefined;
				state.program.value = undefined;
				state.program.isLoading = false;
			})
			// Courses
			.addCase(setCourseById.pending, (state, action) => {
				state.course.id = undefined;
				state.course.value = undefined;
				state.course.isLoading = true;
			})
			.addCase(setCourseById.fulfilled, (state, { payload }) => {
				state.course.id = payload?.id;
				state.course.value = payload;
				state.course.isLoading = false;
			})
			.addCase(setCourseById.rejected, (state, action) => {
				state.course.id = undefined;
				state.course.value = undefined;
				state.course.isLoading = false;
			})
			// Units
			.addCase(setUnitById.pending, (state, action) => {
				state.unit.id = undefined;
				state.unit.value = undefined;
				state.unit.isLoading = true;
			})
			.addCase(setUnitById.fulfilled, (state, { payload }) => {
				state.unit.id = payload?.id;
				state.unit.value = payload;
				state.unit.isLoading = false;
			})
			.addCase(setUnitById.rejected, (state, action) => {
				state.unit.id = undefined;
				state.unit.value = undefined;
				state.unit.isLoading = false;
			})
			// Lessons
			.addCase(setLessonById.pending, (state, action) => {
				state.lesson.id = undefined;
				state.lesson.value = undefined;
				state.lesson.isLoading = true;
			})
			.addCase(setLessonById.fulfilled, (state, { payload }) => {
				state.lesson.id = payload?.id;
				state.lesson.value = payload;
				state.lesson.isLoading = false;
			})
			.addCase(setLessonById.rejected, (state, action) => {
				state.lesson.id = undefined;
				state.lesson.value = undefined;
				state.lesson.isLoading = false;
			});
	},
});

export const {
	setProgramValue,
	setCourseValue,
	setUnitValue,
	setLessonValue,
	clearCourse,
	clearProgram,
	setAdditionalFilter,
	resetAdditionalFilters,
} = filtersSlice.actions;

const filtersReducer = filtersSlice.reducer;

export default filtersReducer;
