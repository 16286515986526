import { styled } from '@mui/material';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import { useAppSelector } from '../../store';
import UnitsList from '../../views/UnitsPage/UnitsList';
import UnitsPageHeader from '../../views/UnitsPage/UnitsPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function UnitsPage() {
  const { program, course } = useAppSelector((state) => state.filters);
  useSetHeaderTitle(
    'Блоки',
    course.value &&
      `${program?.value?.title ? `${program.value.title} - ` : ''}${
        course.value.title
      }`
  );

  return (
    <PageContainer>
      <UnitsPageHeader />

      <UnitsList />
    </PageContainer>
  );
}

export default withWhitelistRoles(UnitsPage, ['admin']);
