import MainContainer from "../MainContainer";
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement, LineElement, Title, Filler
} from 'chart.js';

const QuizActivity = ({data: dataFromQuery}: any) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );
    const options = {
        scales: {
            x: {
                ticks: {
                    display: false,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: true,
                text: 'Набранные балы при сдаче теста',
            },
        },
    };

    const labels = dataFromQuery.map((d: any) => d.quiz);

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Секунд',
                data: dataFromQuery.map((d: any) => d.score),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <MainContainer>
            {dataFromQuery.length ?
                <Line height="300px" options={options} data={data} />
                :
                <span>Нет актуальных данных</span>
            }
        </MainContainer>
    )
}

export default QuizActivity;