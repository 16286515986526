import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import TokenService from '../services/TokenService';
import { useAppSelector } from '../store';
import { setIsSignedIn } from '../store/appSlice';
import jwt_decode from 'jwt-decode';
import { JWT } from '../types';

// Auth guard
export default function withAuth(
	Component: React.ComponentType,
	isSignedInRequired: boolean,
	isSignedInForbidden: boolean,
	roles?: { rolesWhiteList: string[]; fallbackPath: string }
) {
	return () => {
		const { isSignedIn, claims } = useAppSelector((state) => state.app);
		const navigate = useNavigate();
		const dispatch = useDispatch();

		// Start token checker
		useEffect(() => {
			// Check the token in background
			const authChecker = setInterval(() => {
				let newSignedIn = TokenService.tokenExists();
				const accessToken = TokenService.getToken();

				if (newSignedIn && accessToken) {
					const tokenDecoded: JWT = jwt_decode(accessToken);

					if (
						tokenDecoded.claims.length > 0 &&
						tokenDecoded.claims.find(
							(el) => el.name.toLowerCase() === 'student'
						)
					) {
						newSignedIn = false;
					}
				}

				if (newSignedIn !== isSignedIn) {
					dispatch(setIsSignedIn(newSignedIn));
				}
			}, 1000);

			return () => {
				clearInterval(authChecker);
			};
		}, [isSignedIn]);

		// If anauthenticated and component requires sign in
		if (isSignedInRequired && !isSignedIn) {
			return <Navigate to="/auth/sign-in" replace />;
		}

		// If authenticated and component forbides signed in users
		if (isSignedInForbidden && isSignedIn) {
			return <Navigate to="/" />;
		}

		if (
			roles &&
			claims &&
			claims.length > 0 &&
			!roles.rolesWhiteList.includes(claims[0].name)
		) {
			return <Navigate to={roles.fallbackPath} />;
		}
		return <Component />;
	};
}
