import DashboardPageHeader from '../../components/DashboardPageHeader';

export default function NPSPageHeader() {
	return (
		<DashboardPageHeader
			newButtonText=""
			curriculumFiltersProps={{
				program: true,
				course: true,
			}}
			disableNewButton
		/>
	);
}
