/* 
    Converts an object to search params query
*/
export default function objectToSearchParams(obj: { [key: string]: any }) {
	let res = '';

	const keys = Object.keys(obj);

	for (let i = 0; i < keys.length; i++) {
		const key: string = keys[i];

		if (i === 0) {
			res += '?';
		} else {
			res += '&';
		}

		res += `${key}=${obj[key]}`;
	}

	return res;
}
