import { styled } from '@mui/material';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import ProgramsGrid from '../../views/ProgramsPage/ProgramsGrid';
import ProgramsPageHeader from '../../views/ProgramsPage/ProgramsPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function ProgramsPage() {
	useSetHeaderTitle('Программы');

	return (
		<PageContainer>
			<ProgramsPageHeader />
			<ProgramsGrid />
		</PageContainer>
	);
}

export default withWhitelistRoles(ProgramsPage, ['admin']);
