import { GridSelectionModel } from '@mui/x-data-grid';
import { createContext, ReactNode, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import useToast from '../hooks/useToast';
import CoursesService from '../services/CoursesService';
import ProgramsService from '../services/ProgramsService';
import { useAppSelector } from '../store';
import { Course } from '../types';

interface ContextProps {
	courses: Course[];
	isLoadingCourses: boolean;
	selection: {
		setModel: (users: GridSelectionModel) => void;
		model: GridSelectionModel;
	};
	deleteCourse: (id: string) => any;
}

const initialState: ContextProps = {
	courses: [],
	isLoadingCourses: true,
	selection: {
		model: [],
		setModel: () => null,
	},
	deleteCourse: () => null,
};

export const CoursesPageContext = createContext<ContextProps>(initialState);

export function CoursesPageContextProvider({
	children,
}: {
	children: ReactNode;
}) {
	const toast = useToast();
	const { program, additionalFilters } = useAppSelector(
		(state) => state.filters
	);

	const {
		data: courses,
		isLoading: isLoadingCourses,
		refetch,
	} = useQuery<Course[]>(
		[
			'courses',
			program.id,
			additionalFilters?.courseIsActive,
			additionalFilters?.courseType,
		],
		() =>
			CoursesService.getCoursesByProgram(program.id ?? undefined, {
				isActive: additionalFilters?.courseIsActive,
				courseType: additionalFilters?.courseType,
			}),
		{ enabled: Boolean(program) }
	);
	const [selectedCourses, setSelectedCourses] = useState<GridSelectionModel>(
		[]
	);

	const deleteCourseMutation = useMutation(
		'deleteCourse',
		(id: string) => CoursesService.deleteCourse(id),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				refetch();
			},
		}
	);

	const deleteCourse = (id: string) => {
		if (deleteCourseMutation.isLoading) {
			return;
		}

		deleteCourseMutation.mutate(id);
	};

	const contextValue: ContextProps = {
		courses: courses ?? [],
		isLoadingCourses,
		selection: {
			model: selectedCourses,
			setModel: setSelectedCourses,
		},
		deleteCourse,
	};

	return (
		<CoursesPageContext.Provider value={contextValue}>
			{children}
		</CoursesPageContext.Provider>
	);
}
