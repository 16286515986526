export const DEFAULT_HEADER_TITLE = 'The Flow';
export const SIDEBAR_WIDTH = 280;
export const IMAGE_UPLOAD_TYPES = [
	'image/png',
	'image/jpeg',
	'image/webp',
	'image/heic',
];
export const BANNER_TYPES = ['COMING_SOON', 'ACTIVE', 'REMOVED'] as const;
export const COURSE_TYPES = ['INTRO', 'REQUIRED', 'OTHER'] as const;
export const COURESE_LEVELS = ['INTRO', 'LAST', 'BUSINESS'] as const;

export const DEFAULT_ERROR_MESSAGE =
	'Произошла непредвиденная ошибка. Пожалуйста, попробуйте еще раз.';
export const DEFAULT_SUCCESS_MESSAGE = 'Данные были успешно сохранены!';
export const ORDER_SUCCESS_MESSAGE = 'Порядок был успешно обновлен!';

export const ATTACHMENT_TYPES = {
	DEFAULT: 'DEFAULT',
	VIDEO: 'VIDEO',
};

export const S3_BASE_URL = 'https://fra1.digitaloceanspaces.com';
export const S3_BUCKET_URL = 'https://fra1.digitaloceanspaces.com/flow';

interface API_MAP {
	[key: string]: string;
}

const env = process.env.REACT_APP_ENV || 'local';
const BASE_API_URL_MAP: API_MAP = {
	local: 'https://service-<serviceName>-stage.theflow.education/api',
	stage: 'https://service-<serviceName>-stage.theflow.education/api',
	prod: 'https://service-<serviceName>.sova.education/api',
};
const BASE_API_URL = BASE_API_URL_MAP[env];
export const SERVICE_URLS = {
	CURRICULUM: BASE_API_URL.replace('<serviceName>', 'curriculum'),
	PROFILE: BASE_API_URL.replace('<serviceName>', 'profile'),
	ASSETS: BASE_API_URL.replace('<serviceName>', 'assets'),
	ACL: BASE_API_URL.replace('<serviceName>', 'acl'),
};

export const ROLES_MAP: { [key: string]: string } = {
	Admin: 'Администратор',
	Student: 'Студент',
	Mentor: 'Наставник',
};

export const EMAIL_TYPES = ['COURSE_FINISH'] as const;

export const EMAIL_TYPE_LABELS: { [key: string]: string } = {
	COURSE_FINISH: 'Сертификаты об окончании курса',
};

export const STUDENT_TYPES = ['STANDARD', 'MEDIUM', 'PREMIUM'];

export const STUDENT_TYPE_LABELS: { [key: string]: string } = {
	STANDARD: 'Стандарт',
	MEDIUM: 'Наставничество',
	PREMIUM: 'Мастер-майнд',
};
