import {
	Assignment,
	AssignmentOutlined,
	AssignmentTurnedInOutlined,
	DeleteOutlined,
	DragHandle,
	DragIndicator,
	EditOutlined,
	PeopleOutlined,
	Quiz,
} from '@mui/icons-material';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	Grid,
	IconButton,
	Stack,
	styled,
	Tooltip,
} from '@mui/material';
import { ElementType, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { DraggableListItemSelection } from '../../components/DraggableList';
import Typography from '../../components/material/Typography';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useContextMenu, { ContextMenuAction } from '../../hooks/useContextMenu';
import useNavigateWithFilters from '../../hooks/useNavigateWithFilters';
import { Lesson } from '../../types';

interface Props extends DraggableListItemSelection {
	index: number;
	lesson: Lesson;
	isDisabled: boolean;
	onDelete: (id: string) => any;
}

const Container = styled(Card, {
	shouldForwardProp: (prop: string) => !['isDragging'].includes(prop),
})<{ component?: ElementType; isDragging?: boolean }>(
	({ theme, isDragging }) => ({
		cursor: 'grab',
		transition: theme.transitions.create(['transform']),

		...(isDragging && {
			transform: 'scale(1.03)',
		}),

		'.MuiCardContent-root': {
			padding: theme.spacing(1),

			'&:last-child': {
				paddingBottom: theme.spacing(1),
			},
		},

		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			position: 'relative',

			'.drag-handle-row': {
				position: 'absolute',
				bottom: theme.spacing(0),
				left: 0,
				right: 0,
			},
		},
	})
);

export default function LessonsListRow({
	lesson,
	index,
	selection,
	toggleSelection,
	isDisabled,
	onDelete,
}: Props) {
	const navigate = useNavigate();
	const withConfirm = useConfirmDialog();
	const navigateWithFilters = useNavigateWithFilters();

	const contextMenuActions: ContextMenuAction[] = [
		{
			label: 'Тестовые задания',
			onClick: (lessonId: string) =>
				navigateWithFilters('quizzes', lessonId, { lessonId }),
			icon: <AssignmentTurnedInOutlined />,
		},
		{
			label: 'Домашние задания',
			onClick: (lessonId: string) =>
				navigateWithFilters('assignments', lessonId, { lessonId }),
			icon: <AssignmentOutlined />,
		},
		{
			label: 'Пользователи',
			onClick: (lessonId: string) =>
				navigateWithFilters('users', lessonId, { lessonId }),
			icon: <PeopleOutlined />,
		},
		{
			label: 'Редактировать',
			onClick: (lessonId: string) => navigate(lessonId),
			icon: <EditOutlined />,
		},
		{
			label: 'Удалить',
			onClick: (lessonId: string) => onDelete(lessonId),
			icon: <DeleteOutlined />,
		},
	];

	const isSelected = useMemo(() => {
		return selection.includes(lesson.id);
	}, [selection]);

	const actionsSliceLength = 2;

	const { menu, setAnchorPosition } = useContextMenu(
		contextMenuActions,
		lesson.id
	);

	return (
		<Draggable
			index={index}
			draggableId={lesson.id}
			isDragDisabled={isDisabled}
		>
			{(provided, snapshot) => (
				<Box
					draggable
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
				>
					{menu}
					<Container
						variant={
							snapshot.isDragging && !snapshot.isDropAnimating
								? 'elevation'
								: 'outlined'
						}
						isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
						onContextMenu={(event) => {
							setAnchorPosition({ top: event.clientY, left: event.clientX });
							event.preventDefault();
						}}
					>
						<CardContent>
							<Grid container alignItems="center" spacing={1}>
								<Grid
									item
									xs={12}
									sm="auto"
									order={{ xs: 2, sm: 0 }}
									className="drag-handle-row"
								>
									<DragIndicator
										color="disabled"
										sx={{ display: { xs: 'none', sm: 'inherit' } }}
									/>
									<DragHandle
										color="disabled"
										sx={{ display: { sm: 'none' } }}
									/>
								</Grid>
								<Grid item xs order={{ xs: 0, sm: 1 }}>
									{/* <Checkbox
												checked={isSelected}
												onChange={() => toggleSelection(lesson.id)}
											/> */}
									<Typography pt={{ xs: 1, sm: 0 }} maxLines={3}>
										{lesson.title}
									</Typography>
								</Grid>
								<Grid container item xs={12} sm="auto" order={{ xs: 1, sm: 2 }}>
									<Grid
										item
										xs
										display="flex"
										justifyContent={{ xs: 'flex-start', sm: 'center' }}
									>
										<Stack direction="row">
											{contextMenuActions
												.slice(0, actionsSliceLength)
												.map((el) => (
													<Tooltip
														title={el.label}
														key={`lesson-action-${el.label}`}
													>
														<IconButton
															disabled={isDisabled}
															onClick={() => {
																el.onClick(lesson.id);
															}}
														>
															{el.icon}
														</IconButton>
													</Tooltip>
												))}
										</Stack>
									</Grid>
									<Grid
										item
										xs
										display="flex"
										justifyContent={{ xs: 'flex-end', sm: 'center' }}
									>
										<Stack direction="row">
											{contextMenuActions
												.slice(actionsSliceLength)
												.map((el) => (
													<Tooltip
														title={el.label}
														key={`lesson-action-${el.label}`}
													>
														<IconButton
															disabled={isDisabled}
															onClick={() => {
																el.onClick(lesson.id);
															}}
														>
															{el.icon}
														</IconButton>
													</Tooltip>
												))}
										</Stack>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Container>
				</Box>
			)}
		</Draggable>
	);
}
