import LoadingButton from '@mui/lab/LoadingButton';
import {Button, Dialog, Grid, MenuItem, styled} from '@mui/material';
import {useEffect, useState} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AutocompleteField from '../../components/AutocompleteField';
import CheckboxField from "../../components/CheckboxField";
import DatePickerField from '../../components/DatePickerField';
import { MaskedField } from '../../components/MaskedField';
import TextField from '../../components/material/TextField';
import PasswordField from '../../components/PasswordField';
import RouterLink from '../../components/RouterLink';
import Select from '../../components/SelectField';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  ROLES_MAP,
  STUDENT_TYPES,
  STUDENT_TYPE_LABELS,
} from '../../constants';
import useToast from '../../hooks/useToast';
import ProgramsService from '../../services/ProgramsService';
import UsersService from '../../services/UsersService';
import { PostUser, Program } from '../../types';
const ModalContainer = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    minWidth: 700,
    margin: theme.spacing(2),
    padding: '20px',
  },

  '.MuiDialogContent-root': {
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(1, 1),
    paddingBottom: '100%',
  },

  '.MuiDialogActions-root': {
    minHeight: 60,
    position: 'sticky',
    bottom: 0,
    background: '#fff',
  },

  button: {
    maxWidth: '400px',
  },

  [theme.breakpoints.down(700 + 32)]: {
    '.MuiDialog-paper': {
      minWidth: 'initial',
      width: '100%',
    },
  },
}));

interface Props {
  isUpdate?: boolean;
  defaultValues?: Partial<PostUser>;
  isStudent: boolean;
}

export default function UserForm({
  isUpdate = false,
  defaultValues,
  isStudent,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const setDefaultPassword = async  () =>{
    const url = window.location.href;
    const parts = url.split('/');
    const id = parts[parts.length - 1];
    await UsersService.setDefaultPassword(id);
    setIsModalOpen(false);
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<PostUser>({ defaultValues });
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const saveUserMutation = useMutation(
    isUpdate ? 'updateUser' : 'createUser',
    (data: PostUser) =>
      isUpdate
        ? UsersService.updateUser(
            id ?? '',
            data,
            defaultValues?.studentProgram ?? []
          )
        : UsersService.createUser(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        navigate('/users');
      },
    }
  );

  const { data: roles, isLoading: isLoadingRoles } = useQuery(
    'getRoles',
    UsersService.getRoles
  );

  const watchRole = watch('role');

  const isWatchStudent =
    roles?.find((role) => role.id === watchRole)?.name.toLowerCase() ===
    'student';

  const programs = useQuery('programs', () => ProgramsService.getPrograms());

  const { isLoading } = saveUserMutation;

  const onSubmit: SubmitHandler<PostUser> = (data) => {
    if (isLoading) {
      return;
    }

    saveUserMutation.mutate({
      ...data,
      phoneNumber: data.phoneNumber?.replace(/[^0-9.]/g, ''),
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  // @ts-ignore
  // @ts-ignore
  return (
    <Grid
      container
      spacing={6}
      mb={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item container spacing={4}>
        {/* <Grid item xs={12} sm={6} md="auto" minWidth={330}>
					<ImageUploadField
						label="Фото"
						name="image"
						control={control}
						ratio={1}
						rules={{ required: 'Загрузите изображение' }}
						formControlProps={{disabled: isLoading}}
					/>
				</Grid> */}
        <Grid container item spacing={2} xs={12} lg={10} xl={9}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Имя"
              separateLabel
              inputProps={{
                ...register('firstName', { required: 'Введите имя' }),
              }}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message}
              disabled={isLoading}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Фамилия"
              separateLabel
              inputProps={{
                ...register('lastName', { required: 'Введите фамилию' }),
              }}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message}
              disabled={isLoading}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Электронная почта"
              type="email"
              separateLabel
              inputProps={{
                ...register('email', { required: 'Введите электронную почту' }),
              }}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              disabled={isLoading}
              autoComplete="new-password"
            />
          </Grid>
          {!isUpdate && (
            <Grid item xs={12} sm={6}>
              <PasswordField
                fullWidth
                label="Пароль"
                type="password"
                separateLabel
                inputProps={{
                  ...register('password', {
                    required: 'Введите пароль',
                  }),
                }}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                disabled={isLoading}
                autoComplete="new-password"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <DatePickerField
              control={control}
              name="birthdate"
              label="День рождения"
              datePickerProps={{
                disabled: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MaskedField
              fullWidth
              label="Номер телефона"
              separateLabel
              mask={[
                { mask: '+0 (000) 000-00-00' },
                { mask: '+00 (000) 000-00-00' },
                { mask: '+000 (000) 000-00-00' },
                { mask: '+0000 (000) 000-00-00' },
              ]}
              inputProps={{
                ...register('phoneNumber', {
                  required: 'Введите номер телефона',
                }),
              }}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
              disabled={isLoading}
            />
          </Grid>

          {!isUpdate && (
            <Grid item xs={12} sm={6} minWidth={200}>
              <Select
                formControlProps={{ fullWidth: true, disabled: isLoading }}
                label="Тип пользователя"
                name="role"
                rules={{ required: isUpdate ? false : 'Выберите тип' }}
                control={control}
              >
                {roles?.map((el) => (
                  <MenuItem value={el.id} key={`role-${el.id}`}>
                    {ROLES_MAP[el.name]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {(isStudent || isWatchStudent) && (
            <>
              <Grid item xs={12} sm={6} minWidth={200}>
                <Select
                  formControlProps={{ fullWidth: true, disabled: isLoading }}
                  label="Тип студента"
                  name="studentType"
                  rules={{ required: 'Выберите тип студента' }}
                  control={control}
                >
                  {STUDENT_TYPES.map((el) => (
                    <MenuItem value={el} key={`student-type-${el}`}>
                      {STUDENT_TYPE_LABELS[el]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} minWidth={200}>
                <AutocompleteField
                  name="studentProgram"
                  control={control}
                  rules={{ required: 'Выберите программу' }}
                  label="Программа"
                  disabled={isLoading}
                  formControlProps={{ fullWidth: true }}
                  autocompleteProps={{
                    defaultValue: [],
                    multiple: true,
                    isOptionEqualToValue: (option: any, value: any) =>
                      (option as Program).id === (value as Program).id,
                    getOptionLabel: (option: any) => (option as Program).title,
                    options: programs.data,
                    loading: programs.isLoading,
                    disableCloseOnSelect: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} minWidth={200}>
                <CheckboxField
                    control={control}
                    name="noLimitVideo"
                    label="Убрать ограничения по просмотру видео"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item container spacing={2} xs={12} lg={10} xl={9}>
          {isUpdate &&
              <Grid item xs={12} lg={2} mr="auto" >
                <LoadingButton
                    fullWidth
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    onClick={openModal}
                >
                  Сбросить пароль
                </LoadingButton>
              </Grid>
          }
          <Grid item ml="auto">
            <Button
              fullWidth
              color="inherit"
              component={RouterLink}
              to="/users"
              disabled={isLoading}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
              loading={isLoading}
            >
              Сохранить
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
        <ModalContainer open={isModalOpen} >
          <h2>Сброс пароля</h2>
          <p>Вы уверены,что хотите сбросить пароль этому пользователю?</p>
          <LoadingButton variant="contained" color="secondary" onClick={setDefaultPassword}>Сбросить пароль</LoadingButton>
        </ModalContainer>
    </Grid>
  );
}
