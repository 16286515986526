import axios from 'axios';
import { SERVICE_URLS } from '../constants';
import { INPSUnit, TNPSRecord } from '../types';
import sortByPosition from '../utils/sortByPosition';

const NPSService = {
	getNPSForCourse: async (courseId: string) => {
		const res = await axios.get<INPSUnit[]>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/nps/course/${courseId}`
		);

		sortByPosition(res.data);

		res.data = res.data.map((nps, i) => ({
			...nps,
			position: i + 1,
		}));

		return res.data;
	},
	getNPSForUnit: async (unitId: string) => {
		const res = await axios.get<{ users: TNPSRecord[] }>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/nps/unit/${unitId}`
		);
		return res.data.users;
	},
};

export default NPSService;
