import {
	AccessAlarmOutlined,
	EmailOutlined,
	LogoutOutlined,
	Search,
	SettingsOutlined,
} from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Divider,
	Fade,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Toolbar,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { ROLES_MAP } from '../../constants';
import { NAV_LINKS } from '../../constants/navLinks';
import AuthService from '../../services/AuthService';
import { useAppSelector } from '../../store';
import ButtonBase from '../material/ButtonBase';
import TextField from '../material/TextField';
import EmailDialog from '../modals/EmailDialog';
import RouterLink from '../RouterLink';
import SidebarLink from './SidebarLink';

interface Props {
	onClose: () => void;
}

export default function SidebarContent({ onClose }: Props) {
	const { isSidebarOpen, profile, claims } = useAppSelector(
		(state) => state.app
	);
	const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

	const onEmailClick = () => {
		setIsEmailDialogOpen(true);
	};

	const onEmailDialogClose = () => {
		setIsEmailDialogOpen(false);
	};

	return (
		<>
			<EmailDialog isOpen={isEmailDialogOpen} onClose={onEmailDialogClose} />
			<Box pt={3} />
			{profile && (
				<ButtonBase
					component={RouterLink}
					to="/profile"
					sx={{
						alignItems: 'center',
						justifyContent: 'flex-start',
						p: 2,
						display: { xs: 'flex', md: 'none' },
					}}
					onClick={onClose}
				>
					<Avatar src="/dfdff" sx={{ width: 48, height: 48 }} />
					<Box pl={2}>
						<Typography fontWeight={600}>
							{profile.firstName} {profile.lastName?.substring(0, 1)}.
						</Typography>
						<Typography color="grey.500">
							{claims && claims?.length > 0 && ROLES_MAP[claims[0].name]}
						</Typography>
					</Box>
				</ButtonBase>
			)}
			<Toolbar sx={{ pb: 2, pt: 3, display: { xs: 'none', md: 'flex' } }}>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Avatar
						src="/images/logo.png"
						sx={{ width: 24, height: 24, transform: `scale(1.3)` }}
					/>
					<Typography variant="h2">Управление</Typography>
				</Stack>
			</Toolbar>

			<List component="nav">
				{NAV_LINKS.filter((el) =>
					el.roles
						? claims && claims.length > 0
							? el.roles.includes(claims[0].name.toLowerCase())
							: false
						: true
				).map((link) => (
					<SidebarLink
						key={link.text + 'sidebar-link'}
						link={link}
						onSideMenuClose={onClose}
					/>
				))}

				<Divider sx={{ mx: 2, my: 1 }} />

				{/* <SidebarLink
					link={{
						text: 'Настройки',
						path: '/settings',
						icon: <SettingsOutlined />,
					}}
					onSideMenuClose={onClose}
				/> */}
				{claims &&
					claims.length > 0 &&
					['admin'].includes(claims[0].name.toLowerCase()) && (
						<>
							<ListItemButton component={RouterLink} to="/">
								<ListItemIcon sx={{ color: 'inherit' }}>
									<AccessAlarmOutlined />
								</ListItemIcon>
								<ListItemText>Прогресс</ListItemText>
							</ListItemButton>
							<ListItemButton
								component={Button}
								fullWidth
								onClick={onEmailClick}
							>
								<ListItemIcon sx={{ color: 'inherit' }}>
									<EmailOutlined />
								</ListItemIcon>
								<ListItemText>Email рассылка</ListItemText>
							</ListItemButton>
							<Divider sx={{ mx: 2, my: 1 }} />
						</>
					)}

				<ListItemButton
					component={Button}
					fullWidth
					color="error"
					sx={{ color: (theme) => theme.palette.error.light }}
					onClick={() => AuthService.signOut()}
				>
					<ListItemIcon>
						<LogoutOutlined
							sx={{ color: (theme) => theme.palette.error.light }}
						/>
					</ListItemIcon>
					<ListItemText>Выйти</ListItemText>
				</ListItemButton>
			</List>
		</>
	);
}
