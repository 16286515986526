import { ExpandMore } from '@mui/icons-material';
import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store';
import { openSidebar } from '../../store/appSlice';
import RouterLink from '../RouterLink';

interface Props {
	link: NavLink;
	onSideMenuClose: () => void;
}

export default function SidebarLink({ link, onSideMenuClose }: Props) {
	const { pathname } = useLocation();
	const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen);
	const dispatch = useAppDispatch();
	const [isOpen, setIsOpen] = useState(true);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const toggleIsOpen = () => {
		if (!isSidebarOpen && link.hasNested) {
			dispatch(openSidebar());
			setIsOpen(true);
			return;
		}

		setIsOpen((prev) => !prev);
	};

	const isSelected = useMemo(() => {
		// if (link.hasNested) {
		// 	return pathname.startsWith(link.path ?? '');
		// }

		return pathname.startsWith(link.path ?? '') || pathname === link.path;
	}, [pathname]);

	useEffect(() => {
		if (!isSidebarOpen) {
			setIsTooltipOpen(false);
		}
	}, [isSidebarOpen]);

	return (
		<>
			<Tooltip
				title={link.text}
				placement="right"
				disableHoverListener={isSidebarOpen}
				disableFocusListener={isSidebarOpen}
				disableInteractive={isSidebarOpen}
				disableTouchListener={isSidebarOpen}
				open={isTooltipOpen && !isSidebarOpen}
				onClose={() => setIsTooltipOpen(false)}
				onOpen={() => setIsTooltipOpen(true)}
			>
				{link.hasNested ? (
					<ListItemButton
						onClick={toggleIsOpen}
						key={link.text}
						color="text.primary"
						selected={isSelected}
						disabled={link.disabled}
					>
						<ListItemIcon sx={{ color: 'inherit' }}>{link.icon}</ListItemIcon>
						<ListItemText>{link.text}</ListItemText>
						{/* {link.hasNested && (isOpen ? <ExpandLess /> : <ExpandMore />)} */}
						{link.hasNested && (
							<ExpandMore
								sx={(theme) => ({
									transform: `rotate(${isOpen ? 0 : -90}deg)`,
									transition: theme.transitions.create('all', {
										duration: theme.transitions.duration.short,
									}),
								})}
							/>
						)}
					</ListItemButton>
				) : (
					<ListItemButton
						component={RouterLink}
						to={link.path ?? ''}
						onClick={onSideMenuClose}
						key={link.text}
						color="text.primary"
						selected={isSelected}
						disabled={link.disabled}
					>
						<ListItemIcon sx={{ color: 'inherit' }}>{link.icon}</ListItemIcon>
						<ListItemText>{link.text}</ListItemText>
					</ListItemButton>
				)}
			</Tooltip>

			{link.hasNested && link.children && (
				<Collapse in={isOpen && isSidebarOpen}>
					<List disablePadding sx={{ pl: 7, pr: 3 }}>
						{link.children.map((nestedLink) => (
							<ListItemButton
								key={`side-menu-link-nested-${nestedLink.text}`}
								component={RouterLink}
								to={(link.path ?? '') + nestedLink.path ?? ''}
								onClick={onSideMenuClose}
								selected={
									((link.path ?? '') + nestedLink.path ?? '') === pathname
								}
								className="nested"
								disabled={link.disabled}
							>
								<ListItemText>{nestedLink.text}</ListItemText>
							</ListItemButton>
						))}
					</List>
				</Collapse>
			)}
		</>
	);
}
