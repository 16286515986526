import axios, { AxiosResponse } from 'axios';
import { SERVICE_URLS } from '../constants';
import { Banner, PostBanner } from '../types';
import fillFormData from '../utils/fillFormData';
import sortByPosition from '../utils/sortByPosition';

interface BannersServiceTypes {
	getBanners: () => Promise<Banner[]>;
	getBanner: (id: string) => Promise<Banner>;
	updateBannersOrder: (ids: string[], activeCount: number) => Promise<any>;
	updateBanner: (bannerId: string, data: PostBanner) => Promise<Banner>;
	createBanner: (data: PostBanner) => Promise<Banner>;
	deleteBanner: (id: string) => Promise<any>;
}

const BannersService: BannersServiceTypes = {
	async getBanners() {
		const res: AxiosResponse<Banner[]> = await axios.get(
			`${SERVICE_URLS.ASSETS}/assets/banner`
		);

		sortByPosition(res.data);

		return res.data;
	},
	async getBanner(id) {
		const res: AxiosResponse<Banner> = await axios.get(
			`${SERVICE_URLS.ASSETS}/assets/banner/${id}`
		);

		return res.data;
	},
	async updateBannersOrder(ids, activeCount) {
		await axios.post(`${SERVICE_URLS.ASSETS}/assets/banner/order`, {
			bannerIds: ids,
			activeCount,
		});
	},
	async updateBanner(id, data) {
		const formData = new FormData();

		fillFormData(formData, data);

		const res: AxiosResponse<Banner> = await axios.patch(
			`${SERVICE_URLS.ASSETS}/assets/banner/${id}`,
			formData
		);
		return res.data;
	},
	async createBanner(data) {
		const formData = new FormData();

		fillFormData(formData, data);

		// const banners: AxiosResponse<Banner[]> = await axios.get<Banner[]>(
		// 	`${SERVICE_URLS.ASSETS}/assets/banner`
		// );

		// let position = 0;

		// banners.data.forEach((el) => {
		// 	if (el.position > position) {
		// 		position = el.position;
		// 	}
		// });

		// position++;

		// formData.append('position', position.toString());

		const res: AxiosResponse<Banner> = await axios.post(
			`${SERVICE_URLS.ASSETS}/assets/banner`,
			formData
		);
		return res.data;
	},
	async deleteBanner(id) {
		await axios.delete(`${SERVICE_URLS.ASSETS}/assets/banner/${id}`);
	},
};

export default BannersService;
