import { Close } from '@mui/icons-material';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { STUDENT_TYPE_LABELS } from '../../constants';
import { TNPSRecord } from '../../types';
import formatDate from '../../utils/formatDate';

interface Props {
	npsRecord: TNPSRecord | null;
	isOpen: boolean;
	onClose: () => any;
}

export default function CheckNPSModal({ npsRecord, isOpen, onClose }: Props) {
	const onDialogClose = () => {
		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			maxWidth="md"
			PaperProps={{
				sx: {
					width: '100%',
				},
			}}
			onClose={onDialogClose}
		>
			<DialogTitle>
				<Grid container alignItems="center">
					<Grid item xs>
						Проверка ответа
					</Grid>
					<Grid item xs="auto">
						<Tooltip title="Закрыть окно">
							<IconButton edge="end" onClick={onDialogClose}>
								<Close />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md>
						<Stack spacing={0.5}>
							<Typography variant="subtitle2">Пользователь</Typography>
							<Typography variant="body2" textTransform="capitalize">
								{`ФИО: ${npsRecord?.firstName} ${npsRecord?.lastName}`}
							</Typography>
							{npsRecord?.phoneNumber && (
								<Typography variant="body2" textTransform="capitalize">
									{`Телефон: ${npsRecord?.phoneNumber}`}
								</Typography>
							)}
							<Typography variant="body2">{`Почта: ${npsRecord?.email}`}</Typography>
							<Typography variant="body2">{`Тип студента: ${
								STUDENT_TYPE_LABELS[npsRecord?.studentType ?? '']
							}`}</Typography>
							<Typography variant="body2">
								{`Профессия: ${npsRecord?.title}`}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={4} md>
						<Stack spacing={0.5}>
							<Typography variant="subtitle2">Дата</Typography>
							<Typography variant="body2">
								{npsRecord &&
									formatDate(npsRecord.date, {
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
									})}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={2} md>
						<Stack spacing={0.5}>
							<Typography variant="subtitle2">Оценка</Typography>
							<Typography variant="body2">{npsRecord?.score}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack spacing={0.5}>
							<Typography variant="subtitle2">Комментарий</Typography>
							<Typography variant="body2">
								{npsRecord?.commentary ?? '-'}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
