import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import Loader from '../../components/Loader';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import CoursesService from '../../services/CoursesService';
import { PostCourse } from '../../types';
import CourseForm from '../../views/forms/CourseForm';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function EditCoursePage() {
  const { id } = useParams();

  const isUpdate = id !== undefined;

  const { data: course, isLoading } = useQuery(
    ['course', id],
    () => CoursesService.getCourse(id ?? ''),
    { enabled: isUpdate, refetchOnWindowFocus: false }
  );

  useSetHeaderTitle(
    isUpdate ? 'Редактирование курса' : 'Новый курс',
    undefined,
    '/curriculum/courses'
  );

  const formValues = useMemo<Partial<PostCourse> | undefined>(() => {
    if (!isUpdate || !course) {
      return;
    }

    return {
      title: course.title,
      subtitle: course.subtitle,
      description: course.description,
      coverImage: course.coverImage,
      startDate: new Date(course.startDate),
      endDate: new Date(course.endDate),
      isActive: course.isActive,
      type: course.type,
      certificate: course.certificate,
      common: course.common,
    } as Partial<PostCourse>;
  }, [isUpdate, course]);

  return (
    <PageContainer>
      {isUpdate && isLoading ? (
        <Loader />
      ) : (
        <CourseForm defaultValues={formValues} isUpdate={isUpdate} />
      )}
    </PageContainer>
  );
}

export default withWhitelistRoles(EditCoursePage, ['admin']);
