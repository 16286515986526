import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import Loader from '../../components/Loader';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import UnitsService from '../../services/UnitsService';
import { PostUnit } from '../../types/units';
import UnitForm from '../../views/forms/UnitForm';

function NewUnitPage() {
	const { id } = useParams();

	const isUpdate = id !== undefined;

	const { data: unit, isLoading } = useQuery(
		['unit', id],
		() => UnitsService.getUnit(id ?? ''),
		{ enabled: isUpdate }
	);

	useSetHeaderTitle(
		isUpdate ? 'Редактирование блока' : 'Новый блок',
		undefined,
		'/curriculum/units'
	);

	const formValues = useMemo<Partial<PostUnit> | undefined>(() => {
		if (!isUpdate || !unit) {
			return;
		}

		return {
			title: unit.title,
			position: unit.position,
			startDate: new Date(unit.startDate),
			endDate: new Date(unit.endDate),
			submissionLimit: unit.submissionLimit,
		} as Partial<PostUnit>;
	}, [isUpdate, unit]);

	return (
		<DashboardPageContainer>
			{isUpdate && isLoading ? (
				<Loader />
			) : (
				<UnitForm isUpdate={isUpdate} defaultValues={formValues} />
			)}
		</DashboardPageContainer>
	);
}

export default withWhitelistRoles(NewUnitPage, ['admin']);
