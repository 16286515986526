import {Grid, styled} from "@mui/material";

const MainContainer = styled(Grid)(({theme}) => ({
            border: '1px solid lightgrey',
            padding: '12px 18px',
            borderRadius: '15px',
            height: '300px',
            '&:hover': {
                boxShadow: 'inset 0 0 0 3px lightgrey',
                borderColor: 'lightgrey',
            },
            'span': {
               opacity: 0.7
            },
            'canvas': {
                maxHeight: '-webkit-fill-available',
                width: '100%'
            },
        }
    )
);

export default MainContainer;