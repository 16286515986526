import { styled } from '@mui/material';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import { useAppSelector } from '../../store';
import LessonsList from '../../views/LessonsPage/LessonsList';
import LessonsPageHeader from '../../views/LessonsPage/LessonsPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function LessonsPage() {
  const { program, course, unit } = useAppSelector((state) => state.filters);
  useSetHeaderTitle(
    'Уроки',
    course.value &&
      unit.value &&
      `${program?.value?.title ? `${program.value.title} - ` : ''}${
        course.value.title
      } - ${unit.value.title}`
  );
  return (
    <PageContainer>
      <LessonsPageHeader />

      <LessonsList />
    </PageContainer>
  );
}

export default withWhitelistRoles(LessonsPage, ['admin']);
