import axios, { AxiosResponse } from 'axios';
import { SERVICE_URLS } from '../constants';
import { PostUser, Program, Role, User } from '../types';

interface BatchUploadResult {
  erroredUsers: User[];
}

const UsersService = {
  async batchUpload(file: File) {
    const formData = new FormData();

    formData.append('users', file);

    const res = await axios.post<BatchUploadResult>(
      `${SERVICE_URLS.PROFILE}/iam/profile/excel`,
      formData
    );

    return res.data;
  },
  async getUsers(
    page = 0,
    size = 10,
    options?: {
      isActive?: boolean;
      role?: string;
      query?: string;
      program?: string;
      course?: string;
    }
  ) {
    const res: AxiosResponse<{ count: number; users: User[] }> =
      await axios.get(`${SERVICE_URLS.PROFILE}/iam/profiles`, {
        params: {
          page,
          size,

          ...(options?.isActive !== undefined && {
            is_active: options.isActive,
          }),

          ...(options?.query && {
            search: options.query,
          }),

          ...(options?.course
            ? {
                group_type: 'COURSE',
                group_id: options.course,
              }
            : options?.program && {
                group_type: 'CURRICULUM',
                group_id: options.program,
              }),
        },
      });

    return res.data;
  },
  async setDefaultPassword(id:string){
  const res = await axios.post(
      `${SERVICE_URLS.PROFILE}/iam/profile/resetPassword/${id}`
  )
    return res.data;
  },
  async getUser(id: string) {
    const res: AxiosResponse<User> = await axios.get(
      `${SERVICE_URLS.PROFILE}/iam/profile/${id}`
    );

    return res.data;
  },
  async createUser({ studentProgram, ...userData }: PostUser) {
    const res: AxiosResponse<User> = await axios.post(
      `${SERVICE_URLS.PROFILE}/iam/profile`,
      userData
    );

    await this.setRole(res.data.id, userData.role);

    if (studentProgram) {
      for (const program of studentProgram) {
        await axios.put(
          `${SERVICE_URLS.CURRICULUM}/curriculum/flow/${program.id}/attendee/${res.data.id}/admit`
        );
      }

      // await axios.put(
      //   `${SERVICE_URLS.CURRICULUM}/curriculum/flow/${studentProgram.id}/attendee/${userId}/admit`
      // );
    }

    return res.data;
  },
  async updateUser(
    userId: string,
    { studentProgram, ...userData }: PostUser,
    allPrograms: Program[] = []
  ) {
    const res: AxiosResponse<User> = await axios.patch(
      `${SERVICE_URLS.PROFILE}/iam/profile/${userId}`,
      userData
    );

    const excludedPrograms = allPrograms.filter(
      (program) => !studentProgram?.some((p) => p.id === program.id)
    );

    if (studentProgram) {
      for (const program of excludedPrograms) {
        await axios.put(
          `${SERVICE_URLS.CURRICULUM}/curriculum/flow/${program.id}/attendee/${userId}/expel`
        );
      }

      for (const program of studentProgram) {
        await axios.put(
          `${SERVICE_URLS.CURRICULUM}/curriculum/flow/${program.id}/attendee/${userId}/admit`
        );
      }

      // await axios.put(
      //   `${SERVICE_URLS.CURRICULUM}/curriculum/flow/${studentProgram.id}/attendee/${userId}/admit`
      // );
    }

    return res.data;
  },
  async deleteUser(userId: string) {
    const res: AxiosResponse<User> = await axios.delete(
      `${SERVICE_URLS.PROFILE}/iam/profile/${userId}`
    );

    return res.data;
  },
  async getRoles() {
    const url = `${SERVICE_URLS.ACL}/iam/acl/roles`;

    const res = await axios.get<Role[]>(url);

    return res.data;
  },
  async setRole(userId: string, roleId?: string) {
    if (!roleId) {
      return;
    }

    const url = `${SERVICE_URLS.ACL}/iam/acl`;

    await axios.post(url, {
      agent: userId,
      roles: [roleId],
    });
  },
  async getUserRoles(userId: string) {
    const url = `${SERVICE_URLS.ACL}/iam/acl/agent/${userId}`;

    const res = await axios.get<{
      roles: Role[];
    }>(url);

    return res.data.roles;
  },
  async getUserPrograms(userId: string) {
    const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activities/user/${userId}`;

    const res = await axios.get<Program[]>(url);

    return res.data;
  },
};

export default UsersService;
