import {
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	InputProps,
	Select as MuiSelect,
	SelectProps,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';

interface FieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	children?: ReactNode;
	render?: any;
	label?: string;
	selectProps?: SelectProps;
	formControlProps?: FormControlProps;
}

const Select = forwardRef<HTMLDivElement, FieldProps>(
	({ label, formControlProps, selectProps, ...props }, ref) => {
		return (
			<Controller
				{...props}
				name={props.name}
				control={props.control}
				render={({ field, fieldState }) => (
					<FormControl
						{...field}
						{...formControlProps}
						error={Boolean(fieldState.error)}
						ref={ref}
					>
						<FormLabel htmlFor={selectProps?.id ?? props.name} sx={{ mb: 1 }}>
							{label}
						</FormLabel>

						<MuiSelect
							{...selectProps}
							value={field.value ?? ''}
							size={selectProps?.size ?? 'small'}
							onChange={(event) => field.onChange(event)}
						>
							{props.children}
						</MuiSelect>

						{fieldState.error && (
							<FormHelperText>{fieldState.error.message}</FormHelperText>
						)}
					</FormControl>
				)}
			/>
		);
	}
);

export default Select;
