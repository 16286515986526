import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RemoveTrailingSlash } from './components/RemoveTrailingSlash';
import GlobalStyles from './GlobalStyles';
import AppRoutes from './routes';
import store from './store';
import theme from './theme';
import { ToastContextProvider } from './contexts/ToastContext';
import ruLocale from 'date-fns/locale/ru';
import { ConfirmDialogContextProvider } from './contexts/ConfirmDialogContext';

const queryClient = new QueryClient();

function App() {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
			<HelmetProvider>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<ThemeProvider theme={theme}>
							<BrowserRouter>
								<ToastContextProvider>
									<ConfirmDialogContextProvider>
										<div className="App">
											<CssBaseline />
											<GlobalStyles />
											<RemoveTrailingSlash />
											<AppRoutes />
										</div>
									</ConfirmDialogContextProvider>
								</ToastContextProvider>
							</BrowserRouter>
						</ThemeProvider>
					</Provider>
				</QueryClientProvider>
			</HelmetProvider>
		</LocalizationProvider>
	);
}

export default App;
