import { Action, Middleware, Store } from '@reduxjs/toolkit';
import { closeSidebar, openSidebar, toggleSidebar } from './appSlice';

export const sideMenuMiddleware: Middleware = (store) => (next) => (action) => {
	if (openSidebar.match(action)) {
		localStorage.setItem('isSidebarOpen', 'true');
	} else if (closeSidebar.match(action)) {
		localStorage.setItem('isSidebarOpen', 'false');
	} else if (toggleSidebar.match(action)) {
		const currentItem = JSON.parse(
			localStorage.getItem('isSidebarOpen') ?? 'true'
		);

		localStorage.setItem('isSidebarOpen', JSON.stringify(!currentItem));
	}

	return next(action);
};
