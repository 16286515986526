import { Area } from 'react-easy-crop';

const MAX_SIZE = 2160 * 3840;

export async function getCroppedImage(
	image: HTMLImageElement,
	crop: Area,
	fileName: string
): Promise<File> {
	const canvas = document.createElement('canvas');
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	canvas.width = crop.width;
	canvas.height = crop.height;
	const ctx: CanvasRenderingContext2D =
		canvas.getContext('2d') ?? new CanvasRenderingContext2D();

	// New lines to be added
	// const pixelRatio = window.devicePixelRatio;
	const pixelRatio = 1;
	canvas.width = crop.width * pixelRatio;
	canvas.height = crop.height * pixelRatio;
	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
	ctx.imageSmoothingQuality = 'high';

	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width,
		crop.height
	);

	// As Base64 string
	// const base64Image = canvas.toDataURL("image/jpeg");
	// return base64Image;

	// As a blob
	const blob: Blob = await new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob) => {
				if (!blob) {
					reject();
					return;
				}

				resolve(blob);
			},
			'image/jpeg',
			// 1
			Math.min(1, MAX_SIZE / (crop.width * crop.height))
		);
	});

	const file = new File([blob], fileName, { type: 'image/jpeg' });

	return file;
}
