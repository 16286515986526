import { styled } from '@mui/material';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DashboardPageContainer from '../components/DashboardPageContainer';
import Loader from '../components/Loader';
import withWhitelistRoles from '../components/withWhitelistRoles';
import useFileFromURL from '../hooks/useFileFromURL';
import useSetHeaderTitle from '../hooks/useSetHeaderTitle';
import BannersService from '../services/BannersService';
import { PostBanner } from '../types';
import BannerForm from '../views/forms/BannerForm';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function EditBannerPage() {
	const { id } = useParams();

	const isUpdate = id !== undefined;

	const { data: banner, isLoading } = useQuery(
		['banner', id],
		() => BannersService.getBanner(id ?? ''),
		{ enabled: isUpdate, refetchOnWindowFocus: false }
	);

	useSetHeaderTitle(
		isUpdate ? 'Редактирование баннера' : 'Новый баннер',
		undefined,
		'/banners'
	);

	// const [defaultFile, isLoadingFile] = useFileFromURL(
	// 	banner?.file,
	// 	'banner-image'
	// );

	const formValues = useMemo<Partial<PostBanner> | undefined>(() => {
		if (!isUpdate || !banner) {
			return;
		}

		return {
			name: banner.name,
			description: banner.description,
			isActive: banner.isActive,
			position: banner.position,
			targetUrl: banner.targetUrl,
			file: banner.file,
		} as Partial<PostBanner>;
	}, [isUpdate, banner]);

	return (
		<PageContainer>
			{isUpdate && isLoading ? (
				<Loader />
			) : (
				<BannerForm isUpdate={isUpdate} defaultValues={formValues} />
			)}
		</PageContainer>
	);
}

export default withWhitelistRoles(EditBannerPage, ['admin']);
