import axios from 'axios';
import { SERVICE_URLS } from '../constants';
import {
	PostQuiz,
	PostQuizAnswer,
	PostQuizQuestion,
	Quiz,
	QuizAnswer,
	QuizQuestion,
} from '../types';
import fillFormData from '../utils/fillFormData';

const QuizzesService = {
	async getQuiz(quizId: string): Promise<Quiz | undefined> {
		if (!quizId) {
			return;
		}

		const res = await axios.get<Quiz>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/quiz/${quizId}`
		);

		// if (res.data.length > 0) {
		// 	return res.data[0];
		// }

		return res.data;
	},
	async createQuiz(lessonId: string, data: PostQuiz): Promise<Quiz> {
		const res = await axios.post<Quiz>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/lesson/${lessonId}/quiz`,
			data
		);

		return res.data;
	},
	async updateQuiz(id: string, data: PostQuiz): Promise<Quiz> {
		const res = await axios.patch<Quiz>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/quiz/${id}`,
			data
		);

		return res.data;
	},
	async deleteQuiz(id: string): Promise<any> {
		await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/quiz/${id}`);
	},
	async createQuestion(
		quizId: string,
		{ answers, ...data }: PostQuizQuestion
	): Promise<QuizQuestion> {
		const formData = new FormData();

		fillFormData(formData, {
			text: data.question.text,
			...(data.question.assets.filter((el) => Boolean(el) && el instanceof File)
				.length > 0 && {
				assets: data.question.assets,
			}),
		});

		const res = await axios.post<QuizQuestion>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/quiz/${quizId}/entry`,
			formData
		);

		return res.data;
	},
	async updateQuestion(
		id: string,
		{ answers, ...data }: PostQuizQuestion
	): Promise<QuizQuestion> {
		const formData = new FormData();

		fillFormData(formData, {
			text: data.question.text,
			...(data.question.assets.filter((el) => Boolean(el) && el instanceof File)
				.length > 0 && {
				assets: data.question.assets,
			}),
		});

		const res = await axios.patch<QuizQuestion>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/entry/${id}`,
			formData
		);

		return res.data;
	},
	async deleteQuestion(id: string): Promise<any> {
		await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/entry/${id}`);
	},
	async createAnswer(
		questionId: string,
		{ assets, ...data }: PostQuizAnswer
	): Promise<QuizAnswer> {
		const formData = new FormData();

		fillFormData(formData, {
			text: data.text,
			isCorrect: data.isCorrect,
			...(assets.filter((el) => Boolean(el) && el instanceof File).length >
				0 && {
				assets,
			}),
		});

		const res = await axios.post<QuizAnswer>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/entry/${questionId}/answer`,
			formData
		);

		return res.data;
	},
	async updateAnswer(
		id: string,
		{ assets, ...data }: PostQuizAnswer
	): Promise<QuizAnswer> {
		const formData = new FormData();

		fillFormData(formData, {
			text: data.text,
			isCorrect: data.isCorrect,
			...(assets &&
				assets.filter((el) => Boolean(el) && el instanceof File).length > 0 && {
					assets,
				}),
		});

		const res = await axios.patch<QuizAnswer>(
			`${SERVICE_URLS.CURRICULUM}/curriculum/answer/${id}`,
			formData
		);

		return res.data;
	},
	async deleteAnswer(id: string): Promise<any> {
		await axios.delete(`${SERVICE_URLS.CURRICULUM}/curriculum/answer/${id}`);
	},
};

export default QuizzesService;
