export default function sortByPosition(arr: Array<any>) {
	arr.sort((a, b) => {
		if (a.position > b.position) {
			return 1;
		}

		if (a.position < b.position) {
			return -1;
		}

		return 0;
	});

	return arr;
}
