import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Container, Paper, Stack, styled } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import TextField from '../components/material/TextField';
import Typography from '../components/material/Typography';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import useToast from '../hooks/useToast';
import AuthService from '../services/AuthService';
import { useAppDispatch } from '../store';
import { setIsSignedIn } from '../store/appSlice';
import formatTitle from '../utils/formatTitle';
import getTextFieldErrorProps from '../utils/getTextFieldErrorProps';

const PageContainer = styled(Box)(({ theme }) => ({
	width: '100vw',
	height: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

interface FormValues {
	email: string;
	password: string;
}

export default function SignInPage() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toast = useToast();

	const signIn = useMutation(
		'signIn',
		({ email, password }: { email: string; password: string }) =>
			AuthService.signIn(email.trim(), password.trim()),
		{
			onError(error: AxiosError, variables, context) {
				let errorMessage = DEFAULT_ERROR_MESSAGE;

				if (
					error?.response &&
					error.response?.status >= 400 &&
					error.response?.status < 500
				) {
					errorMessage = 'Неверное имя пользователя или пароль';
				}

				toast(errorMessage);
			},
			onSuccess(data, variables, context) {
				dispatch(setIsSignedIn(true));
				navigate('/users');
			},
		}
	);

	const { isLoading } = signIn;

	const onSubmit = (data: FormValues) => {
		if (isLoading) {
			return;
		}

		signIn.mutate({ email: data.email, password: data.password });
	};

	return (
		<PageContainer>
			<Helmet>
				<title>{formatTitle('Вход')}</title>
			</Helmet>
			<Container maxWidth="xs">
				<Paper sx={{ p: 2, pt: 4 }}>
					<Stack
						spacing={4}
						alignItems="stretch"
						component="form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={2}
						>
							<Avatar src="/images/logo.png" />
							<Typography variant="h1">Поток</Typography>
						</Stack>

						<Stack spacing={2}>
							<TextField
								separateLabel
								type="email"
								label="Email"
								name="email"
								placeholder="example@mail.com"
								inputProps={{
									...register('email', { required: 'Введите email' }),
								}}
								disabled={isLoading}
								{...getTextFieldErrorProps(errors.email)}
							/>

							<TextField
								separateLabel
								type="password"
								label="Пароль"
								name="password"
								placeholder="**********"
								inputProps={{
									...register('password', { required: 'Введите пароль' }),
								}}
								disabled={isLoading}
								{...getTextFieldErrorProps(errors.password)}
							/>
							<LoadingButton
								variant="contained"
								type="submit"
								loading={isLoading}
							>
								Войти
							</LoadingButton>
						</Stack>
					</Stack>
				</Paper>
			</Container>
		</PageContainer>
	);
}
