import { Box, Button, Stack, styled } from '@mui/material';
import { ReactNode, useState } from 'react';
import {
	DragDropContext,
	DragStart,
	Droppable,
	DropResult,
	ResponderProvided,
} from 'react-beautiful-dnd';
import AbsoluteCoverContainer from './AbsoluteCoverContainer';
import Typography from './material/Typography';
import SelectionStateRow, { SelectionAction } from './SelectionStateRow';

interface Props {
	items: Array<any>;
	droppableId: string;
	selectionActions?: Array<SelectionAction>;
	emptyMessage?: string;
	renderItem: (
		value: any,
		index: number,
		options: {
			selection: Array<string>;
			toggleSelection: (id: string) => void;
		}
	) => ReactNode;
	onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
	onChangeSave: () => void;
	onChangeCancel: () => void;
	onDragStart?: (initial: DragStart, provided: ResponderProvided) => void;
}

export interface DraggableListItemSelection {
	selection: Array<string>;
	toggleSelection: (id: string) => void;
}

const ListContainer = styled(Box)(({ theme }) => ({
	'.droppable-stack': {
		position: 'relative',
		background: '#fff',
		padding: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
	},
}));

/* 
	A draggable list component that expects an items array of Draggable's
*/
export default function DraggableList({
	droppableId,
	selectionActions,
	items,
	emptyMessage,
	renderItem,
	onDragStart,
	onDragEnd,
	onChangeSave,
	onChangeCancel,
}: Props) {
	const [selection, setSelection] = useState<Array<any>>([]);
	const [isOrderChanged, setIsOrderChanged] = useState(false);

	const _onDragEnd = (result: DropResult, provided: ResponderProvided) => {
		onDragEnd(result, provided);
	};

	const _onDragStart = (initial: DragStart, provided: ResponderProvided) => {
		if (!isOrderChanged) {
			setIsOrderChanged(true);
		}

		if (onDragStart) {
			onDragStart(initial, provided);
		}
	};

	const _onChangeSave = () => {
		setIsOrderChanged(false);

		onChangeSave();
	};

	const _onChangeCancel = () => {
		setIsOrderChanged(false);

		onChangeCancel();
	};

	const toggleSelection = (id: string) => {
		if (selection.includes(id)) {
			setSelection((prev) => prev.filter((currentId) => currentId !== id));
			return;
		}

		setSelection((prev) => [...prev, id]);
	};

	const onClearSelection = () => setSelection([]);

	return (
		<ListContainer>
			{selectionActions && (
				<SelectionStateRow
					actions={selectionActions}
					clearSelection={onClearSelection}
					selection={selection}
				/>
			)}
			<DragDropContext onDragEnd={_onDragEnd} onDragStart={_onDragStart}>
				<Stack
					className="droppable-stack"
					spacing={2}
					sx={(theme) => ({
						minHeight: items.length === 0 ? theme.spacing(20) : 0,
					})}
				>
					{items.length === 0 && (
						<AbsoluteCoverContainer>
							<Typography color="text.secondary">
								{emptyMessage ?? 'Элементов нет'}
							</Typography>
						</AbsoluteCoverContainer>
					)}

					<Droppable droppableId={droppableId}>
						{(provided) => (
							<Stack
								spacing={2}
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{items.map((item, index) =>
									renderItem(item, index, { selection, toggleSelection })
								)}
								{provided.placeholder}
							</Stack>
						)}
					</Droppable>
					{isOrderChanged && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={2}
							className="state-control-row"
						>
							<Button
								variant="outlined"
								color="inherit"
								onClick={_onChangeCancel}
							>
								Отменить
							</Button>
							<Button variant="contained" onClick={_onChangeSave}>
								Сохранить
							</Button>
						</Stack>
					)}
				</Stack>
			</DragDropContext>
		</ListContainer>
	);
}
