import { HighlightOff } from '@mui/icons-material';
import {
	Button,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import ButtonDropdown from './ButtonDropdown';
import Typography from './material/Typography';

export interface SelectionAction {
	text: string;
	onClick: (items: Array<string>) => void;
	icon?: React.ReactNode;
	color?: string;
}

interface SelectionStateRowProps {
	selection: Array<string>;
	clearSelection: () => void;
	actions: Array<SelectionAction>;
}

export default function SelectionStateRow({
	selection,
	clearSelection,
	actions,
}: SelectionStateRowProps) {
	if (!selection || selection.length === 0) {
		return null;
	}

	return (
		<Grid container spacing={2} mb={1}>
			<Grid item container xs={12} sm spacing={2}>
				<Grid item xs sm="auto" sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography variant="body2">
						Выделено строк: {selection.length}
					</Typography>
				</Grid>
				<Grid
					item
					xs="auto"
					sm="auto"
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: { xs: 'flex-end', sm: 'flex-start' },
					}}
				>
					<Button
						color="inherit"
						size="small"
						variant="outlined"
						startIcon={<HighlightOff />}
						onClick={clearSelection}
					>
						Сбросить
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12} sm="auto" justifyContent="flex-end" display="flex">
				<ButtonDropdown
					buttonProps={{ fullWidth: true }}
					menuItems={actions.map((action, i) => (
						<MenuItem key={uuid()} onClick={() => action.onClick(selection)}>
							{action.icon && (
								<ListItemIcon color={action.color ?? 'inherit'}>
									{action.icon}
								</ListItemIcon>
							)}
							<ListItemText>
								<Typography color={action.color ?? 'inherit'} variant="body2">
									{action.text}
								</Typography>
							</ListItemText>
						</MenuItem>
					))}
				>
					Действия
				</ButtonDropdown>
			</Grid>
		</Grid>
	);
}
