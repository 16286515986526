import { Box, styled, Typography } from '@mui/material';
import DashboardPageContainer from '../../components/DashboardPageContainer';
import withContext from '../../components/withContext';
import withWhitelistRoles from '../../components/withWhitelistRoles';
import { CoursesPageContextProvider } from '../../contexts/CoursesPageContext';
import useSetHeaderTitle from '../../hooks/useSetHeaderTitle';
import { useAppSelector } from '../../store';
import CommmonCoursesGrid from '../../views/CoursesPage/CommonCoursesGrid';
import CoursesGrid from '../../views/CoursesPage/CoursesGrid';
import CoursesPageHeader from '../../views/CoursesPage/CoursesPageHeader';

const PageContainer = styled(DashboardPageContainer)(({ theme }) => ({}));

function CoursesPage() {
  const program = useAppSelector((state) => state.filters.program);
  useSetHeaderTitle('Курсы', program.value && program.value.title);

  return (
    <PageContainer
      sx={{
        minHeight: '100vh',
      }}
    >
      <CoursesPageHeader />
      <CoursesGrid />

      <Box minHeight={400} mt={4}>
        <Typography variant="h5" mb={2}>
          Общие курсы
        </Typography>
        <CommmonCoursesGrid />
      </Box>
    </PageContainer>
  );
}

export default withWhitelistRoles(
  withContext(CoursesPage, CoursesPageContextProvider),
  ['admin']
);
