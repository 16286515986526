import { createTheme, responsiveFontSizes } from '@mui/material';
import { ruRU } from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';

let theme = createTheme(
	{
		palette: {
			primary: {
				main: '#5aace7',
			},
			secondary: {
				main: '#212631',
			},
			error: {
				main: '#ef5348',
			},
			background: {
				default: '#F8F8F8',
			},
			common: {
				black: '#1B1C1E',
				white: '#F8F8F8',
			},
		},
		typography: {
			fontFamily: 'Rubik, sans-serif',
			h1: {
				fontSize: '1.625rem',
				fontWeight: 700,
			},
			h2: {
				fontSize: '1.5rem',
				fontWeight: 700,
			},
			h5: {
				fontWeight: 700,
			},
			h6: {
				fontWeight: 700,
				fontSize: '1rem',
			},
		},
		shape: {
			borderRadius: 5,
		},
		components: {
			MuiFormControlLabel: {
				styleOverrides: {
					root: ({ theme }) => ({
						fontWeight: 600,
						'&:not(.MuiInputLabel-root):not(.regular):not(.Mui-disabled)': {
							'.MuiFormControlLabel-label': {
								color: theme.palette.text.primary,
							},
						},
					}),
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: ({ theme }) => ({
						fontWeight: 600,
						'&:not(.MuiInputLabel-root):not(.regular):not(.Mui-disabled)': {
							color: theme.palette.text.primary,
						},
					}),
				},
			},
			MuiPaginationItem: {
				styleOverrides: {
					root: ({ theme }) => ({
						lineHeight: 1,
						'&.Mui-selected': {
							backgroundColor: theme.palette.common.black,
							color: theme.palette.common.white,
						},
					}),
				},
			},
			MuiFab: {
				styleOverrides: {
					root: ({ theme }) => ({
						'&.MuiFab-colorInherit': {
							backgroundColor: '#fff',
							boxShadow: theme.shadows[3],
							transition: theme.transitions.create(['opacity'], {
								duration: theme.transitions.duration.short,
							}),

							'&.header-button': {
								opacity: 0.7,
							},

							'&:hover': {
								opacity: 1,
							},
						},
					}),
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: ({ theme }) => ({
						[theme.breakpoints.up(600)]: {
							paddingLeft: theme.spacing(2),
							paddingRight: theme.spacing(2),
						},
					}),
				},
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						verticalAlign: 'middle',
					},
				},
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: ({ theme }) => ({
						minWidth: theme.spacing(5),
					}),
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: ({ theme }) => ({
						opacity: 0.4,
						whiteSpace: 'nowrap',

						'&:not(.nested)': {
							'&.Mui-selected': {
								background: 'none',
								opacity: 1,
							},

							'.MuiListItemText-root': {
								marginTop: 6,

								'.MuiTypography-root': {
									fontWeight: 500,
								},
							},
						},

						'&.nested': {
							padding: theme.spacing(1, 2),
							borderRadius: theme.shape.borderRadius,

							'&.Mui-selected': {
								opacity: 1,
								background: '#ffffff16',
							},
						},
					}),
				},
			},
			MuiButton: {
				styleOverrides: {
					root: ({ theme }) => ({
						textTransform: 'none',
						whiteSpace: 'nowrap',
						fontWeight: 500,

						'&.MuiButton-sizeMedium': {
							minWidth: 38,
							paddingTop: 7,
							paddingBottom: 7,
						},
					}),
					outlined: ({ theme }) => ({
						'&.MuiButton-outlinedInherit': {
							backgroundColor: '#fff',
							borderColor: theme.palette.action.disabled,
						},

						'&.MuiButton-outlinedError': {
							backgroundColor: '#fff',
							borderColor: theme.palette.error.light,
						},
					}),
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: ({ theme }) => ({
						backgroundColor: '#fff',
					}),
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: ({ theme }) => ({
						'&.dropdown-menu-item': {
							color: theme.palette.text.disabled,

							'&.Mui-selected': {
								background: 'none',
								color: theme.palette.text.primary,

								'&:hover': {
									background: theme.palette.action.hover,
								},
							},
						},
					}),
				},
			},
			MuiListSubheader: {
				styleOverrides: {
					root: ({ theme }) => ({
						opacity: 1,

						'.MuiListItemText-root': {
							color: theme.palette.text.primary,

							'.MuiTypography-root': {
								fontSize: '1rem',
								fontWeight: 500,
							},
						},
					}),
				},
			},
			MuiDataGrid: {
				styleOverrides: {
					root: ({ theme }) => ({
						border: 'none',
						backgroundColor: '#fff',
					}),
				},
			},
			MuiChip: {
				styleOverrides: {
					root: ({ theme }) => ({
						'&.chip-id': {
							fontWeight: 600,
							backgroundColor: theme.palette.primary.main + '25',
							color: theme.palette.primary.main,
						},
					}),
				},
			},
		},
	},
	ruRU
);

theme = responsiveFontSizes(theme);

export default theme;
