import { Outlet } from 'react-router-dom';
import withAuth from '../components/withAuth';

function AuthLayout() {
	return (
		<div>
			<Outlet />
		</div>
	);
}

export default withAuth(AuthLayout, false, true);
