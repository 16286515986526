import { Box, Stack, styled } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { useMutation } from 'react-query';
import AbsoluteCoverContainer from '../../components/AbsoluteCoverContainer';
import Typography from '../../components/material/Typography';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import BannersService from '../../services/BannersService';
import { Banner } from '../../types';
import BannersControlItem from './BannersControlItem';

interface Props {
	title?: string;
	droppableId: string;
	onBannerImageClick: (src: string) => void;
	banners: Banner[];
	isDisabled: boolean;
	refetch: () => any;
}

const Container = styled(Box)(({ theme }) => ({
	background: '#fff',
	padding: theme.spacing(2, 2, 0),
	borderRadius: theme.shape.borderRadius,
	border: `1px solid ${theme.palette.divider}`,
	minHeight: theme.spacing(30),
	position: 'relative',
}));

export default function BannersControlRow({
	title,
	droppableId,
	banners,
	onBannerImageClick,
	isDisabled,
	refetch,
}: Props) {
	const withConfirm = useConfirmDialog();

	const deleteBannerMutation = useMutation(
		'deleteBanner',
		(id: string) => BannersService.deleteBanner(id),
		{
			onSuccess(data, variables, context) {
				refetch();
			},
		}
	);

	const onBannerDeleteClick = (id: string) => () => {
		withConfirm({
			title: 'Удалить баннер?',
			cancelButtonText: 'Отмена',
			confirmButtonText: 'Удалить',
			onConfirm: () => deleteBannerMutation.mutate(id),
		});
	};

	return (
		<Box>
			{title && (
				<Typography variant="h2" mb={2}>
					{title}
				</Typography>
			)}
			<Container>
				{banners.length === 0 && (
					<AbsoluteCoverContainer>
						<Typography color="text.secondary">Баннеров нет</Typography>
					</AbsoluteCoverContainer>
				)}
				<Droppable droppableId={droppableId} direction="horizontal">
					{(provided, snapshot) => (
						<Stack
							spacing={2}
							direction="row"
							{...provided.droppableProps}
							ref={provided.innerRef}
							mb={2}
							maxWidth="100%"
							overflow="auto"
							pb={2}
						>
							{banners.map((banner, i) => (
								<BannersControlItem
									key={`banner-${banner.id}`}
									banner={banner}
									index={i}
									onBannerImageClick={onBannerImageClick}
									onDeleteButtonClick={onBannerDeleteClick(banner.id)}
									isDisabled={isDisabled || deleteBannerMutation.isLoading}
								/>
							))}

							{provided.placeholder}
						</Stack>
					)}
				</Droppable>
			</Container>
		</Box>
	);
}
