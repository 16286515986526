import axios from 'axios';
import { SERVICE_URLS } from '../constants';
import {AssignmentSubmission, TNPSRecord} from '../types';
import {NPSActivityProps} from "../views/UserActivityPage/NPSActivity";

const UserActivityService = {
    async getAllSubmissionsByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/assignment/submissions/all/${userId}`;

        const res = await axios.get<AssignmentSubmission[]>(url);

        return res.data;
    },

    async getAllNPSSubmissionsByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/nps/submission/${userId}`;

        const res = await axios.get<NPSActivityProps[] | undefined>(url);

        return res.data;
    },

    async getAllQuizSubmissionsByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/lesson/quizzes/${userId}`;

        const res = await axios.get<any[]>(url);

        return res.data;
    },

    async getAllSelfAssignmentSubmissionsByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/selfassignment/submissions/user/${userId}`;

        const res = await axios.get<any[]>(url);

        return res.data;
    },

    async getAllCertificateInfoByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/certificates/user/${userId}`;

        const res = await axios.get<any[]>(url);

        return res.data;
    },

    async getAllCourseInfoByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/course/user/${userId}`;

        const res = await axios.get<any[]>(url);

        return res.data;
    },

    async getAllCurriculumsInfoByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activities/user/${userId}`;

        const res = await axios.get<any[]>(url);

        return res.data;
    },

    async getAllLessonsInfoByUserId(userId: string) {
        const url = `${SERVICE_URLS.CURRICULUM}/curriculum/activity/lesson/user/${userId}`;

        const res = await axios.get<any[]>(url);

        return res.data;
    },

    getNPSForUnit: async (unitId: string) => {
        const res = await axios.get<{ users: TNPSRecord[] }>(
            `${SERVICE_URLS.CURRICULUM}/curriculum/nps/unit/${unitId}`
        );
        return res.data.users;
    },
};

export default UserActivityService;
