import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { MouseEventHandler, ReactNode, useState } from 'react';
import { v4 as uuid } from 'uuid';

export interface ContextMenuAction {
	icon?: ReactNode;
	label: string;
	onClick: (itemId: string) => void;
}

/* 
    Creates a MUI context menu and allows to set the anchor element
*/
export default function useContextMenu(
	actions: ContextMenuAction[],
	selectedItemId: string
) {
	const [anchorPosition, setAnchorPosition] = useState<
		{ top: number; left: number } | undefined
	>(undefined);

	const onClose = () => {
		setAnchorPosition(undefined);
	};

	const onActionClick = (action: ContextMenuAction) => {
		return () => {
			action.onClick(selectedItemId);
			onClose();
		};
	};

	const isOpen = Boolean(anchorPosition);

	const menu = (
		<Menu
			open={isOpen}
			onClose={onClose}
			anchorReference="anchorPosition"
			anchorPosition={isOpen ? anchorPosition : undefined}
		>
			{actions.map((action) => (
				<MenuItem key={uuid()} onClick={onActionClick(action)}>
					{action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
					<ListItemText>{action.label}</ListItemText>
				</MenuItem>
			))}
		</Menu>
	);

	return { menu, setAnchorPosition };
}
