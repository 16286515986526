import { Close } from '@mui/icons-material';
import {
	Box,
	Dialog,
	DialogContent,
	Fab,
	IconButton,
	styled,
} from '@mui/material';

const ModalContainer = styled(Dialog)(({ theme }) => ({
	'.MuiDialog-paper': {
		width: '100%',
		margin: theme.spacing(2),
		maxWidth: 1100,
		overflow: 'visible',
	},

	'.MuiDialogContent-root': {
		padding: 0,
		lineHeight: 0,
	},

	'.close-button-container': {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),

		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(-6),
			right: theme.spacing(0),
		},
	},

	img: {
		width: '100%',
		objectFit: 'cover',
	},

	[theme.breakpoints.down(700 + 32)]: {
		'.MuiDialog-paper': {
			minWidth: 'initial',
			width: '100%',
		},
	},
}));

interface Props {
	src?: string;
	isOpen: boolean;
	onClose: () => void;
}

export default function ImageZoomModal({ src, isOpen, onClose }: Props) {
	return (
		<ModalContainer open={isOpen} onClose={onClose}>
			<Box className="close-button-container">
				<Fab size="small" onClick={onClose}>
					<Close />
				</Fab>
			</Box>
			<DialogContent>
				<img src={src} alt="zoomed" />
			</DialogContent>
		</ModalContainer>
	);
}
