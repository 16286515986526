import {Attachment, PostAttachment} from "../../../types";
import {Grid, IconButton, styled, Tooltip, Typography} from "@mui/material";
import {Check, RemoveCircleOutlineOutlined} from "@mui/icons-material";
import TextField from "../../../components/material/TextField";
import getTextFieldErrorProps from "../../../utils/getTextFieldErrorProps";
import FileUploadField from "../../../components/FileUploadField";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import {ATTACHMENT_TYPES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE} from "../../../constants";
import useToast from "../../../hooks/useToast";
import {useParams} from "react-router-dom";
import {useMutation} from "react-query";
import LessonsService from "../../../services/LessonsService";
import useConfirmDialog from "../../../hooks/useConfirmDialog";

const AttachmentsContainer = styled(Grid)(({theme}) => ({
    '.MuiButton-root.answer-submit, .MuiButton-root.question-submit': {
        maxWidth: 38,
        minHeight: 38,
        marginTop: theme.spacing(4.1),

        '.MuiTypography-root': {
            display: 'none',
        },

        [theme.breakpoints.up('sm')]: {
            '.MuiButton-endIcon': {
                marginLeft: theme.spacing(-0.5),
            },
        },

        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            maxWidth: 1000,

            '.MuiTypography-root': {
                display: 'block',
            },
        },
    },
}));

interface Props {
    attachment?: Attachment,
    attachments: Attachment[],
    index?: number,
    onDelete: (index: number | undefined) => void,
    onUpdate: (index: number | undefined, data: Attachment) => void,
    isUpdate: boolean,
    setAttachmentsList: any,
    closeModal: () => void
}
export default function AttachmentItem(
    {
        attachment,
        attachments,
        index,
        onDelete,
        onUpdate,
        isUpdate,
        closeModal,
        setAttachmentsList
    }: Props) {
    const toast = useToast();
    const {id} = useParams();
    const withConfirm = useConfirmDialog();

    const saveAttachmentMutation = useMutation(
        isUpdate ? 'updateAttachment' : 'createAttachment',
        (data: PostAttachment) =>
            isUpdate && attachment?.id
                ? LessonsService.updateAttachment(attachment.id, data)
                : LessonsService.createAttachment(id ?? '', data),
        {
            onError(error, variables, context) {
                toast(DEFAULT_ERROR_MESSAGE);
            },
            onSuccess(data, variables, context) {
                toast(DEFAULT_SUCCESS_MESSAGE, 'success');
                onUpdate(index, data);
                if (!isUpdate) {
                    const newAttachment = JSON.parse(JSON.stringify(data));
                    setAttachmentsList([...attachments, newAttachment])
                }
            },
        }
    );

    const deleteAttachmentMutation = useMutation(
        'deleteAttachment',
        () => LessonsService.deleteAttachment(attachment?.id ?? ''),
        {
            onError(error, variables, context) {
                toast(DEFAULT_ERROR_MESSAGE);
            },
            onSuccess(data, variables, context) {
                toast(DEFAULT_SUCCESS_MESSAGE, 'success');
                onDelete(index);
                setAttachmentsList((prevState: Attachment[]) => {
                    return prevState.filter((att: Attachment) => att.id !== attachment?.id)
                })
                attachments.length === 1 && closeModal();
            },
        }
    );

    const isLoading = saveAttachmentMutation.isLoading || deleteAttachmentMutation.isLoading;

    const {
        control,
        register,
        formState: {errors, isDirty},
        handleSubmit,
    } = useForm<PostAttachment>({
        defaultValues: {
            ...(attachment as unknown as PostAttachment),
        },
    });
    const onDeleteAttachment = () => {
        if (!attachments.some((att: Attachment) => att.id == attachment?.id)) {
            onDelete(index);
            return;
        }

        if (isLoading) {
            return;
        }

        withConfirm({
            title: 'Удалить материал?',
            onConfirm() {
                deleteAttachmentMutation.mutate();
            },
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отмена',
        });
    };

    const onSubmit = (data: PostAttachment) => {
        if (isLoading) {
            return;
        }
        data.type = ATTACHMENT_TYPES.DEFAULT;
        saveAttachmentMutation.mutate(data);
    };


    return (
        <Grid component="form" onSubmit={handleSubmit(onSubmit)} mb={3}>
            <AttachmentsContainer item container spacing={2}>
                <Grid item container xs={12} sm spacing={1}>
                    <Grid item xs="auto">
                        <IconButton
                            onClick={onDeleteAttachment}
                            sx={{mt: 4}}
                            edge="start"
                            disabled={isLoading}
                        >
                            <RemoveCircleOutlineOutlined/>
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            separateLabel
                            fullWidth
                            label="Название"
                            inputProps={{
                                ...register('title', {required: 'Введите название'}),
                            }}
                            {...getTextFieldErrorProps(errors.title)}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} lg={4}>
                    <FileUploadField
                        control={control}
                        name="file"
                        label="Файл"
                        formControlProps={{fullWidth: true, disabled: isLoading}}
                        rules={{required: isUpdate ? false : 'Загрузите файл'}}
                    />
                </Grid>

                <Grid item xs={12} sm={'auto'}>
                    <Tooltip title="Сохранить материал">
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="secondary"
                            loading={isLoading}
                            type="submit"
                            className="question-submit"
                            endIcon={<Check/>}
                        >
                            <Typography>Сохранить материал </Typography>
                        </LoadingButton>
                    </Tooltip>
                </Grid>
            </AttachmentsContainer>
        </Grid>
    )
}