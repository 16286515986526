import { AccessTime, CancelOutlined, Check } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';

type Props = ChipProps & {
	accepted?: boolean;
};

export default function SelfAssignmentStatusChip({
	accepted,
	...props
}: Props) {
	return (
		<Chip
			{...props}
			color={
				accepted === false ? 'error' : accepted === true ? 'success' : 'default'
			}
			label={
				accepted === false
					? 'Отклонено'
					: accepted === true
					? 'Принято'
					: 'На проверке'
			}
			icon={
				accepted === false ? (
					<CancelOutlined />
				) : accepted === true ? (
					<Check />
				) : (
					<AccessTime />
				)
			}
		/>
	);
}
