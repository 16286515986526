import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	MenuItem,
	Stack,
	ThemeProvider,
	Tooltip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
	DEFAULT_ERROR_MESSAGE,
	EMAIL_TYPES,
	EMAIL_TYPE_LABELS,
} from '../../constants';
import useToast from '../../hooks/useToast';
import ProgramsService from '../../services/ProgramsService';
import theme from '../../theme';
import { Program } from '../../types';
import AutocompleteField from '../AutocompleteField';
import Select from '../SelectField';

interface Props {
	isOpen: boolean;
	onClose: () => any;
}

interface FormValues {
	emailType: typeof EMAIL_TYPES[number];
	program?: Program;
}

export default function EmailDialog({ isOpen, onClose }: Props) {
	const { control, watch, reset, handleSubmit } = useForm<FormValues>();

	const emailType = watch('emailType');

	const toast = useToast();

	const onDialogClose = () => {
		onClose();
		reset({
			emailType: undefined,
			program: undefined,
		});
	};

	const sendCertificateMutation = useMutation(
		['sendCertificate'],
		ProgramsService.sendCertificates,
		{
			onError: () => {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess: () => {
				toast('Сертификаты успешно отправлены!', 'success');
				onDialogClose();
			},
		}
	);

	const { data: programs = [], isLoading: programsIsLoading } = useQuery(
		'programs',
		() => ProgramsService.getPrograms()
	);

	const isLoading = programsIsLoading || sendCertificateMutation.isLoading;

	const onSubmit = (values: FormValues) => {
		if (isLoading) return;

		if (values.emailType === 'COURSE_FINISH' && values.program) {
			sendCertificateMutation.mutate(values.program.id);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={isOpen}
				onClose={onDialogClose}
				maxWidth="sm"
				PaperProps={{
					sx: {
						width: '100%',
					},
				}}
			>
				<DialogTitle>
					<Grid container alignItems="center">
						<Grid item xs>
							Email Рассылка
						</Grid>
						<Grid item xs="auto">
							<Tooltip title="Закрыть окно">
								<IconButton edge="end" onClick={onDialogClose}>
									<Close />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Box
						component="form"
						id="emailForm"
						onSubmit={handleSubmit(onSubmit)}
					>
						<DialogContentText mb={2}>
							Для отправки email выберите тип письма
						</DialogContentText>

						<Stack spacing={2}>
							<Select
								control={control}
								name="emailType"
								label="Тип письма"
								formControlProps={{ fullWidth: true, disabled: isLoading }}
								rules={{ required: 'Пожалуйста, выберите тип письма' }}
							>
								{EMAIL_TYPES.map((el) => (
									<MenuItem key={`email-item-${el}`} value={el}>
										{EMAIL_TYPE_LABELS[el]}
									</MenuItem>
								))}
							</Select>

							{emailType === 'COURSE_FINISH' && (
								<>
									<AutocompleteField
										name="program"
										control={control}
										rules={{ required: 'Выберите программу' }}
										label="Программа"
										disabled={isLoading}
										formControlProps={{ fullWidth: true }}
										autocompleteProps={{
											getOptionLabel: (option: any) =>
												(option as Program).title,
											options: programs,
											loading: programsIsLoading,
										}}
									/>
								</>
							)}
						</Stack>
					</Box>
				</DialogContent>
				{emailType && (
					<DialogActions>
						<Button
							type="submit"
							variant="contained"
							color="secondary"
							form="emailForm"
						>
							Отправить
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</ThemeProvider>
	);
}
