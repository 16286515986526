import { Check, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import CheckboxField from '../../../components/CheckboxField';
import FileUploadField from '../../../components/FileUploadField';
import TextField from '../../../components/material/TextField';
import Typography from '../../../components/material/Typography';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../../constants';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import useToast from '../../../hooks/useToast';
import QuizzesService from '../../../services/QuizzesService';
import {
	PostQuizAnswer,
	QuizAnswer,
	QuizQuestion,
} from '../../../types/quizzes';
import getTextFieldErrorProps from '../../../utils/getTextFieldErrorProps';

interface Props {
	question: QuizQuestion;
	answer: QuizAnswer | PostQuizAnswer;
	index: number;
	questionIndex: number;
	disabled: boolean;
	onUpdate: (index: number, data: QuizAnswer) => any;
	onDelete: (index: number) => any;
}

export default function QuizFormAnswer({
	question,
	answer,
	index,
	questionIndex,
	onDelete,
	onUpdate,
	disabled,
}: Props) {
	const { id } = useParams();

	const toast = useToast();
	const withConfirm = useConfirmDialog();

	const isUpdate = (answer as QuizAnswer)?.id !== undefined;

	const saveAnswerMutation = useMutation(
		isUpdate ? 'updateAnswer' : 'createAnswer',
		(data: PostQuizAnswer) =>
			isUpdate
				? QuizzesService.updateAnswer((answer as QuizAnswer)?.id ?? '', data)
				: QuizzesService.createAnswer(question.id, data),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				onUpdate(index, data);
			},
		}
	);

	const deleteAnswerMutation = useMutation(
		'deleteAnswer',
		() => QuizzesService.deleteAnswer((answer as QuizAnswer)?.id),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				onDelete(index);
			},
		}
	);

	const isLoading = saveAnswerMutation.isLoading;

	const {
		control,
		register,
		formState: { errors, isDirty },
		handleSubmit,
	} = useForm<PostQuizAnswer>({
		defaultValues: {
			...(answer as PostQuizAnswer),
		},
	});

	const onDeleteClick = () => {
		if (!isUpdate) {
			onDelete(index);
			return;
		}

		if (isLoading) {
			return;
		}

		withConfirm({
			title: 'Удалить ответ?',
			onConfirm() {
				deleteAnswerMutation.mutate();
			},
			confirmButtonText: 'Удалить',
			cancelButtonText: 'Отмена',
		});
	};

	const onSubmit = (data: PostQuizAnswer) => {
		if (isLoading) {
			return;
		}

		saveAnswerMutation.mutate(data);
	};

	return (
		<Grid item container spacing={2} xs={12}>
			<Grid item container xs={12} sm spacing={1}>
				<Grid item xs="auto" minWidth={(theme) => theme.spacing(5)} />
				<Grid item xs="auto">
					<IconButton
						sx={{ mt: 4 }}
						edge="start"
						onClick={onDeleteClick}
						disabled={disabled || isLoading}
					>
						<RemoveCircleOutlineOutlined />
					</IconButton>
				</Grid>
				<Grid item xs>
					<TextField
						separateLabel
						label="Ответ"
						fullWidth
						inputProps={{
							...register('text', {
								required: 'Введите ответ',
							}),
						}}
						{...getTextFieldErrorProps(errors.text)}
						InputProps={{
							endAdornment: (
								<Tooltip title="Правильный">
									<Box>
										<CheckboxField
											control={control}
											label=""
											name={'isCorrect'}
											checkboxProps={{ color: 'success', edge: 'end' }}
										/>
									</Box>
								</Tooltip>
							),
						}}
						disabled={isLoading}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={5} lg={4}>
				<FileUploadField
					control={control}
					name={'assets.0'}
					label="Изображение"
					formControlProps={{ fullWidth: true, disabled: isLoading }}
				/>
			</Grid>
			{(isDirty || !(answer as QuizAnswer)?.id) && (
				<Grid item xs="auto" ml="auto">
					<Tooltip title="Сохранить ответ">
						<LoadingButton
							variant="contained"
							color="secondary"
							loading={isLoading}
							onClick={handleSubmit(onSubmit)}
							className="answer-submit"
							endIcon={<Check />}
						>
							<Typography>Сохранить ответ</Typography>
						</LoadingButton>
					</Tooltip>
				</Grid>
			)}
		</Grid>
	);
}
