import MainContainer from "../MainContainer";
import {Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale} from 'chart.js';

const AssignmentActivity = ({data: dataFromQuery}: any) => {
    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    const acceptedAssignments = dataFromQuery.filter((a: any) => a.accepted === true);
    const rejectedAssignments = dataFromQuery.filter((a: any) => a.accepted === false);
    const colors = {
        accepted: '#29A8FA',
        rejected: '#FE3E6D'
    }
    const getPercentValue = (value: number) => {
        return (value / (acceptedAssignments.length + rejectedAssignments.length)) * 100;
    }

    const data = {
        labels: ['Сдано', 'Отклонено'],
        datasets: [
            {
                data: [getPercentValue(acceptedAssignments.length), getPercentValue(rejectedAssignments.length)],
                label: 'Процент',
                backgroundColor: [
                    colors.accepted,
                    colors.rejected,
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Прогресс сдачи дом. заданий',
            },
        },
    }

    return (
        <MainContainer>
            {dataFromQuery.length ?
                <Doughnut height="300px" options={options} data={data}/>
                :
                <span>Нет актуальных данных</span>
            }

        </MainContainer>
    )
}

export default AssignmentActivity;