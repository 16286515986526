import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import CheckboxField from '../../components/CheckboxField';
import ImageUploadField from '../../components/ImageUploadField';
import TextField from '../../components/material/TextField';
import RouterLink from '../../components/RouterLink';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import BannersService from '../../services/BannersService';
import { PostBanner } from '../../types';

interface Props {
	isUpdate?: boolean;
	defaultValues?: Partial<PostBanner>;
}

export default function BannerForm({ isUpdate = false, defaultValues }: Props) {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control,
	} = useForm<PostBanner>({
		defaultValues: defaultValues ?? {
			isActive: false,
		},
	});
	const { id } = useParams();
	const navigate = useNavigate();
	const toast = useToast();

	const saveBannerMutation = useMutation(
		isUpdate ? 'updateBanner' : 'createBanner',
		({ file, ...data }: PostBanner) => {
			return isUpdate
				? BannersService.updateBanner(id ?? '', {
						...data,
						...(file && typeof file !== 'string' && { file }),
				  })
				: BannersService.createBanner({ ...data, file });
		},
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				navigate('/banners');
			},
		}
	);

	const { isLoading } = saveBannerMutation;

	const onSubmit = (data: PostBanner) => {
		if (isLoading) {
			return;
		}

		saveBannerMutation.mutate(data);
	};

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues]);

	return (
		<Grid
			container
			spacing={4}
			component="form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<Grid container item columnSpacing={4} rowSpacing={2}>
				<Grid
					item
					xs={12}
					sm={6}
					md="auto"
					minWidth={{ xs: 'initial', sm: 300, md: 400 }}
					order={0}
				>
					<ImageUploadField
						name="file"
						control={control}
						label="Фото"
						ratio={1000 / 500}
						rules={{ required: !isUpdate && 'Загрузите баннер' }}
						formControlProps={{ disabled: isLoading }}
					/>
				</Grid>
				<Grid item xs={12} order={{ xs: 1, md: 2 }}>
					<CheckboxField
						control={control}
						name="isActive"
						label="Активный"
						formControlLabelProps={{ disabled: isLoading }}
					/>
				</Grid>
				<Grid
					container
					item
					xs={12}
					md
					spacing={2}
					order={{ xs: 3, md: 1 }}
					minWidth={{ xs: 0, sm: 500 }}
				>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Заголовок"
							separateLabel
							inputProps={{
								...register('name', { required: 'Введите заголовок' }),
							}}
							error={Boolean(errors.name)}
							helperText={errors.name?.message}
							disabled={isLoading}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label="Ссылка"
							separateLabel
							inputProps={{
								...register('targetUrl'),
							}}
							error={Boolean(errors.targetUrl)}
							helperText={errors.targetUrl?.message}
							disabled={isLoading}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Описание"
							separateLabel
							multiline
							minRows={3}
							maxRows={3}
							inputProps={{
								...register('description'),
							}}
							error={Boolean(errors.description)}
							helperText={errors.description?.message}
							disabled={isLoading}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item container spacing={2}>
				<Grid item ml="auto">
					<Button
						fullWidth
						color="inherit"
						component={RouterLink}
						to="/banners"
						disabled={isLoading}
					>
						Отменить
					</Button>
				</Grid>
				<Grid item>
					<LoadingButton
						fullWidth
						variant="contained"
						type="submit"
						color="secondary"
						loading={isLoading}
					>
						Сохранить
					</LoadingButton>
				</Grid>
			</Grid>
		</Grid>
	);
}
