import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';
import axios, { AxiosError } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SERVICE_URLS } from './constants';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthResult } from './services/AuthService';
import TokenService from './services/TokenService';

axios.interceptors.request.use((config) => {
	let token = TokenService.getToken();

	if (config.headers) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

const refreshToken = async (failedRequest: AxiosError) => {
	const token = TokenService.getRefreshToken();

	try {
		const res = await axios.get<AuthResult>(
			`${SERVICE_URLS.PROFILE}/iam/profile/auth/refresh?token=${token}`
		);

		const { accessToken, refreshToken } = res.data;

		// const tokenDecoded: JWT = jwt_decode(accessToken);
		// const refreshTokenDecoded: JWT = jwt_decode(refreshToken);
		// const exp = new Date(tokenDecoded.exp * 1000);
		// const refreshExp = new Date(refreshTokenDecoded.exp * 1000)

		TokenService.setToken(accessToken);
		TokenService.setRefreshToken(refreshToken);

		if (failedRequest.response?.config.headers) {
			failedRequest.response.config.headers[
				'Authorization'
			] = `Bearer ${accessToken}`;
		}

		return Promise.resolve();
	} catch (e) {
		console.log(e)
		TokenService.removeToken();
		window.location.reload();
		return Promise.reject(e);
	}
};

createAuthRefreshInterceptor(axios, refreshToken, {
	shouldRefresh: (error) => {
		return !error.config.url?.includes('/iam/profile/auth') &&
		error.response?.status === 401
	}
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
