import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AutocompleteField from '../../components/AutocompleteField';
import DatePickerField from '../../components/DatePickerField';
import ImageUploadField from '../../components/ImageUploadField';
import TextField from '../../components/material/TextField';
import RouterLink from '../../components/RouterLink';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import CoursesService from '../../services/CoursesService';
import ProgramsService from '../../services/ProgramsService';
import { Course, PostProgram } from '../../types';

interface Props {
  isUpdate?: boolean;
  defaultValues?: Partial<PostProgram>;
}

export default function ProgramForm({
  isUpdate = false,
  defaultValues,
}: Props) {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<PostProgram>({ defaultValues });

  const { data: courses, isLoading: isLoadingCourses } = useQuery(
    'courses',
    () =>
      CoursesService.getCoursesByProgram(undefined, { includeCommon: false }),
    { initialData: [] }
  );

  const navigate = useNavigate();
  const toast = useToast();

  const saveProgramMutation = useMutation(
    isUpdate ? 'updateProgram' : 'createProgram',
    (data: PostProgram) =>
      isUpdate
        ? ProgramsService.updateProgram(id ?? '', data)
        : ProgramsService.createProgram(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        navigate('/curriculum/programs');
      },
    }
  );

  const { isLoading } = saveProgramMutation;

  const onSubmit = (data: PostProgram) => {
    if (isLoading) {
      return;
    }

    saveProgramMutation.mutate(data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <Grid
      container
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container item xs={12} spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          md="auto"
          minWidth={{ xs: 'initial', sm: 380 }}
        >
          <ImageUploadField
            label="Фото"
            name="coverImage"
            control={control}
            ratio={1000 / 500}
            formControlProps={{ disabled: isLoading }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm
          spacing={2}
          minWidth={{ xs: 'initial', sm: 400 }}
        >
          <Grid item xs={12}>
            <TextField
              label="Заголовок"
              fullWidth
              separateLabel
              inputProps={{
                ...register('title', { required: 'Введите заголовок' }),
              }}
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Описание"
              separateLabel
              multiline
              minRows={4}
              maxRows={4}
              sx={{ height: '100%' }}
              inputProps={{
                ...register('description'),
              }}
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          control={control}
          name="courses"
          label="Курсы"
          formControlProps={{ fullWidth: true }}
          autocompleteProps={{
            multiple: true,
            options: courses,
            getOptionLabel: (option: string | Course) =>
              typeof option === 'string' ? option : option.title,
            loading: isLoadingCourses,
            limitTags: 3,
          }}
          disabled={isLoading}
        />
      </Grid>

      <Grid item container columnSpacing={{ xs: 2, sm: 4 }} rowSpacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <DatePickerField
            control={control}
            name="startDate"
            rules={{ required: 'Введите дату старта' }}
            label="Дата старта"
            datePickerProps={{
              disabled: isLoading,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <DatePickerField
            control={control}
            name="endDate"
            rules={{ required: 'Введите дату конца' }}
            label="Дата завершения"
            datePickerProps={{
              disabled: isLoading,
            }}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item ml="auto">
          <Button
            fullWidth
            color="inherit"
            component={RouterLink}
            to="/curriculum/programs"
            disabled={isLoading}
          >
            Отменить
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            color="secondary"
            loading={isLoading}
          >
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
