import axios from 'axios';
import { addHours } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { SERVICE_URLS } from '../constants';
import { JWT } from '../types';
import TokenService from './TokenService';

export interface AuthResult {
	accessToken: string;
	refreshToken: string;
}

const AuthService = {
	async signIn(email: string, password: string) {
		const res = await axios.post<AuthResult>(
			`${SERVICE_URLS.PROFILE}/iam/profile/auth`,
			{
				email,
				password,
				rememberMe:true
			}
		);

		const { accessToken, refreshToken } = res.data;

		const tokenDecoded: JWT = jwt_decode(accessToken);
		const refreshTokenDecoded: JWT = jwt_decode(refreshToken);
		// const exp = new Date(tokenDecoded.exp * 1000);
		// const refreshExp = new Date(refreshTokenDecoded.exp * 1000)

		if (
			tokenDecoded.claims.length > 0 &&
			tokenDecoded.claims.find((el) => el.name.toLowerCase() === 'student')
		) {
			throw new Error();
		}

		TokenService.setToken(accessToken);
		TokenService.setRefreshToken(refreshToken);
	},
	signOut() {
		TokenService.removeToken();
		window.location.reload();
	},
};

export default AuthService;
