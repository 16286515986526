import DashboardPageHeader from '../../components/DashboardPageHeader';

export default function ProgramsPageHeader() {
	return (
		<DashboardPageHeader
			newButtonText="Добавить программу"
			textFieldProps={{ placeholder: 'Найти программы...' }}
		/>
	);
}
