import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AutocompleteField from '../../../components/AutocompleteField';
import CheckboxField from '../../../components/CheckboxField';
import TextField from '../../../components/material/TextField';
import RouterLink from '../../../components/RouterLink';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from '../../../constants';
import useToast from '../../../hooks/useToast';
import CoursesService from '../../../services/CoursesService';
import LessonsService from '../../../services/LessonsService';
import ProgramsService from '../../../services/ProgramsService';
import UnitsService from '../../../services/UnitsService';
import { useAppSelector } from '../../../store';
import {
  Assignment,
  Attachment,
  Course,
  Glossary,
  PatchLesson,
  PostAssignment,
  PostAttachment,
  PostLesson,
  PostSelfAssignment,
  Program,
  SelfAssignment,
} from '../../../types';
import { Unit } from '../../../types/units';
import getTextFieldErrorProps from '../../../utils/getTextFieldErrorProps';
import AssignmentForm from './AssignmentForm';
import AttachmentForm from './AttachmentForm';
import GlossaryForm from './GlossaryForm';
import QuizForm from './QuizForm';
import SelfAssignmentForm from './SelfAssignmentForm';

interface Props {
  isUpdate?: boolean;
  defaultValues?: Partial<PostLesson>;
  attachments: Attachment[];
  glossary?: Glossary;
  assignment?: Assignment;
  selfAssignment?: SelfAssignment;
  quizId?: string;
}

export default function LessonForm({
  isUpdate = false,
  defaultValues,
  attachments,
  glossary,
  assignment,
  selfAssignment,
  quizId,
}: Props) {
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const { program, course, unit } = useAppSelector((state) => state.filters);
  const toast = useToast();
  const navigate = useNavigate();

  const { id } = useParams();

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    resetField,
    reset,
  } = useForm<PostLesson>({
    defaultValues: {
      ...defaultValues,
      program: program.value,
      course: course.value,
      unit: unit.value,
    },
  });

  const queryClient = useQueryClient();
  const watchProgram = watch('program');
  const watchCourse = watch('course');

  const saveLessonMutation = useMutation(
    isUpdate ? 'updateLesson' : 'createLesson',
    (data: PostLesson | PatchLesson) =>
      !isUpdate
        ? LessonsService.createLesson(data as PostLesson)
        : LessonsService.updateLesson(id ?? '', data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');

        if (isUpdate) {
          queryClient.refetchQueries(['lesson', id]);
        } else {
          navigate(`/curriculum/lessons/${data.id}`);
        }
      },
    }
  );

  const { isLoading } = saveLessonMutation;

  const onSubmit = (data: PostLesson) => {
    if (isLoading) {
      return;
    }

    saveLessonMutation.mutate(data);
  };

  const programs = useQuery('programs', () => ProgramsService.getPrograms());

  const courses = useQuery(['courses', watchProgram?.id], () =>
    CoursesService.getCoursesByProgram(watchProgram?.id)
  );

  const units = useQuery(
    ['units', watchCourse?.id],
    () => UnitsService.getUnitsByCourse(watchCourse?.id),
    { enabled: Boolean(watchCourse) }
  );

  const attachmentValues = useMemo<Partial<PostAttachment | undefined>>(() => {
    if (!attachments) {
      return;
    }

    return attachments as Partial<PostAttachment>;
  }, [attachments]);

  const assignmentValues = useMemo<Partial<PostAssignment | undefined>>(() => {
    if (!assignment) {
      return;
    }

    return {
      title: assignment.title,
      deadline: new Date(assignment.deadline),
      description: assignment.description,
      example: assignment.example,
    } as Partial<PostAssignment>;
  }, [assignment]);

  const selfAssignmentValues = useMemo<
    Partial<PostSelfAssignment | undefined>
  >(() => {
    if (!selfAssignment) {
      return;
    }

    return {
      title: selfAssignment.title,
      description: selfAssignment.description,
      example: selfAssignment.example,
    } as Partial<PostSelfAssignment>;
  }, [selfAssignment]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      switch (name) {
        case 'program':
          resetField('course', { defaultValue: undefined });
          resetField('unit', { defaultValue: undefined });
          break;
        case 'course':
          resetField('unit', { defaultValue: undefined });
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, resetField]);

  return (
    <Grid container>
      <Grid
        item
        container
        columnSpacing={4}
        rowSpacing={2}
        xs={12}
        lg={10}
        xl={9}
      >
        <Grid
          item
          container
          xs={12}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          columnSpacing={4}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <TextField
              separateLabel
              label="Заголовок"
              fullWidth
              inputProps={{
                ...register('title', { required: 'Введите заголовок' }),
              }}
              {...getTextFieldErrorProps(errors.title)}
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              separateLabel
              label="Описание"
              multiline
              rows={7}
              fullWidth
              inputProps={{
                ...register('description'),
              }}
              disabled={isLoading}
            />
          </Grid>

          {!isUpdate && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <AutocompleteField
                  name="program"
                  control={control}
                  label="Программа"
                  disabled={isLoading}
                  formControlProps={{ fullWidth: true }}
                  autocompleteProps={{
                    getOptionLabel: (option: any) => (option as Program).title,
                    options: programs.data,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <AutocompleteField
                  name="course"
                  control={control}
                  rules={{ required: 'Выберите курс' }}
                  label="Курс"
                  disabled={isLoading}
                  formControlProps={{ fullWidth: true }}
                  autocompleteProps={{
                    getOptionLabel: (option: any) => (option as Course).title,
                    options: courses.data,
                    groupBy: (option: Course) =>
                      option.common ? 'Общие курсы' : 'По направлению',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <AutocompleteField
                  name="unit"
                  control={control}
                  rules={{ required: 'Выберите блок' }}
                  label="Блок"
                  disabled={isLoading || !watchCourse}
                  formControlProps={{ fullWidth: true }}
                  autocompleteProps={{
                    getOptionLabel: (option: any) => (option as Unit).title,
                    options: units.data,
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <CheckboxField
              control={control}
              name="isLastLesson"
              label="Последний урок"
              formControlLabelProps={{ disabled: isLoading }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              separateLabel
              label="Ссылка на видео"
              fullWidth
              inputProps={{
                ...register('playlist'),
              }}
              {...getTextFieldErrorProps(errors.playlist)}
              disabled={isLoading}
              autoComplete="off"
            />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item ml="auto">
              <Button
                fullWidth
                color="inherit"
                component={RouterLink}
                to="/curriculum/lessons"
                disabled={isLoading}
              >
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                fullWidth
                variant="contained"
                type="submit"
                color="secondary"
                loading={isLoading}
              >
                Сохранить
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <AttachmentForm
            isDisabled={!isUpdate}
            defaultValues={attachmentValues}
            attachments={attachments}
          />

          <GlossaryForm isDisabled={!isUpdate} glossary={glossary} />

          <AssignmentForm
            isDisabled={!isUpdate}
            assignment={assignment}
            defaultValues={assignmentValues}
          />

          <SelfAssignmentForm
            isDisabled={!isUpdate}
            assignment={selfAssignment}
            defaultValues={selfAssignmentValues}
          />

          <QuizForm isDisabled={!isUpdate} quizId={quizId} />
        </Grid>
      </Grid>
    </Grid>
  );
}
