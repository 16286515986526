import {
  Delete,
  DeleteOutlined,
  EditOutlined,
  ListAltOutlined,
  PeopleOutlined,
} from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import { GridColumns, GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../components/CustomDataGrid';
import { DEFAULT_ERROR_MESSAGE } from '../../constants';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import { ContextMenuAction } from '../../hooks/useContextMenu';
import useNavigateWithFilters from '../../hooks/useNavigateWithFilters';
import useToast from '../../hooks/useToast';
import ProgramsService from '../../services/ProgramsService';
import { Program } from '../../types';
import copyToClipboard from '../../utils/copyToClipboard';
import formatDate from '../../utils/formatDate';

export default function ProgramsGrid() {
  const toast = useToast();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const navigate = useNavigate();
  const {
    data: programs,
    isLoading,
    refetch,
  } = useQuery('programs', () => ProgramsService.getPrograms());
  const withConfirm = useConfirmDialog();
  const deleteProgramMutation = useMutation(
    'deleteProgram',
    (id: string) => ProgramsService.deleteProgram(id),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        refetch();
      },
    }
  );

  const navigateWithFilters = useNavigateWithFilters();

  const actions: ContextMenuAction[] = [
    {
      label: 'Курсы',
      icon: <ListAltOutlined />,
      onClick: (programId) => navigateWithFilters('courses', programId),
    },
    {
      label: 'Пользователи',
      icon: <PeopleOutlined />,
      onClick: (programId) =>
        navigateWithFilters('users', programId, { programId }),
    },
    {
      label: 'Редактировать',
      icon: <EditOutlined />,
      onClick: (programId) => navigate(programId),
    },
    {
      label: 'Удалить',
      icon: <DeleteOutlined />,
      onClick: (programId) =>
        withConfirm({
          title: 'Удалить программу?',
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          onConfirm: () => deleteProgramMutation.mutate(programId),
        }),
    },
  ];

  const columns: GridColumns<Program> = [
    {
      field: 'title',
      headerName: 'Заголовок',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 220,
      renderCell: (params) => (
        <Tooltip title="Скопировать">
          <Button
            size="small"
            color="inherit"
            sx={{ fontWeight: 400 }}
            onClick={async () => {
              try {
                await copyToClipboard(params.value);
                toast('ID был скопирован в буфер обмена!', 'success');
              } catch (e) {
                toast('Произошла ошибка при копировании');
              }
            }}
          >
            {params.value}
          </Button>
        </Tooltip>
      ),
    },
    {
      field: 'startDate',
      headerName: 'Старт',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'endDate',
      headerName: 'Конец',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: '',
      width: 210,
      renderCell: (params) =>
        actions.map((el) => (
          <Tooltip title={el.label} key={`programs-action-${el.label}`}>
            <IconButton onClick={() => el.onClick(params.id.toString())}>
              {el.icon}
            </IconButton>
          </Tooltip>
        )),
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <CustomDataGrid
      contextMenuItems={actions}
      selection={{
        model: selectionModel,
        setModel: setSelectionModel,
        actions: [
          {
            text: 'Удалить выбранные программы',
            onClick: (items) => {},
            icon: <Delete color="error" />,
            color: 'error',
          },
        ],
      }}
      columns={columns}
      rows={programs ?? []}
      loading={isLoading}
      // checkboxSelection
      hideFooter
    />
  );
}
