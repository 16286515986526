import DashboardPageHeader from '../../components/DashboardPageHeader';

export default function QuizzesPageHeader() {
	return (
		<DashboardPageHeader
			allowSearch
			newButtonText="Добавить тест"
			textFieldProps={{ placeholder: 'Найти тест...' }}
			curriculumFiltersProps={{
				program: true,
				course: true,
				unit: true,
				lesson: true,
			}}
			disableNewButton
		/>
	);
}
