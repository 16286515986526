import { useEffect } from 'react';
import { useAppDispatch } from '../store';
import {
	resetBackButtonPath,
	resetHeaderSubtitle,
	resetHeaderTitle,
	setBackButtonPath,
	setHeaderSubtitle,
	setHeaderTitle,
} from '../store/appSlice';

/* 
	Sets the header title to the title provided as the parameter
*/
export default function useSetHeaderTitle(
	title: string,
	subtitle?: string,
	backButtonPath?: string
): void {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(title));

		return () => {
			dispatch(resetHeaderTitle());
		};
	}, [title]);

	useEffect(() => {
		if (subtitle) {
			dispatch(setHeaderSubtitle(subtitle));
		}

		return () => {
			dispatch(resetHeaderSubtitle());
		};
	}, [subtitle]);

	useEffect(() => {
		if (backButtonPath) {
			dispatch(setBackButtonPath(backButtonPath));
		}

		return () => {
			dispatch(resetBackButtonPath());
		};
	}, [backButtonPath]);
}
