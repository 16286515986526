import { UploadFile } from '@mui/icons-material';
import { Button } from '@mui/material';
import DashboardPageHeader from '../../components/DashboardPageHeader';
import RouterLink from '../../components/RouterLink';

export default function UsersPageHeader() {
  return (
    <DashboardPageHeader
      allowSearch
      rightSideAdornment={
        <Button
          component={RouterLink}
          to="batch-upload"
          fullWidth
          endIcon={<UploadFile />}
          color="inherit"
          variant="outlined"
          disableElevation
        >
          Массовая загрузка
        </Button>
      }
      newButtonText="Добавить пользователя"
      textFieldProps={{ placeholder: 'Найти пользователей...' }}
      curriculumFiltersProps={{
        program: true,
        course: true,
      }}
    />
  );
}
