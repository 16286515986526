import { Box, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useMutation, useQuery } from 'react-query';
import CurriculumFilters from '../../components/CurriculumFilters';
import DashboardFullPageContainer from '../../components/DashboardFullPageContainer';
import DraggableList from '../../components/DraggableList';
import Loader from '../../components/Loader';
import Typography from '../../components/material/Typography';
import { DEFAULT_ERROR_MESSAGE, ORDER_SUCCESS_MESSAGE } from '../../constants';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useToast from '../../hooks/useToast';
import UnitsService from '../../services/UnitsService';
import { useAppSelector } from '../../store';
import { Unit } from '../../types/units';
import UnitsListRow from './UnitsListRow';

export default function UnitsList() {
  const toast = useToast();
  const { course, additionalFilters } = useAppSelector(
    (state) => state.filters
  );
  const {
    data,
    isLoading: filtersIsLoading,
    refetch,
  } = useQuery(
    ['unitsList', course?.id, additionalFilters?.unitIsActive],
    () =>
      UnitsService.getUnitsByCourse(course?.id, {
        isActive: additionalFilters?.unitIsActive,
      }),
    { enabled: Boolean(course.value) }
  );

  const withConfirm = useConfirmDialog();

  const saveUnitsOrderMutation = useMutation(
    'saveUnitsOrderMutation',
    (ids: string[]) => UnitsService.updateUnitsOrder(ids),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        refetch();
        toast(ORDER_SUCCESS_MESSAGE, 'success');
      },
    }
  );

  const deleteUnitMutation = useMutation(
    'deleteUnit',
    (id: string) => UnitsService.deleteUnit(id),
    {
      onSuccess(data, variables, context) {
        refetch();
      },
    }
  );

  const [units, setUnits] = useState<Unit[]>([]);

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const element = units[source.index];
    const newArray = Array.from(units);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, element);

    setUnits([...newArray]);
  };

  const onDelete = (id: string) => {
    if (deleteUnitMutation.isLoading) {
      return;
    }

    withConfirm({
      title: 'Удалить блок?',
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отмена',
      onConfirm: () => deleteUnitMutation.mutate(id),
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setUnits(data);
  }, [data]);

  if (filtersIsLoading || course.isLoading) {
    return <Loader />;
  }

  if (!course.value) {
    return (
      <DashboardFullPageContainer>
        <Paper sx={{ p: 2 }}>
          <Typography variant="body1" mb={1}>
            Пожалуйста, выберите программу и курс:
          </Typography>
          <CurriculumFilters program course />
        </Paper>
      </DashboardFullPageContainer>
    );
  }

  return (
    <Box>
      <DraggableList
        droppableId="unitsList"
        items={units}
        onDragEnd={onDragEnd}
        emptyMessage="Блоки не найдены"
        renderItem={(unit, i, options) => (
          <UnitsListRow
            unit={unit}
            index={i}
            key={`unit-${unit.id}`}
            onDelete={onDelete}
            isDisabled={deleteUnitMutation.isLoading}
            {...options}
          />
        )}
        onChangeSave={() =>
          saveUnitsOrderMutation.mutate(units.map((el) => el.id))
        }
        onChangeCancel={() => setUnits(data ?? [])}
      />
    </Box>
  );
}
