import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { ReactNode } from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import getTextFieldErrorProps from '../utils/getTextFieldErrorProps';
import TextField, { TextFieldProps } from './material/TextField';

interface FieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	children?: ReactNode;
	render?: any;
	label?: string;
	datePickerProps?: Partial<DatePickerProps<any, any>>;
	textFieldProps?: TextFieldProps;
}

export default function DatePickerField({
	label,
	datePickerProps,
	textFieldProps,
	...props
}: FieldProps) {
	return (
		<Controller
			{...props}
			render={({ field: { value, ...field }, fieldState: { error } }) => (
				<DatePicker
					{...field}
					{...datePickerProps}
					value={value ?? null}
					label={label}
					renderInput={(params) => (
						<TextField
							fullWidth
							{...params}
							inputProps={{
								...params.inputProps,
								placeholder: 'дд.мм.гггг',
							}}
							separateLabel
							{...getTextFieldErrorProps(error)}
						/>
					)}
				/>
			)}
		/>
	);
}
